/*
 * Do NOT use hooks in this file. This file is rendered with ReactDOMServer, and hooks are not supported.
 */

import {
  CARESEEKER_TYPE_PROVIDER_SEARCH,
  CORONA_NOT_TESTED,
  CORONA_TESTED,
  LIVING_SITUATION_OTHER,
  PAYERS_MAPPING,
  PREDICAMENT_STATE_NOT_APPLICABLE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_TRANSPORT,
} from "core/consts";
import { getPayersLabels } from "core/consts/labels";
import { getName } from "core/model/accounts";
import { isCareSearch, isRequestAvailable } from "core/model/auctions";
import { getAge, getCareLevelValue, getInterval } from "core/model/patients";
import { getEncryptedValue } from "core/model/patients/encryption";
import { convertBirthDateIn, formatUnixDate } from "core/model/utils/dates";
import { formatLocation } from "core/model/utils/location";
import {
  ONTOLOGY_COMMUNICATION,
  ONTOLOGY_MOBILITY,
  ONTOLOGY_ORIENTATION,
  ONTOLOGY_SOLUTION,
  ONTOLOGY_SPECIALIZATIONS,
} from "core/model/utils/ontologies";
import { concatStrings } from "core/model/utils/strings";
import { renderMultiLine } from "core/model/utils/text";
import {
  AccessDrainages as AccessDrainagesType,
  Auction,
  AuctionRequest as AuctionRequestType,
  Careseeker,
  Financing,
  GetOntologyType,
  Patient,
  SearchType,
  SocialWorker,
} from "core/types";
import { Locale as DateFnsLocale } from "date-fns";
import { margin } from "ds_legacy/materials/metrics";
import { Body, Email, Subheading, Title } from "ds_legacy/materials/typography";
import PhoneNumber from "dsl/atoms/PhoneNumber";
import { getSelectedOntologies } from "dsl/ecosystems/PatientProfile";
import GrantStatusField from "dsl/organisms/Infos/GrantStatusField";
import { careneedsFilters } from "dsl/organisms/Infos/PatientInformation/CareNeedsCard";
import { generalInformationFilters } from "dsl/organisms/Infos/PatientInformation/InformationCard";
import {
  IcdCodesCommon,
  IndependenceScaleResult,
  coronaSwitch,
  medicalFilters,
} from "dsl/organisms/Infos/PatientInformation/MedicalCard";
import {
  formatProductString,
  locationValue,
  medicalSuppliesFilters,
} from "dsl/organisms/Infos/PatientInformation/MedicalSuppliesSearchCard";
import {
  ChipInfo,
  ProviderSearchHeader,
} from "dsl/organisms/Infos/PatientInformation/OverviewCard";
import TransportInfoCard from "dsl/organisms/Infos/PatientInformation/TransportInfoCard";
import {
  formatPickupStringValue,
  transportFilters,
} from "dsl/organisms/Infos/PatientInformation/TransportSearchCard";
import {
  BodyWrapper,
  BooleanField,
  OntologyField,
  StringField,
  activableInputCollectionIsNotEmpty,
  isInfectionsAndGermsPredicamentNegative,
} from "dsl/organisms/Infos/PatientInformation/shared";
import ShortTermCareGrant from "dsl/organisms/Infos/ShortTermCareGrant";
import { solutionSortOrder } from "dsl/organisms/PatientCreatePage/PatientCreateForm";
import { FieldsHolderType } from "dsl/organisms/PatientMenu/transform";
import React, { ReactNode } from "react";
import { getPredicamentValue } from "react-forms-state";
import { Locale as TranslationsLocale } from "translations";
import Translations from "translations/types";

const careneedsComponents = [
  {
    Component: Support,
    key: "support",
  },
  {
    Component: PsychiatricCare,
    key: "psychiatric_care",
  },
  {
    Component: PalliativeCare,
    key: "palliative_care",
  },
  {
    Component: Nutrition,
    key: "nutrition",
  },
  {
    Component: Incontinence,
    key: "incontinence",
  },
  {
    Component: Breathing,
    key: "breathing",
  },
  {
    Component: Dialysis,
    key: "Dialysis",
  },
  {
    Component: Wounds,
    key: "Wounds",
  },
  {
    Component: Medication,
    key: "medication",
  },
  {
    Component: Compression,
    key: "compression",
  },
  {
    Component: Injection,
    key: "injection",
  },
  {
    Component: AccessDrainages,
    key: "access_drainages",
  },
  {
    Component: ImportantInformation,
    key: "important_information",
  },
  {
    Component: BaseCare,
    key: "base_care",
  },
  {
    Component: MedicalCare,
    key: "basic_medical_care",
  },
  {
    Component: Companionship,
    key: "companionship",
  },
  {
    Component: HelpAtHome,
    key: "help_at_home",
  },
  {
    Component: Remarks,
    key: "remarks",
  },
] as const;

const transportSearchComponentMapping = [
  {
    Component: PickUpSection,
    key: "pick_up",
  },

  {
    Component: Destination,
    key: "destination",
  },
  {
    Component: TransportEquipment,
    key: "transport_equipment",
  },
] as const;

const medicalDiagnosisComponents = [
  {
    Component: IcdCodesForPrint,
    key: "icd_codes",
  },
  {
    Component: InfectionAndGerms,
    key: "infection_and_germs",
  },
  {
    Component: CoronaVirusTesting,
    key: "corona_testing",
  },
  {
    Component: DependencyDiagnosis,
    key: "dependencyDiagnosis",
  },
  {
    Component: Dysphagia,
    key: "dysphagia",
  },
  {
    Component: ChronicPains,
    key: "chronic_pains",
  },
  {
    Component: Allergies,
    key: "allergies",
  },
  {
    Component: Addictions,
    key: "addictions",
  },
  {
    Component: HospitalStay,
    key: "hospital_stay",
  },
  {
    Component: LimitedCapacity,
    key: "limited_capacity",
  },
  {
    Component: RehabilitationGoals,
    key: "rehabilitation_goals",
  },
  {
    Component: RehabilitationPotential,
    key: "rehabilitation_potential",
  },
] as const;

const mentalHealthComponents = [
  {
    Component: PatientOrientation,
    key: "patient_orientation",
  },
  {
    Component: PatientMotivation,
    key: "patient_motivation",
  },
  {
    Component: PatientCommunication,
    key: "patient_communication",
  },
  {
    Component: Dementia,
    key: "dementia",
  },
  {
    Component: RunAway,
    key: "run_away",
  },
  {
    Component: Nightwatch,
    key: "night_watch",
  },
  {
    Component: FacilityCharacteristics,
    key: "facility_characteristics",
  },
  {
    Component: MentalIssues,
    key: "mental_issues",
  },
] as const;

const physicalComponents = [
  {
    Component: PatientMobility,
    key: "patient_mobility",
  },
  {
    Component: VisualImpairment,
    key: "visual_impairment",
  },
  {
    Component: AuditiveImpairment,
    key: "auditive_impairment",
  },
  {
    Component: AssistiveEquipment,
    key: "assistive_equipment",
  },
  {
    Component: Orthosis,
    key: "orthosis",
  },
  {
    Component: Prothesis,
    key: "prothesis",
  },
] as const;

const generalInformationComponents = [
  {
    Component: RelevantProviderInformation,
    key: "relavant_information",
  },
  {
    Component: GeneralInformation,
    key: "general_information",
  },
  {
    Component: Insurance,
    key: "insurance",
  },
  {
    Component: Carelevel,
    key: "carelevel",
  },
  {
    Component: Payout,
    key: "payout",
  },
  {
    Component: LivingSituation,
    key: "living_situation",
  },
  {
    Component: GeneralPractitioner,
    key: "general_practitioner",
  },
  {
    Component: Communication,
    key: "communication",
  },
  {
    Component: Grants,
    key: "grants",
  },
  {
    Component: Payers,
    key: "payers",
  },
  {
    Component: Preferences,
    key: "preferences",
  },
] as const;

const medicalSuppliesSearchComponents = [
  {
    Component: Products,
    key: "products",
  },
  {
    Component: Prescription,
    key: "prescription",
  },
  {
    Component: DeliveryDetails,
    key: "delivery_details",
  },
] as const;

function BodyWithBreak({
  children,
  faxPrint,
  testId,
}: {
  children: ReactNode;
  faxPrint: boolean | null | undefined;
  testId: string;
}) {
  return (
    <>
      <Body data-testid={testId}>{children}</Body>
      {faxPrint ? <br /> : null}
    </>
  );
}

function EmptyablePrintCategory({
  children,
  empty,
  faxPrint,
  testId,
  title,
  translations,
}: {
  children: React.ReactNode;
  empty: boolean;
  faxPrint: boolean | null | undefined;
  testId: string;
  title: string;
  translations: Translations;
}) {
  return (
    <div>
      <Subheading bold>{title}</Subheading>
      {empty ? (
        <BodyWithBreak faxPrint={faxPrint} testId={testId}>
          {translations.auctionRequest.noAdditionalInformationProvided}
        </BodyWithBreak>
      ) : (
        children
      )}
    </div>
  );
}

function EmptiablePrintField({
  faxPrint,
  prefix,
  testId,
  value,
}: {
  faxPrint: boolean | null | undefined;
  prefix: string;
  testId: string;
  value: string | null | undefined;
}) {
  if (value == null) return null;

  return (
    <BodyWithBreak faxPrint={faxPrint} testId={testId}>
      {concatStrings(prefix, value, ": ")}
    </BodyWithBreak>
  );
}

function Diagnosis({
  auction,
  faxPrint,
  forClinic,
  getOntology,
  locale,
  patient,
  request,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  forClinic: boolean | null | undefined;
  getOntology: GetOntologyType;
  locale: TranslationsLocale;
  patient: Patient;
  request?: AuctionRequestType;
  translations: Translations;
}) {
  if (request?.is_provider_search_request || !patient?.diagnosis) return null;

  const categories = medicalDiagnosisComponents.filter((c) =>
    medicalFilters[c.key].exists(patient.diagnosis),
  );

  if (!categories.length) return null;

  return (
    <>
      <Title bold>{translations.patient.medicalDiagnosis.stepperTitle}</Title>
      <MainDiagnosis
        faxPrint={faxPrint}
        patient={patient}
        translations={translations}
      />
      <SecondaryDiagnosis
        faxPrint={faxPrint}
        patient={patient}
        translations={translations}
      />
      <MorbidityStatus
        faxPrint={faxPrint}
        status={patient.diagnosis?.multimorbidity}
        translations={translations}
      />
      <AdditionalSpecializations
        faxPrint={faxPrint}
        auction={auction}
        translations={translations}
        getOntology={getOntology}
      />
      <MedicalHistory
        faxPrint={faxPrint}
        patient={patient}
        translations={translations}
      />
      <AdditionalExaminationsNecessary
        faxPrint={faxPrint}
        patient={patient}
        translations={translations}
      />
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          patient,
          forClinic: !!forClinic,
          getOntology,
          faxPrint,
          locale,
        }),
      )}
    </>
  );
}

function MentalHealth({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient?.diagnosis) return null;

  const categories = mentalHealthComponents.filter((c) =>
    medicalFilters[c.key].exists(patient.diagnosis),
  );

  if (!categories.length) return null;

  return (
    <>
      <Title bold>{translations.patient.mentalHealth}</Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          patient,
          getOntology,
          faxPrint,
        }),
      )}
    </>
  );
}

function PhysicalHealth({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient?.diagnosis) return null;

  const categories = physicalComponents.filter((c) =>
    medicalFilters[c.key].exists(patient.diagnosis),
  );

  if (!categories.length) return null;

  return (
    <>
      <Title>{translations.patient.physicalHealth}</Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          patient,
          getOntology,
          faxPrint,
        }),
      )}
    </>
  );
}

function TransportSearch({
  auction,
  currentLocale,
  faxPrint,
  translations,
}: {
  auction: Auction;
  currentLocale: DateFnsLocale;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  if (auction.search_type !== SEARCH_TYPE_TRANSPORT) return null;

  const categories = transportSearchComponentMapping.filter((c) =>
    transportFilters[c.key].exists(auction),
  );

  if (!categories.length) return null;

  return (
    <>
      <Title bold>
        {translations.assessments.transportSearch.titleTransportSearch}
      </Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          auction,
          faxPrint,
          currentLocale,
        }),
      )}
    </>
  );
}

function GeneralInformationContainer({
  auction,
  extractedPatientFields,
  faxPrint,
  forClinic,
  getOntology,
  locale,
  patient,
  request,
  translations,
}: {
  auction: Auction;
  extractedPatientFields: FieldsHolderType;
  faxPrint: boolean | null | undefined;
  forClinic: boolean | null | undefined;
  getOntology: GetOntologyType;
  locale: TranslationsLocale;
  patient: Patient;
  request: AuctionRequestType | null | undefined;
  translations: Translations;
}) {
  const categories = generalInformationComponents.filter((c) =>
    generalInformationFilters[c.key].exists(auction),
  );

  if (!categories.length) return null;

  return (
    <>
      <Title bold>{translations.patient.patientInformation.stepperTitle}</Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          auction,
          extractedPatientFields,
          faxPrint,
          forClinic: !!forClinic,
          getOntology,
          key: c.key,
          locale,
          patient,
          request: request ?? undefined,
          translations,
        }),
      )}
    </>
  );
}

function MedicalSuppliesContainer({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | undefined;
  translations: Translations;
}) {
  if (
    !(
      [SEARCH_TYPE_HOME_CARE, SEARCH_TYPE_MEDICAL_SUPPLIES] as SearchType[]
    ).includes(auction.search_type)
  ) {
    return null;
  }

  const categories = medicalSuppliesSearchComponents.filter((c) =>
    medicalSuppliesFilters[c.key].exists(auction),
  );

  if (!categories.length) return null;

  return (
    <>
      <Title bold>
        {translations.assessments.medicalSupplies.medicalSupplies}
      </Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          auction,
          faxPrint,
        }),
      )}
    </>
  );
}

function CareNeeds({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;

  const categories = careneedsComponents.filter((c) =>
    careneedsFilters[c.key].exists(patient.care_needs),
  );

  if (!categories.length) return null;

  return (
    <>
      <Title bold>{translations.patient.careNeeds.stepperTitle}</Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          patient,
          faxPrint,
        }),
      )}
    </>
  );
}

function TransportInfo({
  auction,
  getOntology,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
}) {
  return (
    <TransportInfoCard auction={auction} getOntology={getOntology} forPrint />
  );
}

function BasicCategory({
  column,
  faxPrint,
  multiline = false,
  testId,
  text,
  title,
}: {
  column?: boolean;
  faxPrint: boolean | null | undefined;
  multiline?: boolean;
  testId: string;
  text: string;
  title: string;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: column ? "column" : undefined,
        alignItems: "baseline",
      }}
    >
      <Subheading bold>{title}</Subheading>
      <BodyWithBreak faxPrint={faxPrint} testId={testId}>
        {multiline ? renderMultiLine(text) : text}
      </BodyWithBreak>
    </div>
  );
}

function HospitalContactInformation({
  careseeker,
  getOntology,
  socialWorker,
  translations,
}: {
  careseeker: Careseeker | undefined;
  getOntology: GetOntologyType;
  socialWorker: SocialWorker | undefined;
  translations: Translations;
}) {
  if (
    !socialWorker ||
    !careseeker ||
    careseeker.type === CARESEEKER_TYPE_PROVIDER_SEARCH
  ) {
    return null;
  }

  return (
    <>
      <Subheading bold>
        {translations.careproviderProfile.clinicContactInformation({
          careseekerName: careseeker.name || "",
        })}
      </Subheading>
      <StringField
        value={getName(socialWorker, getOntology)}
        testId="careseeker_name"
      />
      <PhoneNumber
        phone={socialWorker.phone}
        prefix={translations.people.tel}
      />
      <PhoneNumber
        phone={socialWorker.mobile_phone}
        prefix={translations.people.mobile}
      />

      {socialWorker.email && (
        <div>
          <Email href={`mailto:${socialWorker.email}`}>
            {translations.people.mailShort}&nbsp;{socialWorker.email}
          </Email>
        </div>
      )}
    </>
  );
}

function RelevantProviderInformation({
  auction,
  extractedPatientFields,
  faxPrint,
  forClinic,
  getOntology,
  patient,
  request,
  translations,
}: {
  auction: Auction;
  extractedPatientFields: FieldsHolderType;
  faxPrint: boolean | null | undefined;
  forClinic: boolean;
  getOntology: GetOntologyType;
  locale: TranslationsLocale;
  patient: Patient | undefined;
  request: AuctionRequestType | undefined;
  translations: Translations;
}) {
  if (!patient) return null;

  const {
    careseeker,
    diagnosis,
    external_id,
    profile,
    social_worker,
    user_id,
  } = patient;

  const typeOfTransfer = getSelectedOntologies(
    extractedPatientFields.solutions,
    ONTOLOGY_SOLUTION,
    getOntology,
    solutionSortOrder,
  );

  const specialization = getSelectedOntologies(
    extractedPatientFields.specializations,
    ONTOLOGY_SPECIALIZATIONS,
    getOntology,
  );

  return (
    <div>
      {user_id && (
        <BodyWithBreak
          testId="user_id"
          faxPrint={faxPrint}
        >{`${translations.patient.patientId}: ${user_id}`}</BodyWithBreak>
      )}
      {!!request?.is_provider_search_request && (
        <ProviderSearchHeader
          requestAvailable={isRequestAvailable(request)}
          print
        />
      )}
      {forClinic && (
        <>
          {careseeker && (
            <BodyWithBreak
              testId="careseeker_name"
              faxPrint={faxPrint}
            >{`${translations.auctionRequest.hospitalName}: ${careseeker.name}`}</BodyWithBreak>
          )}
          {!!typeOfTransfer && (
            <BodyWithBreak
              testId="type_of_transfer"
              faxPrint={faxPrint}
            >{`${translations.auctionRequest.typeOfTransfer}: ${typeOfTransfer}`}</BodyWithBreak>
          )}
          {!!specialization && (
            <BodyWithBreak
              testId="specialization"
              faxPrint={faxPrint}
            >{`${translations.ontologies.specializations.key}: ${specialization}`}</BodyWithBreak>
          )}
          <br />
        </>
      )}
      {forClinic && external_id != null && (
        <BodyWithBreak faxPrint={faxPrint} testId="external_id">
          {`${translations.patient.externalId}: ${external_id}`}
        </BodyWithBreak>
      )}
      {profile?.first_name?.decrypted && (
        <BodyWithBreak
          testId="first_name"
          faxPrint={faxPrint}
        >{`${translations.patient.firstName}: ${profile.first_name.decrypted}`}</BodyWithBreak>
      )}
      {profile?.last_name?.decrypted && (
        <BodyWithBreak
          testId="last_name"
          faxPrint={faxPrint}
        >{`${translations.patient.lastName}: ${profile.last_name.decrypted}`}</BodyWithBreak>
      )}
      {request?.solutions?.length && (
        <div>
          <ChipInfo
            getOntology={getOntology}
            requestSolutions={request?.solutions}
            auction={auction}
            oldAuction={null}
            translations={translations}
          />
        </div>
      )}
      {!forClinic && !faxPrint && (
        <HospitalContactInformation
          getOntology={getOntology}
          socialWorker={social_worker}
          careseeker={careseeker}
          translations={translations}
        />
      )}
      {!forClinic &&
        (diagnosis?.hospital_stay?.doctor_in_charge_in_hospital ||
          diagnosis?.hospital_stay?.doctor_in_charge_in_hospital_phone) && (
          <>
            {diagnosis.hospital_stay.doctor_in_charge_in_hospital && (
              <BodyWithBreak
                faxPrint={faxPrint}
                testId="doctor_in_charge_in_hospital"
              >{`${translations.patient.doctorInCharge}: ${diagnosis.hospital_stay.doctor_in_charge_in_hospital}`}</BodyWithBreak>
            )}
            {!isCareSearch(auction?.search_type) &&
              diagnosis.hospital_stay.doctor_in_charge_in_hospital_phone && (
                <BodyWithBreak
                  testId="doctor_in_charge_in_hospital_phone"
                  faxPrint={faxPrint}
                >{`${translations.people.tel}: ${diagnosis.hospital_stay.doctor_in_charge_in_hospital_phone}`}</BodyWithBreak>
              )}
          </>
        )}
    </div>
  );
}

function GeneralInformation({
  auction,
  faxPrint,
  getOntology,
  locale,
  patient,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  locale: TranslationsLocale;
  patient: Patient | undefined;
  translations: Translations;
}) {
  if (!patient?.profile) return null;

  const { profile } = patient;
  const search_type = auction?.search_type;
  const location = auction?.profile?.search_location;
  const formattedAddressInfo = location
    ? formatLocation({ location, translations })
    : null;
  const isProviderSearchPatient =
    patient?.careseeker?.type === CARESEEKER_TYPE_PROVIDER_SEARCH;

  const weight =
    getEncryptedValue(profile?.weight) ||
    getInterval(
      patient,
      auction.search_type,
      isProviderSearchPatient,
      "weight_interval",
      translations,
    );
  const height =
    getEncryptedValue(profile?.height) ||
    getInterval(
      patient,
      auction.search_type,
      isProviderSearchPatient,
      "height_interval",
      translations,
    );

  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.generalInformation}
      </Subheading>
      {profile.gender && (
        <BodyWithBreak faxPrint={faxPrint} testId="gender">{`${
          translations.patient.gender
        }: ${getOntology({
          type: "gender",
          key: profile.gender,
        })}`}</BodyWithBreak>
      )}
      {profile.birth_date?.decrypted ? (
        <BodyWithBreak faxPrint={faxPrint} testId="birth_date">{`${
          translations.patient.dateOfBirth
        }: ${convertBirthDateIn(profile.birth_date, {
          locale,
        })}`}</BodyWithBreak>
      ) : (
        profile.age_interval && (
          <BodyWithBreak faxPrint={faxPrint} testId="age_interval">{`${
            translations.patient.age
          }: ${
            getAge(profile.age_interval, search_type) || ""
          }`}</BodyWithBreak>
        )
      )}
      {weight && (
        <BodyWithBreak
          testId="weight"
          faxPrint={faxPrint}
        >{`${translations.patient.formGeneration.weight}: ${weight}kg`}</BodyWithBreak>
      )}
      {height && (
        <BodyWithBreak
          testId="height"
          faxPrint={faxPrint}
        >{`${translations.patient.formGeneration.height}: ${height}cm`}</BodyWithBreak>
      )}
      {formattedAddressInfo && (
        <BodyWithBreak
          testId="address"
          faxPrint={faxPrint}
        >{`${translations.address.address}: ${formattedAddressInfo}`}</BodyWithBreak>
      )}
    </div>
  );
}

function Insurance({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  locale: TranslationsLocale;
  patient: Patient | undefined;
  translations: Translations;
}) {
  if (
    !patient?.profile?.financing?.insurance &&
    !patient?.profile?.financing?.insurance_number &&
    !patient?.profile?.financing?.insurance?.name
  ) {
    return null;
  }

  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.insurance}
      </Subheading>
      {patient.profile.financing.patient_has_health_insurance_state !==
        PREDICAMENT_STATE_NOT_APPLICABLE && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="patient_has_health_insurance_state"
        >
          {getPredicamentValue({
            label: translations.patient.hasHealthInsurance,
            translations,
            value: patient.profile.financing.patient_has_health_insurance_state,
          })}
        </BodyWithBreak>
      )}
      {patient.profile.financing.insurance?.name && (
        <BodyWithBreak
          testId="financing_insurance_name"
          faxPrint={faxPrint}
        >{`${translations.patient.insuranceProvider}: ${patient.profile.financing.insurance.name}`}</BodyWithBreak>
      )}
      {patient.profile.financing.insurance_number?.decrypted && (
        <BodyWithBreak
          testId="financing_insurance_number"
          faxPrint={faxPrint}
        >{`${translations.patient.insuranceNumber}: ${patient.profile.financing.insurance_number?.decrypted}`}</BodyWithBreak>
      )}
    </div>
  );
}

function Carelevel({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  locale: TranslationsLocale;
  patient: Patient | undefined;
  translations: Translations;
}) {
  if (!patient?.profile?.financing?.carelevel) {
    return null;
  }

  const { carelevel, public_care_insurance_status } = patient.profile.financing;

  return (
    <div>
      <Subheading bold>{translations.patient.carelevel}</Subheading>
      <BodyWithBreak faxPrint={faxPrint} testId="carelevel">
        {getCareLevelValue(carelevel, translations).label}
      </BodyWithBreak>

      {(carelevel?.higher_level || public_care_insurance_status) && (
        <BodyWithBreak faxPrint={faxPrint} testId="higher_level">
          {translations.patient.hasAppliedforHigherCarelevel}
        </BodyWithBreak>
      )}
      {carelevel?.expedited_request && (
        <BodyWithBreak faxPrint={faxPrint} testId="expedited_request">
          {translations.patient.expeditedRequest}
        </BodyWithBreak>
      )}
      {carelevel?.has_applied && (
        <BodyWithBreak faxPrint={faxPrint} testId="has_applied">
          {translations.patient.hasAppliedforCarelevel}
        </BodyWithBreak>
      )}
    </div>
  );
}

function Payout({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  locale: TranslationsLocale;
  patient: Patient | undefined;
  translations: Translations;
}) {
  if (!patient?.profile?.financing?.payout) return null;

  return (
    <BasicCategory
      testId="payout"
      faxPrint={faxPrint}
      title={translations.patient.patientInformation.payout}
      text={getOntology({
        type: "grantsType",
        key: patient.profile.financing.payout,
      })}
      column
    />
  );
}

function LivingSituation({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  locale: TranslationsLocale;
  patient: Patient | undefined;
  translations: Translations;
}) {
  if (!patient?.profile) return null;

  const {
    lift_available_description,
    lift_available_state,
    living_situation,
    living_situation_other,
    unsafe_current_domestic_situation,
  } = patient.profile;

  if (
    !living_situation &&
    !lift_available_state &&
    !lift_available_description &&
    !unsafe_current_domestic_situation
  ) {
    return null;
  }

  const livingSituation = getOntology({
    type: "livingSituation",
    key: living_situation as number,
  });
  const other = living_situation === LIVING_SITUATION_OTHER;

  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.livingSituation}
      </Subheading>
      <BodyWithBreak
        faxPrint={faxPrint}
        testId={other ? "living_situation_other" : "living_situation"}
      >
        {`${translations.patient.patientInformation.patientIsLiving}: ${
          other ? living_situation_other || "" : livingSituation
        }`}
      </BodyWithBreak>
      {lift_available_state !== PREDICAMENT_STATE_NOT_APPLICABLE && (
        <BodyWithBreak faxPrint={faxPrint} testId="lift_available_state">
          {getPredicamentValue({
            label: translations.patient.patientInformation.liftAvailable,
            value: lift_available_state,
            translations,
          })}
        </BodyWithBreak>
      )}
      {lift_available_description && (
        <BodyWithBreak faxPrint={faxPrint} testId="lift_available_description">
          {lift_available_description}
        </BodyWithBreak>
      )}
      {unsafe_current_domestic_situation && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="unsafe_current_domestic_situation"
        >
          {`${translations.patient.patientInformation.unsafeCurrentDomesticSituation}: ${unsafe_current_domestic_situation}`}
        </BodyWithBreak>
      )}
    </div>
  );
}

function GeneralPractitioner({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  locale: TranslationsLocale;
  patient: Patient | undefined;
  translations: Translations;
}) {
  if (!patient?.profile?.general_practitioner) return null;

  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.generalPractitionerTitle}
      </Subheading>
      <BodyWithBreak faxPrint={faxPrint} testId="general_practitioner_name">
        {patient.profile.general_practitioner.name}
      </BodyWithBreak>
      <BodyWithBreak
        faxPrint={faxPrint}
        testId="general_practitioner_contact_details"
      >
        {patient.profile.general_practitioner.contact_details}
      </BodyWithBreak>
    </div>
  );
}

function Communication({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  locale: TranslationsLocale;
  patient: Patient | undefined;
  translations: Translations;
}) {
  if (!patient?.profile?.communication) return null;

  const { communication } = patient.profile;

  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.decisionsLanguages}
      </Subheading>

      {communication.patient_is_contact && (
        <BodyWithBreak faxPrint={faxPrint} testId="patient_is_contact">
          {communication.patient_is_contact_description?.decrypted
            ? `${translations.patient.patientInformation.contactIsPatient}: ${communication.patient_is_contact_description.decrypted}`
            : translations.patient.patientInformation.contactIsPatient}
        </BodyWithBreak>
      )}
      {communication.relatives_available && (
        <BodyWithBreak faxPrint={faxPrint} testId="relatives_available">
          {communication.relatives_description?.decrypted
            ? `${translations.patient.patientInformation.relativesAvailable}: ${communication.relatives_description?.decrypted}`
            : translations.patient.patientInformation.relativesAvailable}
        </BodyWithBreak>
      )}
      {communication.has_guardian && (
        <>
          <BodyWithBreak faxPrint={faxPrint} testId="has_guardian">
            {`${translations.patient.patientInformation.hasGuardian}
            ${
              communication.guardian_contact_information?.decrypted
                ? `${translations.general.colon} ${communication.guardian_contact_information?.decrypted}`
                : communication.guardian_description
                ? `${translations.general.colon} ${communication.guardian_description}`
                : ""
            }`}
          </BodyWithBreak>
          {communication.guardian_requested && (
            <BodyWithBreak faxPrint={faxPrint} testId="guardian_requested">
              {translations.patient.patientInformation.guardianRequested}
            </BodyWithBreak>
          )}
        </>
      )}
      {communication.patient_cannot_communicate_in_german && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="patient_cannot_communicate_in_german"
        >
          {`${
            translations.patient.patientInformation
              .patientCannotCommunicateInGerman.label
          }: ${communication.patient_language || ""}`}
        </BodyWithBreak>
      )}
      {communication.patient_uses_sign_language && (
        <BodyWithBreak faxPrint={faxPrint} testId="patient_uses_sign_language">
          {`${translations.patient.patientInformation.patientUsesSignInLanguage.label}: ${communication.patient_uses_sign_language}`}
        </BodyWithBreak>
      )}
    </div>
  );
}

function Payers({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  locale: TranslationsLocale;
  translations: Translations;
}) {
  if (!auction.payers) return null;

  const { payers } = auction;

  const labels = getPayersLabels(translations);

  const selectedField =
    payers.selected_payment_method &&
    PAYERS_MAPPING[payers.selected_payment_method];

  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.payers}
      </Subheading>
      {payers.selected_payment_method && (
        <BodyWithBreak faxPrint={faxPrint} testId="selected_payment_method">
          {`${labels.selected_payment_method}: ${
            labels[selectedField as keyof typeof labels]
          }`}
        </BodyWithBreak>
      )}
      {payers.insurance?.name && (
        <BodyWithBreak faxPrint={faxPrint} testId="payers_insurance_name">
          {`${translations.patient.patientInformation.payersInsurance}: ${payers.insurance.name}`}
        </BodyWithBreak>
      )}
      {payers.civil_servants_aid && (
        <BodyWithBreak faxPrint={faxPrint} testId="civil_servants_aid">
          {`${translations.patient.patientInformation.civilServantsAid}: ${payers.civil_servants_aid}`}
        </BodyWithBreak>
      )}
      {payers.public_accident_insurance && (
        <BodyWithBreak faxPrint={faxPrint} testId="public_accident_insurance">
          {`${translations.patient.patientInformation.publicAccidentInsurance}: ${payers.public_accident_insurance}`}
        </BodyWithBreak>
      )}
      {payers.public_pension?.name && (
        <BodyWithBreak faxPrint={faxPrint} testId="public_pension_name">
          {`${translations.patient.patientInformation.publicPension}: ${payers.public_pension.name}`}
        </BodyWithBreak>
      )}
      {payers.self_payer && (
        <BodyWithBreak faxPrint={faxPrint} testId="self_payer">
          {`${translations.patient.patientInformation.selfPayer}: ${payers.self_payer}`}
        </BodyWithBreak>
      )}
      {payers.other_payer && (
        <BodyWithBreak faxPrint={faxPrint} testId="payers_other_payer">
          {`${translations.patient.patientInformation.payersOther}: ${payers.other_payer}`}
        </BodyWithBreak>
      )}
      {payers.further_information && (
        <BodyWithBreak faxPrint={faxPrint} testId="payers_further_information">
          {`${translations.patient.patientInformation.payersMoreInformations}: ${payers.further_information}`}
        </BodyWithBreak>
      )}
      {payers.supplementary_insurance_state !==
        PREDICAMENT_STATE_NOT_APPLICABLE && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="supplementary_insurance_state"
        >
          {getPredicamentValue({
            label:
              translations.patient.patientInformation.supplementaryInsurance,
            value: payers.supplementary_insurance_state,
            translations,
          })}
        </BodyWithBreak>
      )}
      {payers.supplementary_insurance && (
        <BodyWithBreak faxPrint={faxPrint} testId="supplementary_insurance">
          {payers.supplementary_insurance}
        </BodyWithBreak>
      )}
      {payers.supplementary_payment_state !==
        PREDICAMENT_STATE_NOT_APPLICABLE && (
        <BodyWithBreak faxPrint={faxPrint} testId="supplementary_payment_state">
          {getPredicamentValue({
            label: translations.patient.patientInformation.supplementaryPayment,
            value: payers.supplementary_payment_state,
            translations,
          })}
        </BodyWithBreak>
      )}
      {payers.supplementary_payment && (
        <BodyWithBreak faxPrint={faxPrint} testId="supplementary_payment">
          {payers.supplementary_payment}
        </BodyWithBreak>
      )}
      {payers.free_from_supplementary_payment_state !==
        PREDICAMENT_STATE_NOT_APPLICABLE && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="free_from_supplementary_payment_state"
        >
          {getPredicamentValue({
            label:
              translations.patient.patientInformation
                .freeFromSupplementaryPayment,
            translations,
            value: payers.free_from_supplementary_payment_state,
          })}
        </BodyWithBreak>
      )}
    </div>
  );
}

function Grants({
  auction,
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  auction: Auction | undefined;
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  locale: TranslationsLocale;
  patient: Patient | undefined;
  translations: Translations;
}) {
  if (
    !patient?.profile?.financing ||
    auction?.search_type === SEARCH_TYPE_HOSPITAL
  ) {
    return null;
  }

  const { financing } = patient.profile;

  const keys: (keyof Financing)[] = [
    "reimbursment_for_help_at_home",
    "reimbursment_secured",
    "preventative_care",
    "short_term_care",
    "relief_services",
    "private_payment_bool",
    "more_information",
    "social_help_recipient_bool",
  ];

  if (!keys.some((attr) => financing[attr] != null)) return null;

  return (
    <div>
      <Subheading bold>{translations.patient.grants}</Subheading>
      <GrantStatusField
        value={financing.reimbursment_for_help_at_home}
        testId="reimbursment_for_help_at_home"
        label={translations.patient.reimbursmentForHelpAtHome}
        getOntology={getOntology}
      />
      <GrantStatusField
        value={financing.reimbursment_secured}
        testId="reimbursment_secured"
        getOntology={getOntology}
        label={translations.patient.reimbursmentSecured}
      />
      <GrantStatusField
        getOntology={getOntology}
        value={financing.preventative_care}
        testId="preventative_care"
        label={translations.patient.preventativeCare}
      />
      <ShortTermCareGrant
        getOntology={getOntology}
        value={financing.short_term_care}
        testId="short_term_care"
        withDiff={false}
      />
      <GrantStatusField
        value={financing.social_help_recipient_bool}
        testId="social_help_recipient_bool"
        getOntology={getOntology}
        label={translations.patient.socialHelpRecipient}
      />
      <BooleanField
        value={financing.private_payment_bool}
        label={translations.patient.patientInformation.acceptsPrivatePayment}
      />
      <OntologyField
        getOntology={getOntology}
        value={financing.relief_services}
        type="reliefServices"
        wrap={(content) => (
          <BodyWrapper>
            <Body
              margin={margin(0, -1.5, 0, 0)}
            >{`${translations.ontologies.reliefServices.key}${translations.general.colon} `}</Body>
            {content}
          </BodyWrapper>
        )}
      />
      {financing.more_information && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="more_information"
        >{`${translations.patient.patientInformation.grantsMoreInformation}: ${financing.more_information}`}</BodyWithBreak>
      )}
    </div>
  );
}

function Preferences({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  locale: TranslationsLocale;
  patient: Patient | undefined;
  translations: Translations;
}) {
  if (!patient?.profile?.preferences) return null;

  const { preferences } = patient.profile;

  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.specialRequirements}
      </Subheading>
      {preferences.room_type != null && (
        <BodyWithBreak faxPrint={faxPrint} testId="room_type">
          {getOntology({ type: "roomType", key: preferences.room_type })}
        </BodyWithBreak>
      )}
      {preferences.barrier_free_room && (
        <BodyWithBreak faxPrint={faxPrint} testId="barrier_free_room">
          {translations.patient.barrierFreeRoom}
        </BodyWithBreak>
      )}
      {preferences.accompanying_person && (
        <BodyWithBreak faxPrint={faxPrint} testId="accompanying_person">
          {translations.patient.accompanyingPerson}
        </BodyWithBreak>
      )}
      {preferences.specialist_doctor && (
        <BodyWithBreak faxPrint={faxPrint} testId="specialist_doctor">
          {translations.patient.specialistDoctor}
        </BodyWithBreak>
      )}
    </div>
  );
}

function MainDiagnosis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.main_diagnosis) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalDiagnosis.mainDiagnosis.label}
      text={patient.diagnosis.main_diagnosis}
      multiline
      column
      testId="main_diagnosis"
    />
  );
}

function SecondaryDiagnosis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.secondary_diagnosis) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalDiagnosis.secondaryDiagnosis}
      text={patient.diagnosis.secondary_diagnosis}
      multiline
      column
      testId="secondary_diagnosis"
    />
  );
}

function AdditionalSpecializations({
  auction,
  faxPrint,
  getOntology,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  translations: Translations;
}) {
  return (
    <EmptyablePrintCategory
      translations={translations}
      title={translations.patient.specialisedAdvices}
      empty={auction.additional_specializations === null}
      faxPrint={faxPrint}
      testId="additional_specializations"
    >
      <OntologyField
        getOntology={getOntology}
        value={auction.additional_specializations}
        type="specializations"
      />
    </EmptyablePrintCategory>
  );
}

function MorbidityStatus({
  faxPrint,
  status,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  status: boolean | null | undefined;
  translations: Translations;
}) {
  if (!status) return null;

  return (
    <BodyWithBreak faxPrint={faxPrint} testId="multimorbidity">
      {translations.patient.medicalDiagnosis.multiMorbidPatient}
    </BodyWithBreak>
  );
}

function MedicalHistory({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.medical_history) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalHistory}
      text={patient.diagnosis.medical_history}
      multiline
      column
      testId="medical_history"
    />
  );
}

function AdditionalExaminationsNecessary({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.additional_examinations_necessary) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.additionalExaminations}
      text={patient.diagnosis.additional_examinations_necessary}
      multiline
      column
      testId="additional_examinations_necessary"
    />
  );
}

function PalliativeCare({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.palliative) return null;

  const { palliative } = patient.care_needs;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.palliativeCare.label}
      empty={
        !palliative.description && !palliative.palliative_certificate_needed
      }
      translations={translations}
      testId="palliative"
    >
      <BodyWithBreak faxPrint={faxPrint} testId="palliative_description">
        {palliative.description}
      </BodyWithBreak>
      <BodyWithBreak faxPrint={faxPrint} testId="palliative_certificate_needed">
        {translations.patient.medicalDiagnosis.palliativeCertificateNeeded}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function DependencyDiagnosis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.dependency_diagnosis) {
    return null;
  }

  const { dependency_diagnosis } = patient.diagnosis;

  return (
    <div>
      <Subheading bold>{translations.patient.autonomy}</Subheading>
      <IndependenceScaleResult
        testId="barthel-assessment-result"
        title={translations.patient.medicalDiagnosis.barthelIndexPlaceholder}
        value={dependency_diagnosis?.barthel_assessment}
      />
      <IndependenceScaleResult
        testId="singer-assessment-result"
        title={translations.patient.medicalDiagnosis.singerIndexPlaceholder}
        value={dependency_diagnosis?.singer_assessment}
      />
      <IndependenceScaleResult
        testId="early-rehab-assessment-result"
        title={
          translations.patient.medicalDiagnosis
            .earlyRehabBarthelIndexPlaceholder
        }
        value={dependency_diagnosis?.early_rehab_assessment}
      />
      {dependency_diagnosis.requires_walking_aid !==
        PREDICAMENT_STATE_NOT_APPLICABLE && (
        <BodyWithBreak faxPrint={faxPrint} testId="requires_walking_aid">
          {getPredicamentValue({
            label: translations.patient.needsHelpWalking,
            translations,
            value: dependency_diagnosis.requires_walking_aid,
          })}
        </BodyWithBreak>
      )}
      {dependency_diagnosis.requires_support_with_feeding !==
        PREDICAMENT_STATE_NOT_APPLICABLE && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="requires_support_with_feeding"
        >
          {getPredicamentValue({
            label: translations.patient.needsHelpEating,
            translations,
            value: dependency_diagnosis.requires_support_with_feeding,
          })}
        </BodyWithBreak>
      )}
      {dependency_diagnosis.agitated !== PREDICAMENT_STATE_NOT_APPLICABLE && (
        <>
          <BodyWithBreak faxPrint={faxPrint} testId="agitated">
            {getPredicamentValue({
              label: translations.patient.agitationOrientation,
              translations,
              value: dependency_diagnosis.agitated,
            })}
          </BodyWithBreak>
          {dependency_diagnosis.agitated_description && (
            <BodyWithBreak faxPrint={faxPrint} testId="agitated_description">
              {dependency_diagnosis.agitated_description}
            </BodyWithBreak>
          )}
        </>
      )}
      {dependency_diagnosis?.has_help_at_home !==
        PREDICAMENT_STATE_NOT_APPLICABLE && (
        <BodyWithBreak faxPrint={faxPrint} testId="has_help_at_home">
          {getPredicamentValue({
            label: translations.patient.hasHelpAtHome,
            translations,
            value: dependency_diagnosis.has_help_at_home,
          })}
        </BodyWithBreak>
      )}
      {dependency_diagnosis?.returning_home_short_term !==
        PREDICAMENT_STATE_NOT_APPLICABLE && (
        <BodyWithBreak faxPrint={faxPrint} testId="returning_home_short_term">
          {getPredicamentValue({
            label: translations.patient.medicalDiagnosis.shortTerm,
            translations,
            value: dependency_diagnosis.returning_home_short_term,
          })}
        </BodyWithBreak>
      )}
    </div>
  );
}

function ChronicPains({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.chronic_pains) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalDiagnosis.chronicPains}
      text={patient.diagnosis.chronic_pains}
      testId="chronic_pains"
    />
  );
}

function ImportantInformation({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient?.care_needs?.important_information) return null;

  return (
    <>
      <Subheading bold>
        {translations.patient.careNeeds.titleImportantInformationCare}
      </Subheading>
      <BodyWithBreak faxPrint={faxPrint} testId="important_information">
        {patient.care_needs.important_information}
      </BodyWithBreak>
    </>
  );
}

function Dysphagia({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.dysphagia) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.dysphagia.label}
      text={patient.diagnosis.dysphagia}
      column
      testId="dysphagia"
    />
  );
}

function Allergies({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.allergies) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalDiagnosis.allergies}
      text={patient.diagnosis.allergies}
      multiline={false}
      testId="allergies"
    />
  );
}

function IcdCodesForPrint({
  patient,
  translations,
}: {
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (
    !patient.diagnosis?.icd_code &&
    !patient.diagnosis?.additional_icd_codes
  ) {
    return null;
  }

  return (
    <IcdCodesCommon
      translations={translations}
      value={{
        icd_code: patient.diagnosis.icd_code,
        additional_icd_codes: patient.diagnosis.additional_icd_codes,
      }}
    />
  );
}

function CoronaVirusTesting({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  const { corona, corona_state } = patient?.diagnosis || {};

  if (
    !corona_state &&
    !corona?.test_result &&
    !corona?.description &&
    !corona?.vaccinated
  ) {
    return null;
  }

  const { label } = coronaSwitch(translations, { corona, corona_state });

  return (
    <>
      <Subheading bold>{translations.coronaTesting.auctionLabel}</Subheading>
      {label && (
        <BodyWithBreak faxPrint={faxPrint} testId="corona_label">
          {translations.coronaTesting.results}
          {translations.general.colon} {label}
        </BodyWithBreak>
      )}
      {(corona_state === CORONA_NOT_TESTED ||
        corona_state === CORONA_TESTED) && (
        <BodyWithBreak faxPrint={faxPrint} testId="corona_state">
          {corona?.description}
        </BodyWithBreak>
      )}
      <BodyWithBreak faxPrint={faxPrint} testId="vaccinated">
        {typeof corona?.vaccinated === "string"
          ? `${translations.coronaTesting.hasBeenVaccinated}${
              corona?.vaccinated ? ` : ${corona?.vaccinated}` : ""
            }`
          : translations.coronaTesting.vaccinationUnknown}
      </BodyWithBreak>
    </>
  );
}

function InfectionAndGerms({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;

  const { infection_and_germs, infection_and_germs_state } = patient.diagnosis;

  const [negativeState, negativeStateMessage] =
    isInfectionsAndGermsPredicamentNegative(
      { infection_and_germs_state },
      translations,
    );

  if (negativeState) {
    return (
      <>
        <Subheading bold>
          {translations.patient.medicalDiagnosis.infections}
        </Subheading>
        <BodyWithBreak faxPrint={faxPrint} testId="infection_and_germs_state">
          {negativeStateMessage}
        </BodyWithBreak>
      </>
    );
  }

  if (!infection_and_germs) {
    return null;
  }

  return (
    <div data-testid="infection_and_germs_state">
      <Subheading bold>
        {translations.patient.medicalDiagnosis.infections}
      </Subheading>
      <BooleanField
        value={infection_and_germs.requires_isolation}
        label={
          translations.patient.medicalDiagnosis.infectionsRequiresIsolation
        }
      />
      <BooleanField
        value={infection_and_germs.mrsa}
        label={translations.patient.medicalDiagnosis.infectionsMRSA}
      />
      <BooleanField
        value={infection_and_germs.clostridien}
        label={translations.patient.medicalDiagnosis.infectionsClostridien}
      />
      <BooleanField
        value={infection_and_germs.three_mrgn}
        label={translations.patient.medicalDiagnosis.infectionsThreeMRGN}
      />
      <BooleanField
        value={infection_and_germs.four_mrgn}
        label={translations.patient.medicalDiagnosis.infectionsFourMRGN}
      />
      <BooleanField
        value={infection_and_germs.vre}
        label={translations.patient.medicalDiagnosis.infectionsVRE}
      />
      <StringField
        value={infection_and_germs.remarks}
        testId="infection_and_germs_remarks"
      />
      <StringField
        value={infection_and_germs.other}
        testId="infection_and_germs_other"
      />
    </div>
  );
}

function Addictions({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.addictions) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalDiagnosis.addictions}
      text={patient.diagnosis.addictions}
      column
      testId="addictions"
    />
  );
}

function PatientOrientation({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.ontologies.orientation.key}
      text={getOntology({
        type: ONTOLOGY_ORIENTATION,
        key: patient.diagnosis.mental_health.orientation,
      })}
      column
      testId="mental_health"
    />
  );
}

function PatientCommunication({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health?.communication) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.ontologies.communication.key}
      text={getOntology({
        type: ONTOLOGY_COMMUNICATION,
        key: patient.diagnosis.mental_health.communication,
      })}
      column
      testId="communication"
    />
  );
}

function PatientMotivation({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health?.motivation) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.ontologies.motivation.key}
      text={getOntology({
        type: "motivation",
        key: patient.diagnosis.mental_health.motivation,
      })}
      column
      testId="motivation"
    />
  );
}

function Dementia({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health) return null;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.mentalState.dementia.label}
      empty={!patient.diagnosis.mental_health.dementia_description}
      translations={translations}
      testId="dementia_description"
    >
      <BodyWithBreak faxPrint={faxPrint} testId="dementia_description">
        {patient.diagnosis.mental_health.dementia_description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function RunAway({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health?.run_away_description) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.closedAreaNeeded}
      text={patient.diagnosis.mental_health.run_away_description}
      column
      testId="run_away_description"
    />
  );
}

function Nightwatch({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health?.night_watch_description) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.mentalState.nightwatch.label}
      text={patient.diagnosis.mental_health.night_watch_description}
      column
      testId="night_watch_description"
    />
  );
}

function FacilityCharacteristics({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (
    !patient.diagnosis?.mental_health?.closed_station &&
    !patient.diagnosis?.mental_health?.fixation
  ) {
    return null;
  }

  const { closed_station, fixation } = patient.diagnosis.mental_health;

  return (
    <div>
      <Subheading bold>
        {translations.patient.medicalDiagnosis.hospitalStay}
      </Subheading>
      {closed_station && (
        <BodyWithBreak faxPrint={faxPrint} testId="closed_station">
          {translations.patient.mentalState.closedStationRequired}
        </BodyWithBreak>
      )}
      {fixation && (
        <BodyWithBreak faxPrint={faxPrint} testId="fixation">
          {translations.patient.mentalState.fixationRequired}
        </BodyWithBreak>
      )}
    </div>
  );
}

function MentalIssues({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health) return null;

  const { mental_health } = patient.diagnosis;

  return (
    <div>
      <Subheading bold>
        {translations.patient.mentalState.mentalIssues}
      </Subheading>
      {mental_health.depression && (
        <BodyWithBreak faxPrint={faxPrint} testId="depression">
          {translations.patient.mentalState.depression}
        </BodyWithBreak>
      )}
      {mental_health.anxiety && (
        <BodyWithBreak faxPrint={faxPrint} testId="anxiety">
          {translations.patient.mentalState.anxiety}
        </BodyWithBreak>
      )}
      {mental_health.aggressivity && (
        <BodyWithBreak faxPrint={faxPrint} testId="aggressivity">
          {translations.patient.mentalState.aggressivity}
        </BodyWithBreak>
      )}
      {mental_health.dangerous && (
        <BodyWithBreak faxPrint={faxPrint} testId="dangerous">
          {translations.patient.mentalState.dangerous}
        </BodyWithBreak>
      )}
      {mental_health.mental_issues && (
        <BodyWithBreak
          testId="mental_issues"
          faxPrint={faxPrint}
        >{`${translations.patient.mentalState.mentalIssuesOther}: ${mental_health.mental_issues}`}</BodyWithBreak>
      )}
    </div>
  );
}

function PatientMobility({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities?.mobility) return null;

  return (
    <div>
      <Subheading bold>{translations.ontologies.mobility.key}</Subheading>
      <BodyWithBreak faxPrint={faxPrint} testId="mobility">
        {getOntology({
          type: ONTOLOGY_MOBILITY,
          key: patient.diagnosis.physical_disabilities.mobility,
        })}
      </BodyWithBreak>
      {patient.diagnosis.physical_disabilities
        .mobility_additional_information && (
        <BodyWithBreak faxPrint={faxPrint} testId="physical_disabilities">
          {
            patient.diagnosis.physical_disabilities
              .mobility_additional_information
          }
        </BodyWithBreak>
      )}
    </div>
  );
}

function VisualImpairment({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities?.visual_impairment) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.mobility.visual_impairment.label}
      text={patient.diagnosis.physical_disabilities.visual_impairment}
      column
      testId="visual_impairment"
    />
  );
}

function AuditiveImpairment({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities?.auditive_impairment) {
    return null;
  }

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.mobility.auditive_impairment.label}
      text={patient.diagnosis.physical_disabilities.auditive_impairment}
      column
      testId="auditive_impairment"
    />
  );
}

function AssistiveEquipment({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities) return null;

  const {
    assistive_equipment,
    existing_medical_supplies,
    prescribed_medical_supplies,
    required_medical_supplies,
  } = patient.diagnosis.physical_disabilities;

  const exists =
    !!assistive_equipment ||
    !!required_medical_supplies ||
    !!prescribed_medical_supplies ||
    !!existing_medical_supplies;
  const newValues =
    !!required_medical_supplies ||
    !!prescribed_medical_supplies ||
    !!existing_medical_supplies;
  return (
    <>
      {exists && (
        <Subheading bold>
          {translations.patient.mobility.assistiveEquipment}
        </Subheading>
      )}
      {assistive_equipment && !newValues && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="assistive_equipment"
        >{`${assistive_equipment}`}</BodyWithBreak>
      )}
      {existing_medical_supplies && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="existing_medical_supplies"
        >{`${translations.patient.medicalSupplies.existingMedicalSupplies}: ${existing_medical_supplies}`}</BodyWithBreak>
      )}
      {prescribed_medical_supplies && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="prescribed_medical_supplies"
        >{`${translations.patient.medicalSupplies.prescribedMedicalSupplies}: ${prescribed_medical_supplies}`}</BodyWithBreak>
      )}
      {required_medical_supplies && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="required_medical_supplies"
        >{`${translations.patient.medicalSupplies.neededMedicalSupplies}: ${required_medical_supplies}`}</BodyWithBreak>
      )}
    </>
  );
}

function Orthosis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities?.orthese) return null;

  return (
    <BasicCategory
      testId="orthese"
      faxPrint={faxPrint}
      title={translations.patient.mobility.orthese.label}
      text={patient.diagnosis.physical_disabilities.orthese}
      column
    />
  );
}

function Prothesis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities?.prothese) return null;

  return (
    <BasicCategory
      testId="prothese"
      faxPrint={faxPrint}
      title={translations.patient.mobility.prothese.label}
      text={patient.diagnosis.physical_disabilities.prothese}
      column
    />
  );
}

function HospitalStay({
  faxPrint,
  forClinic,
  locale,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  forClinic?: boolean;
  getOntology: GetOntologyType;
  locale: TranslationsLocale;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.hospital_stay) return null;

  const { hospital_stay } = patient.diagnosis;

  return (
    <div>
      <Subheading bold>
        {translations.patient.medicalDiagnosis.hospitalStay}
      </Subheading>
      {forClinic && (
        <>
          {hospital_stay.doctor_in_charge_in_hospital && (
            <BodyWithBreak
              faxPrint={faxPrint}
              testId="doctor_in_charge_in_hospital"
            >
              {`${translations.patient.medicalDiagnosis.doctorInChargeInHospital}: ${hospital_stay.doctor_in_charge_in_hospital}`}
            </BodyWithBreak>
          )}
          {hospital_stay.doctor_in_charge_in_hospital_phone && (
            <BodyWithBreak
              faxPrint={faxPrint}
              testId="doctor_in_charge_in_hospital_phone"
            >
              {`${translations.patient.stationPhoneNumber}: ${hospital_stay.doctor_in_charge_in_hospital_phone}`}
            </BodyWithBreak>
          )}
        </>
      )}
      {hospital_stay.operation_date && (
        <BodyWithBreak faxPrint={faxPrint} testId="operation_date">
          {`${
            translations.patient.medicalDiagnosis.operationDate
          }: ${formatUnixDate(hospital_stay.operation_date, locale)}`}
        </BodyWithBreak>
      )}
      {hospital_stay.operation_description && (
        <BodyWithBreak faxPrint={faxPrint} testId="operation_description">
          {`${translations.patient.medicalDiagnosis.operationDescription}: ${hospital_stay.operation_description}`}
        </BodyWithBreak>
      )}
      {hospital_stay.geriatric_early_stage && (
        <BodyWithBreak faxPrint={faxPrint} testId="geriatric_early_stage">
          {translations.patient.medicalDiagnosis.conductedEarlyRehab}:
          {hospital_stay.geriatric_early_stage}
        </BodyWithBreak>
      )}
    </div>
  );
}

function RehabilitationPotential({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.rehabilitation_potential) return null;

  const { rehabilitation_potential } = patient.diagnosis;

  return (
    <div>
      <Subheading bold>
        {translations.patient.medicalDiagnosis.rehabilitationPotential}
      </Subheading>
      {rehabilitation_potential.evaluation != null && (
        <BodyWithBreak faxPrint={faxPrint} testId="evaluation">
          {getOntology({
            type: "rehabilitationPotential",
            key: rehabilitation_potential.evaluation,
          })}
        </BodyWithBreak>
      )}
      {rehabilitation_potential.description && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="rehabilitation_potential_description"
        >
          {rehabilitation_potential.description}
        </BodyWithBreak>
      )}
    </div>
  );
}

function LimitedCapacity({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (
    !patient?.diagnosis?.limited_capacity ||
    !activableInputCollectionIsNotEmpty(patient.diagnosis.limited_capacity)
  ) {
    return null;
  }

  const { limited_capacity } = patient.diagnosis;

  return (
    <div>
      <Subheading bold>
        {translations.patient.medicalDiagnosis.limitations}
      </Subheading>
      {limited_capacity.limited_psychological_capacity && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="limited_psychological_capacity"
        >
          {`${translations.patient.medicalDiagnosis.limitedPsychologicalCapacity}: ${limited_capacity.limited_psychological_capacity}`}
        </BodyWithBreak>
      )}
      {limited_capacity.limited_cognitive_capacity && (
        <BodyWithBreak faxPrint={faxPrint} testId="limited_cognitive_capacity">
          {`${translations.patient.medicalDiagnosis.limitedCognitiveCapacity}: ${limited_capacity.limited_cognitive_capacity}`}
        </BodyWithBreak>
      )}
      {limited_capacity.limited_social_behaviour && (
        <BodyWithBreak faxPrint={faxPrint} testId="limited_social_behaviour">
          {`${translations.patient.medicalDiagnosis.limitedSocialBehaviour}: ${limited_capacity.limited_social_behaviour}`}
        </BodyWithBreak>
      )}
      {limited_capacity.limited_physical_capacity && (
        <BodyWithBreak faxPrint={faxPrint} testId="limited_physical_capacity">
          {`${translations.patient.medicalDiagnosis.limitedPsychicalCapacity}: ${limited_capacity.limited_physical_capacity}`}
        </BodyWithBreak>
      )}
      {limited_capacity.other && (
        <BodyWithBreak faxPrint={faxPrint} testId="limited_capacity_other">
          {`${translations.patient.medicalDiagnosis.limitedCapacityOther}: ${limited_capacity.other}`}
        </BodyWithBreak>
      )}
    </div>
  );
}

function RehabilitationGoals({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.rehabilitation_goals) return null;

  return (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalDiagnosis.rehabilitationGoals}
      text={patient.diagnosis.rehabilitation_goals}
      column
      testId="rehabilitation_goals"
    />
  );
}

function Nutrition({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.nutrition) return null;

  const { nutrition } = patient.care_needs;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.nutrition}
      empty={
        nutrition.parenteral_nutrition == null &&
        nutrition.enteral_nutrition == null
      }
      translations={translations}
      testId="nutrition"
    >
      {nutrition.parenteral_nutrition && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="parenteral_nutrition"
        >{`${translations.patient.careNeeds.parenteralNutrition.label}: ${nutrition.parenteral_nutrition}`}</BodyWithBreak>
      )}
      {nutrition.enteral_nutrition && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="enteral_nutrition"
        >{`${translations.patient.careNeeds.enteralNutrition.label}: ${nutrition.enteral_nutrition}`}</BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function Incontinence({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.incontinence) return null;

  const { incontinence } = patient.care_needs;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.incontinence}
      empty={
        !incontinence.urinary_incontinence && !incontinence.fecal_incontinence
      }
      translations={translations}
      testId="incontinence"
    >
      {incontinence.urinary_incontinence && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="urinary_incontinence"
        >{`${translations.patient.careNeeds.urinaryIncontinence}: ${incontinence.urinary_incontinence}`}</BodyWithBreak>
      )}
      {incontinence.fecal_incontinence && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="fecal_incontinence"
        >{`${translations.patient.careNeeds.fecalIncontinence}: ${incontinence.fecal_incontinence}`}</BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function Breathing({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.breathing) return null;

  const { breathing } = patient.care_needs;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.breathing}
      empty={
        !breathing.tracheostomy &&
        !breathing.specialised_intensive_nurse &&
        !breathing.oxygen_therapy
      }
      testId="breathing"
      translations={translations}
    >
      {breathing.tracheostomy && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="tracheostomy"
        >{`${translations.patient.careNeeds.tracheostomy.label}: ${breathing.tracheostomy}`}</BodyWithBreak>
      )}
      {breathing.specialised_intensive_nurse && (
        <BodyWithBreak faxPrint={faxPrint} testId="specialised_intensive_nurse">
          {translations.ontologies.service.values.specialisedIntensiveNurse}
        </BodyWithBreak>
      )}
      {breathing.oxygen_therapy && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="oxygen_therapy"
        >{`${translations.patient.careNeeds.oxygenTherapy.label}: ${breathing.oxygen_therapy}`}</BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function Dialysis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.dialysis) return null;

  const { dialysis } = patient.care_needs;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.dialysis}
      empty={!dialysis.hemodialysis && !dialysis.peritoneal_dialysis}
      translations={translations}
      testId="dialysis"
    >
      {dialysis.hemodialysis != null && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="hemodialysis"
        >{`${translations.patient.careNeeds.hemodialysis.label}: ${dialysis.hemodialysis}`}</BodyWithBreak>
      )}
      {dialysis.peritoneal_dialysis != null && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="peritoneal_dialysis"
        >{`${translations.patient.careNeeds.peritonealDialysis.label}: ${dialysis.peritoneal_dialysis}`}</BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function Wounds({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.wound_care) return null;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.woundCare}
      empty={!patient.care_needs.wound_care.description}
      translations={translations}
      testId="wound_care_description"
    >
      <BodyWithBreak faxPrint={faxPrint} testId="wound_care_description">
        {patient.care_needs.wound_care.description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Support({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.support) return null;

  const { support } = patient.care_needs;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.needSupport}
      empty={!support.eating && !support.body_care && !support.dressing}
      translations={translations}
      testId="support"
    >
      {support.eating && (
        <BodyWithBreak faxPrint={faxPrint} testId="eating">
          {translations.patient.careNeeds.withEating}
        </BodyWithBreak>
      )}
      {support.body_care && (
        <BodyWithBreak faxPrint={faxPrint} testId="body_care">
          {translations.patient.careNeeds.withBodyCare}
        </BodyWithBreak>
      )}
      {support.dressing && (
        <BodyWithBreak faxPrint={faxPrint} testId="dressing">
          {translations.patient.careNeeds.withDressing}
        </BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function PsychiatricCare({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.psychiatric_care) return null;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.psychiatric_care.label}
      empty={!patient.care_needs.psychiatric_care.description}
      translations={translations}
      testId="psychiatric_care"
    >
      <BodyWithBreak faxPrint={faxPrint} testId="psychiatric_care">
        {patient.care_needs.psychiatric_care.description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Medication({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.medication}
      empty={!patient.care_needs.medication}
      translations={translations}
      testId="medication"
    >
      <BodyWithBreak faxPrint={faxPrint} testId="medication">
        {patient.care_needs.medication?.description}
      </BodyWithBreak>
      {patient.care_needs.medication?.blood_thinner && (
        <BodyWithBreak faxPrint={faxPrint} testId="blood_thinner">
          {`${translations.patient.takesBloodThinner}${translations.general.colon} ${patient.care_needs.medication.blood_thinner}`}
        </BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function Compression({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.compression) return null;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.compression}
      empty={!patient.care_needs.compression.description}
      translations={translations}
      testId="compression"
    >
      <BodyWithBreak faxPrint={faxPrint} testId="compression">
        {patient.care_needs.compression.description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Injection({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.injection) return null;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.injection}
      empty={!patient.care_needs.injection.description}
      translations={translations}
      testId="injection"
    >
      <BodyWithBreak faxPrint={faxPrint} testId="injection">
        {patient.care_needs.injection.description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function AccessDrainages({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  const { access_drainages } = patient.care_needs || {
    access_drainages: undefined,
  };
  if (!access_drainages) return null;

  const keys: (keyof AccessDrainagesType)[] = [
    "tracheal_cannula",
    "gastric_tube",
    "central_venous_catheter",
    "peripheral_venous_catheter",
    "suprapubic_catheter",
    "permanent_catheter",
    "drainage",
    "intravenous_injection",
    "other_access_drainages",
  ];

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.accessDrainages.accessAndDrainages}
      empty={!keys.find((attr) => access_drainages[attr] != null)}
      translations={translations}
      testId="access_drainages"
    >
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={translations.patient.careNeeds.accessDrainages.trachealCannula}
        value={access_drainages.tracheal_cannula}
        testId="tracheal_cannula"
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={translations.patient.careNeeds.accessDrainages.gastricTube}
        value={access_drainages.gastric_tube}
        testId="gastric_tube"
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={
          translations.patient.careNeeds.accessDrainages.centralVenousCatheter
        }
        value={access_drainages.central_venous_catheter}
        testId="central_venous_catheter"
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={
          translations.patient.careNeeds.accessDrainages
            .peripheralVenousCatheter
        }
        value={access_drainages.peripheral_venous_catheter}
        testId="peripheral_venous_catheter"
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={
          translations.patient.careNeeds.accessDrainages.suprapubicCatheter
        }
        value={access_drainages.suprapubic_catheter}
        testId="suprapubic_catheter"
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={
          translations.patient.careNeeds.accessDrainages.permanentCatheter
        }
        value={access_drainages.permanent_catheter}
        testId="permanent_catheter"
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={translations.patient.careNeeds.accessDrainages.drainage}
        value={access_drainages.drainage}
        testId="drainage"
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={translations.patient.intravenousInjection}
        value={access_drainages.intravenous_injection}
        testId="intravenous_injection"
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={translations.patient.careNeeds.accessDrainages.other}
        value={access_drainages.other_access_drainages}
        testId="other_access_drainages"
      />
    </EmptyablePrintCategory>
  );
}

function BaseCare({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.base_care) return null;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.baseCare}
      empty={
        !patient.care_needs.base_care.description &&
        !patient.care_needs.base_care.companionship_grant
      }
      translations={translations}
      testId="base_care"
    >
      <BodyWithBreak faxPrint={faxPrint} testId="base_care">
        {patient.care_needs.base_care.description}
      </BodyWithBreak>
      {patient.care_needs.base_care.companionship_grant && (
        <BodyWithBreak faxPrint={faxPrint} testId="companionship_grant">
          {translations.patient.careNeeds.carePaidByHealthInsurance}
        </BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function MedicalCare({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.medicalCare}
      empty={!patient.care_needs?.basic_medical_care?.description}
      translations={translations}
      testId="basic_medical_care_description"
    >
      <BodyWithBreak
        faxPrint={faxPrint}
        testId="basic_medical_care_description"
      >
        {patient.care_needs?.basic_medical_care?.description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Prescription({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.assessments.medicalSupplies.prescriptionIssued}
      empty={!auction.medical_supplies?.prescription_issued}
      translations={translations}
      testId="prescription_issued"
    >
      <BodyWithBreak faxPrint={faxPrint} testId="prescription_issued">
        {auction.medical_supplies?.prescription_issued_description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Destination({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  if (
    !auction.profile?.search_destination ||
    !auction.profile?.search_location
  ) {
    return null;
  }

  const pickupLocationFormatted = formatLocation({
    location: auction.profile.search_location,
    translations,
    showFloor: true,
  });
  const destinationocationFormatted = formatLocation({
    location: auction.profile.search_destination,
    translations,
    showFloor: true,
  });

  return (
    <>
      {pickupLocationFormatted && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="search_location"
        >{`${translations.address.pickupAddress}: ${pickupLocationFormatted}`}</BodyWithBreak>
      )}
      {destinationocationFormatted && (
        <BodyWithBreak
          testId="search_destination"
          faxPrint={faxPrint}
        >{`${translations.assessments.transportSearch.searchDestination}: ${destinationocationFormatted}`}</BodyWithBreak>
      )}
    </>
  );
}

function Products({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.assessments.medicalSupplies.medicalSupplies}
      empty={!auction.medical_supplies?.products?.length}
      translations={translations}
      testId="medical_supplies_products"
    >
      {auction.medical_supplies?.products?.map((product) => (
        <BodyWithBreak
          key={product.id}
          faxPrint={faxPrint}
          testId="medical_supplies_products"
        >
          {formatProductString(product, translations)}
        </BodyWithBreak>
      ))}
      {auction.medical_supplies?.additional_information && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="medical_supplies_additional_information"
        >
          {auction.medical_supplies?.additional_information}
        </BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function PickUpSection({
  auction,
  currentLocale,
  faxPrint,
  translations,
}: {
  auction: Auction;
  currentLocale: DateFnsLocale;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  const pickUpTimeFormatted = formatPickupStringValue(auction, currentLocale);

  return (
    <>
      {pickUpTimeFormatted && (
        <BodyWithBreak
          testId="pick_up_time"
          faxPrint={faxPrint}
        >{`${translations.assessments.transportSearch.pickUpTime}: ${pickUpTimeFormatted}`}</BodyWithBreak>
      )}
    </>
  );
}

function DeliveryDetails({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  const { deliveryAddressPrefix, deliveryAddressString } = locationValue(
    auction,
    translations,
  );
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.assessments.medicalSupplies.deliveryDetails}
      empty={!auction.medical_supplies?.delivery_address_type}
      translations={translations}
      testId="delivery_address_type"
    >
      {deliveryAddressString && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="delivery_address_type"
        >{`${deliveryAddressPrefix}: ${deliveryAddressString}`}</BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function TransportEquipment({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  return (
    <>
      {auction?.profile?.no_site_support_required && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="no_site_support_required"
        >{`${translations.assessments.transportSearch.onsiteSupportRequired}`}</BodyWithBreak>
      )}
      {auction?.profile?.needs_oxygen ? (
        <BodyWithBreak faxPrint={faxPrint} testId="needs_oxygen">{`${
          translations.assessments.transportSearch.oxygenNeeded
        }: ${
          auction?.profile?.needs_oxygen
            ? translations.actions.yes
            : translations.actions.no
        }`}</BodyWithBreak>
      ) : null}
      {auction?.patient?.care_needs?.remarks && (
        <BodyWithBreak
          faxPrint={faxPrint}
          testId="care_needs_remarks"
        >{`${translations.patient.additionalInformation}: ${auction?.patient?.care_needs?.remarks}`}</BodyWithBreak>
      )}
    </>
  );
}

function Companionship({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.companionship) return null;

  return (
    <div>
      <Subheading bold>
        {translations.patient.careNeeds.companionship}
      </Subheading>
      <BodyWithBreak faxPrint={faxPrint} testId="companionship">
        {patient.care_needs.companionship.description}
      </BodyWithBreak>
      {patient.care_needs.companionship.companionship_grant && (
        <BodyWithBreak faxPrint={faxPrint} testId="companionship_grant">
          {translations.patient.careNeeds.carePaidByHealthInsurance}
        </BodyWithBreak>
      )}
    </div>
  );
}

function HelpAtHome({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.help_at_home) return null;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.helpAtHome}
      empty={!patient.care_needs.help_at_home.description}
      translations={translations}
      testId="care_needs.help_at_home_description"
    >
      <BodyWithBreak
        faxPrint={faxPrint}
        testId="care_needs.help_at_home_description"
      >
        {patient.care_needs.help_at_home.description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Remarks({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs?.remarks) return null;

  return (
    <div>
      <Subheading bold>{translations.patient.careNeeds.remarks}</Subheading>
      {patient.care_needs.remarks && (
        <BodyWithBreak faxPrint={faxPrint} testId="care_needs_remarks">
          {patient.care_needs.remarks}
        </BodyWithBreak>
      )}
    </div>
  );
}

export function StaticPatientProfile({
  auction,
  auctionRequest,
  currentLocale,
  extractedPatientFields,
  faxPrint,
  forClinic,
  getOntology,
  locale,
  translations,
}: {
  auction: Auction;
  auctionRequest?: AuctionRequestType;
  currentLocale: DateFnsLocale;
  extractedPatientFields: FieldsHolderType;
  faxPrint?: boolean;
  forClinic?: boolean;
  getOntology: GetOntologyType;
  locale: TranslationsLocale;
  translations: Translations;
}) {
  const isProviderSearchRequest = !!auctionRequest?.is_provider_search_request;

  return (
    <div style={{ margin: margin(0, 4) }}>
      <GeneralInformationContainer
        auction={auction}
        extractedPatientFields={extractedPatientFields}
        faxPrint={faxPrint}
        forClinic={forClinic}
        getOntology={getOntology}
        locale={locale}
        patient={auction.patient}
        request={auctionRequest}
        translations={translations}
      />
      {!isProviderSearchRequest && (
        <>
          <Diagnosis
            auction={auction}
            faxPrint={faxPrint}
            forClinic={forClinic}
            getOntology={getOntology}
            locale={locale}
            patient={auction.patient}
            request={auctionRequest}
            translations={translations}
          />

          <MentalHealth
            faxPrint={faxPrint}
            getOntology={getOntology}
            patient={auction.patient}
            translations={translations}
          />
          <PhysicalHealth
            faxPrint={faxPrint}
            getOntology={getOntology}
            patient={auction.patient}
            translations={translations}
          />
          <MedicalSuppliesContainer
            auction={auction}
            translations={translations}
            faxPrint={faxPrint}
          />
          <TransportSearch
            faxPrint={faxPrint}
            auction={auction}
            translations={translations}
            currentLocale={currentLocale}
          />
        </>
      )}
      <CareNeeds
        faxPrint={faxPrint}
        patient={auction.patient}
        translations={translations}
      />
      <TransportInfo auction={auction} getOntology={getOntology} />
    </div>
  );
}
