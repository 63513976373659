import {
  ActiveProviderSearch,
  ActiveProviderSearchName,
  ActiveSearchMergeStatus,
  AppType,
  AuctionStepperStepNumber,
  CareproviderStatus,
  Payers,
  SearchType,
  SelectedPaymentMethod,
} from "../types";

export const DOCUMENT_TITLE_DEFAULT = "recare";

export const AES_GCM = "AES-GCM";
export const AES_CBC = "AES-CBC";

export const GENERATING_AES_ALGORITHM = AES_CBC;

export const APP_CLINIC = 1 as const;
export const APP_CAREPROVIDER = 2 as const;
export const APP_ACP = 3 as const;
export const APP_B2C = 4 as const;
// export const APP_NATIVE = 5 as const;
export const APP_GATEWAY = 6 as const;
export const APP_BACKEND = 7 as const;
export const APP_THIRD_PARTY = 8 as const;
export const APP_PROVIDER_SEARCH = 9 as const;

export const COUNTRY_DE = "DE" as const;
export const COUNTRY_FR = "FR" as const;

export const APP_KIND = {
  APP_CLINIC,
  APP_CAREPROVIDER,
  APP_ACP,
  APP_B2C,
  APP_PROVIDER_SEARCH,
} as const;

export const APP_VERSION_CLINIC = "clinic";
export const APP_VERSION_CAREPROVIDER = "careprovider";
export const APP_VERSION_ACP = "acp";
export const APP_VERSION_B2C = "b2c";
export const APP_VERSION_GATEWAY = "gateway";
export const APP_VERSION_BACKEND = "backend";
export const APP_VERSION_THIRD_PARTY = "thirdparty";
export const APP_VERSION_PROVIDER_SEARCH = "providersearch";

// Misc
export const DOCUMENT_DATA_PROCESSING_AGREEMENT = "dpa"; // this one is only for RECEIVERS
export const DOCUMENT_AV = "av_contract"; // this one is only for SENDERS, but the French AV is the DPA Document (without Addendum)
export const DOCUMENT_PRIVACY_POLICY = "privacy_policy";
export const DOCUMENT_TERMS_AND_CONDITIONS = "terms_and_conditions";
export const DOCUMENT_ADDENDUM = "addendum";
export const DOCUMENT_DISABILITY_STATEMENT_PROVIDER_SEARCH =
  "disability_statement";
export const DOCUMENT_IMPRINT = "imprint";
export const DOCUMENT_EASY_LANGUAGE = "easy_language";
export const DOCUMENT_SIGN_LANGUAGE = "sign_language";

export const LEGAL_DOCUMENTS = [
  DOCUMENT_DATA_PROCESSING_AGREEMENT,
  DOCUMENT_PRIVACY_POLICY,
  DOCUMENT_TERMS_AND_CONDITIONS,
  DOCUMENT_AV,
  DOCUMENT_ADDENDUM,
  DOCUMENT_DISABILITY_STATEMENT_PROVIDER_SEARCH,
  DOCUMENT_IMPRINT,
  DOCUMENT_EASY_LANGUAGE,
  DOCUMENT_SIGN_LANGUAGE,
] as const;
// End of legal documents

export const PROVIDER_SEARCH_WESER_EMS = 1;
export const PROVIDER_SEARCH_BAVARIA = 2;
export const PROVIDER_SEARCHES = [
  PROVIDER_SEARCH_WESER_EMS,
  PROVIDER_SEARCH_BAVARIA,
] as const;

export const ACTIVE_PROVIDER_SEARCHES = [PROVIDER_SEARCH_BAVARIA] as const;

export const PROVIDER_SEARCH_VERSION_WESER_EMS = "weserems";
export const PROVIDER_SEARCH_VERSION_BAVARIA = "bavaria";
export const PROVIDER_SEARCH_VERSIONS = [
  PROVIDER_SEARCH_VERSION_BAVARIA,
  PROVIDER_SEARCH_VERSION_WESER_EMS,
] as const;

export const ACTIVE_PROVIDER_SEARCH_VERSIONS = [
  PROVIDER_SEARCH_VERSION_BAVARIA,
] as const;

export const PROVIDER_SEARCH_VERSION_MAP: Record<
  ActiveProviderSearchName,
  ActiveProviderSearch
> = {
  [PROVIDER_SEARCH_VERSION_BAVARIA]: PROVIDER_SEARCH_BAVARIA,
};

export const PROVIDER_SEARCH_STATE_MAP: Record<
  ActiveProviderSearch,
  ActiveProviderSearchName
> = {
  [PROVIDER_SEARCH_BAVARIA]: PROVIDER_SEARCH_VERSION_BAVARIA,
};

export const FEDERAL_STATES = {
  BAYERN: "Bayern",
  NIEDERSACHSEN: "Niedersachsen",
} as const;

// strings used in the config db
export const APP_VERSION_NAMES = {
  [APP_CLINIC]: APP_VERSION_CLINIC,
  [APP_B2C]: APP_VERSION_B2C,
  [APP_CAREPROVIDER]: APP_VERSION_CAREPROVIDER,
  [APP_ACP]: APP_VERSION_ACP,
  [APP_GATEWAY]: APP_VERSION_GATEWAY,
  [APP_PROVIDER_SEARCH]: APP_VERSION_PROVIDER_SEARCH,
} as const;

export const QUERY_PROGRESS_NOT_STARTED = "not started";
export const QUERY_PROGRESS_PENDING = "pending";
export const QUERY_PROGRESS_SUCCEED = "succeed";
export const QUERY_PROGRESS_FAILED = "failed";

export const QUERY_PROGRESS_TYPES = [
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  QUERY_PROGRESS_FAILED,
] as const;

export const RESPONSIVE_BREAKPOINT_MOBILE = 600;
export const RESPONSIVE_BREAKPOINT_TABLET = 1024;
export const RESPONSIVE_BREAKPOINT_XL_DESKTOP = 1536;

export const CAREPROVIDER_ACTION_RESEND_ONBOARDING_EMAIL = 1;

export const AUCTION_STATUS_NOT_STARTED = 0;
export const AUCTION_STATUS_IN_PROGRESS = 1;
export const AUCTION_STATUS_SUCCESS = 2;
export const AUCTION_STATUS_CANCELLED = 3;
// export const AUCTION_STATUS_DISCHARGED = 4;
export const AUCTION_STATUS_MANUALLY_RUNNING = 6;
export const AUCTION_STATUS_PAUSED = 7; // deprecated, but needed for showing the event in the messenger
// deprecated
// export const AUCTION_STATUS_TO_BE_STARTED = 8;
// export const AUCTION_STATUS_WAITING_FOR_ELIGIBILITY = 9;
export const AUCTION_STATUS_ACCEPTED = 10; //for FR only

export const COMPANY_TYPE_FACILITY = 1;
export const COMPANY_TYPE_ORGANIZATION = 2;

export const COMPANY_TYPES = {
  COMPANY_TYPE_FACILITY,
  COMPANY_TYPE_ORGANIZATION,
} as const;

export const AUCTION_RESPONSE_STATUS_ACCEPTED = 1;
export const AUCTION_RESPONSE_STATUS_DECLINED = 2;
// export const AUCTION_RESPONSE_STATUS_TENTATIVE = 3;

export const AUCTION_VALIDATION_STATUS_VALIDATED = 1;
export const AUCTION_VALIDATION_STATUS_DECLINED = 2;
// export const AUCTION_VALIDATION_STATUS_MORE_INFORMATION = 3;
// export const AUCTION_VALIDATION_STATUS_ATTACHMENT = 4;

export const AUCTION_REQUEST_DECLINED_REASON_OTHER = 1;
export const AUCTION_REQUEST_DECLINED_REASON_NO_CAPACITY = 2;
export const AUCTION_REQUEST_DECLINED_REASON_SERVICE_UNAVAILABLE = 3;
export const AUCTION_REQUEST_DECLINED_REASON_UNATTRACTIVE_LOCATION = 4;
export const AUCTION_REQUEST_DECLINED_REASON_NO_CAPACITY_FOR_PATIENT = 5;
export const AUCTION_REQUEST_DECLINED_REASON_PAYER = 6;
export const AUCTION_REQUEST_DECLINED_REASON_NO_CAPACITY_FOR_PATIENT_H2H = 7;

export const AUCTION_REQUEST_SENT = 1;
export const AUCTION_REQUEST_ACCEPTED = 2;
export const AUCTION_REQUEST_DECLINED = 3;
export const AUCTION_REQUEST_ATTRIBUTED = 4;
export const AUCTION_REQUEST_REJECTED = 5;
// deprecated
// export const AUCTION_REQUEST_WAITING_FOR_CLINIC_ANSWER = 6;
// deprecated
// export const AUCTION_REQUEST_WAITING_FOR_PROVIDER_ANSWER = 7;
export const AUCTION_REQUEST_UNAVAILABLE = 8;
export const AUCTION_REQUEST_CREATED = 9;
// export const AUCTION_REQUEST_TO_BE_SENT = 11;
// deprecated
// export const AUCTION_REQUEST_WAITING_FOR_ELIGIBILITY = 12;
// export const AUCTION_REQUEST_DISMISSED = 13;
// export const AUCTION_RECOMMENDATION = 20;

// export const COST_APPROVAL_PENDING = 1;
// export const COST_APPROVAL_GRANTED = 2;
// export const COST_APPROVAL_REJECTED = 3;

export const AUCTION_STOPPED_PROVIDER_FOUND = 1 as const; // Frontend only
// export const AUCTION_STOPPED_OTHER = 2  as const; // Deprecated
export const AUCTION_STOPPED_PAUSE_OTHER = 3 as const;
// export const AUCTION_STOPPED_PAUSE_IMPORTANT = 4 as const; // Deprecated
// export const AUCTION_STOPPED_RESET = 5 as const; // Deprecated
export const DEPRECATED_AUCTION_STOPPED_PROVIDER_NOT_NEEDED = 6 as const; // Deprecated
export const AUCTION_STOPPED_PATIENT_TRANSFERED = 7 as const;
export const AUCTION_STOPPED_PATIENT_DIED = 8 as const;
export const AUCTION_STOPPED_DISCHARGED_WITHOUT_PROVIDER = 9 as const;
export const AUCTION_STOP_AUTOMATICALLY_ARCHIVED = 10 as const;
export const AUCTION_STOP_COSTCOVERAGE_NOT_POSSIBLE = 11 as const;
// export const AUCTION_STOP_NOSUITABLE_CLINIC_FOUND = 12 as const;
export const AUCTION_STOP_DISCHARGE_DELAYED = 13 as const;
export const DEPRECATED_AUCTION_STOP_PROVIDER_NOT_NEEDED_OTHER = 14 as const;
export const AUCTION_STOP_DEPRECATE_PAUSE = 15 as const;
export const AUCTION_STOPPED_SUNSET_COSTPAYER_FLOW = 16 as const;
export const AUCTION_DRAFT_MOVED_TO_STOPPED_COSTPAYER_FLOW = 17 as const;
export const AUCTION_PAUSE_MOVED_TO_STOPPED_SUNSET_COSTPAYER_FLOW = 18 as const;

export const STOP_AUCTION_TRANSFER_WITHIN_HOSPITAL = 101 as const;

export const AUCTION_STOPPED_REASONS = [
  AUCTION_STOPPED_PROVIDER_FOUND,
  AUCTION_STOPPED_PAUSE_OTHER,
  AUCTION_STOPPED_PATIENT_TRANSFERED,
  AUCTION_STOPPED_PATIENT_DIED,
  AUCTION_STOPPED_DISCHARGED_WITHOUT_PROVIDER,
  AUCTION_STOP_AUTOMATICALLY_ARCHIVED,
  AUCTION_STOP_COSTCOVERAGE_NOT_POSSIBLE,
  AUCTION_STOP_DISCHARGE_DELAYED,
  DEPRECATED_AUCTION_STOP_PROVIDER_NOT_NEEDED_OTHER,
  AUCTION_STOP_DEPRECATE_PAUSE,
  AUCTION_STOPPED_SUNSET_COSTPAYER_FLOW,
  AUCTION_DRAFT_MOVED_TO_STOPPED_COSTPAYER_FLOW,
  AUCTION_PAUSE_MOVED_TO_STOPPED_SUNSET_COSTPAYER_FLOW,
] as const;

export const SEARCH_PANEL_CANCEL_SEARCH_AND_STOP = 107;
export const SEARCH_PANEL_CANCEL_SEARCH_AND_SUGGEST_PROVIDER = 110;
export const SEARCH_PANEL_CANCEL_VALIDATION = 112;
export const SEARCH_PANEL_CANCEL_SEARCH_AND_STOP_INFORMATION = 114;

export const SEARCH_MERGE_STOP_SEARCH_PANELS = [
  SEARCH_PANEL_CANCEL_SEARCH_AND_SUGGEST_PROVIDER,
  SEARCH_PANEL_CANCEL_SEARCH_AND_STOP,
  SEARCH_PANEL_CANCEL_SEARCH_AND_STOP_INFORMATION,
  SEARCH_PANEL_CANCEL_VALIDATION,
] as const;

export const CAREPROVIDER_CREATED = 0 as const;
export const CAREPROVIDER_TEST_SENT_DEPRECATED = 1 as const;
export const CAREPROVIDER_ACTIVE = 2 as const;
export const CAREPROVIDER_INACTIVE = 3 as const;
export const CAREPROVIDER_INVALID = 4 as const;
export const CAREPROVIDER_DUPLICATE = 5 as const;
export const CAREPROVIDER_PROFILE_FILLED_DEPRECATED = 6 as const;
export const CAREPROVIDER_TEST_NOT_ANSWERED_DEPRECATED = 7 as const;
export const CAREPROVIDER_PENDING_ACCOUNT_ACTIVATION = 8 as const;
export const CAREPROVIDER_TRASH = 9 as const;

export const CAREPROVIDER_STATUSES = [
  CAREPROVIDER_CREATED,
  CAREPROVIDER_TEST_SENT_DEPRECATED,
  CAREPROVIDER_ACTIVE,
  CAREPROVIDER_INACTIVE,
  CAREPROVIDER_INVALID,
  CAREPROVIDER_DUPLICATE,
  CAREPROVIDER_PROFILE_FILLED_DEPRECATED,
  CAREPROVIDER_TEST_NOT_ANSWERED_DEPRECATED,
  CAREPROVIDER_PENDING_ACCOUNT_ACTIVATION,
  CAREPROVIDER_TRASH,
] as const;

export const CAREPROVIDER_DASHBOARD_TAB = {
  DEFAULT: 0,
  NEW: 0,
  DIRECT: 1,
  IN_PROGRESS: 2,
  ATTRIBUTED: 3,
  OTHER: 4,
};

export const CAREPROVIDER_TAB_TYPE_NEW = "new" as const;
export const CAREPROVIDER_TAB_TYPE_DIRECT = "direct" as const;
export const CAREPROVIDER_TAB_TYPE_IN_PROGRESS = "in_progress" as const;
export const CAREPROVIDER_TAB_TYPE_ATTRIBUTED = "attributed" as const;
export const CAREPROVIDER_TAB_TYPE_OTHER = "other" as const;
export const CAREPROVIDER_TAB_DEFAULT = CAREPROVIDER_TAB_TYPE_NEW;

export const CARESEEKER_TAB_TYPE_DRAFT = "draft" as const;
export const CARESEEKER_TAB_TYPE_PATIENTS = "active" as const;
export const CARESEEKER_TAB_TYPE_FINISHED = "finished" as const;
export const CARESEEKER_TAB_TYPE_ARCHIVED = "archived" as const;

export const CLINIC_TODO_DASHBOARD_TAB = {
  DRAFT: 0,
  PATIENTS: 1,
  FINISHED: 2,
  ARCHIVED: 3,
} as const;

export const COMPANY_STATUS_INACTIVE = 2;
export const COMPANY_STATUS_DUPLICATE = 3;
export const COMPANY_STATUS_CREATED = 6;

export const COMPANY_STATUSES = {
  COMPANY_STATUS_INACTIVE,
  COMPANY_STATUS_DUPLICATE,
  COMPANY_STATUS_CREATED,
} as const;

export const ONBOARDING_SELF_ONBOARDING = 1;
export const ONBOARDING_TO_CALL = 2;

export const ONBOARDING_CHAIN = 10;
export const ONBOARDING_PREONBOARDING = 12;
export const ONBOARDING_MISSING_INFORMATION = 13;
export const ONBOARDING_INFO_PHASE = 18;
export const ONBOARDING_CONSIDERATION_PHASE = 19;
export const ONBOARDING_ADMINISTRATION_PHASE = 20;
export const ONBOARDING_MANUALLY_ADDED_BY_SENDER = 21;

export const ONBOARDING_NOT_REACHED_DEPRECATED = 3;
export const ONBOARDING_TO_CALL_BACK_DEPRECATED = 4;
export const ONBOARDING_PROFILE_FILLED_DEPRECATED = 5;
export const ONBOARDING_RESEND_ONBOARDING_EMAIL_DEPRECATED = 6;
export const ONBOARDING_IT_ISSUES_DEPRECATED = 7;
export const ONBOARDING_COMPLICATED_DEPRECATED = 8;
export const ONBOARDING_OTHER_DEPRECATED = 9;
export const ONBOARDING_NOT_REACHED_CALLCENTER_DEPRECATED = 11;
export const ONBOARDING_LATE_DEPRECATED = 14;
export const ONBOARDING_NO_INTEREST_DEPRECATED = 15;
export const ONBOARDING_NO_COMMENT_DEPRECATED = 16;
export const ONBOARDING_NO_ACTION_FROM_CALLCENTER_DEPRECATED = 17;

export const ONBOARDING_PAGE_WELCOME = "welcome";
export const ONBOARDING_PAGE_COMPANY = "company";
export const ONBOARDING_PAGE_SERVICES = "services";
export const ONBOARDING_PAGE_ADDITIONAL_INFORMATION = "additionalinformation";
export const ONBOARDING_PAGE_ACCOUNT = "account";
export const ONBOARDING_PAGE_PASSWORD = "password";
export const ONBOARDING_PAGE_CONGRATULATIONS = "congratulations";
export const ONBOARDING_PAGE_YOUR_TEAM = "yourteam";
export const ONBOARDING_PAGE_YOUR_COMPANY = "yourcompany";
export const ONBOARDING_PAGE_PASSWORD_RESET = "passwordreset";

export const CARE_ONBOARDING = [
  ONBOARDING_SELF_ONBOARDING,
  ONBOARDING_TO_CALL,
  ONBOARDING_CHAIN,
  ONBOARDING_PREONBOARDING,
  ONBOARDING_MISSING_INFORMATION,
  ONBOARDING_MANUALLY_ADDED_BY_SENDER,
];

export const HOSPITAL_ONBOARDING = [
  ONBOARDING_CHAIN,
  ONBOARDING_MISSING_INFORMATION,
  ONBOARDING_PREONBOARDING,
  ONBOARDING_INFO_PHASE,
  ONBOARDING_CONSIDERATION_PHASE,
  ONBOARDING_ADMINISTRATION_PHASE,
  ONBOARDING_MANUALLY_ADDED_BY_SENDER,
];

export const GRANTS_MONEY_FROM_INSURANCE = 1;
export const GRANTS_MONEY_FROM_STATE = 2;
export const GRANTS_COMBINATION_OF_BOTH = 3;

export const PREDICAMENT_STATE_NOT_APPLICABLE = 0;
export const PREDICAMENT_STATE_YES = 1;
export const PREDICAMENT_STATE_NO = 2;
export const PREDICAMENT_STATE_UNKNOWN = 3;

export const PREDICAMENT_STATES = [
  PREDICAMENT_STATE_NOT_APPLICABLE,
  PREDICAMENT_STATE_YES,
  PREDICAMENT_STATE_NO,
  PREDICAMENT_STATE_UNKNOWN,
] as const;

export const SOLUTION_MOBILE_CARE = 1;
export const SOLUTION_FULL_TIME_MOBILE_CARE = 2;
export const SOLUTION_DAY_CARE = 3;
export const SOLUTION_SHORT_TERM_STATIC_CARE = 4;
export const SOLUTION_STATIC_CARE = 5;
export const SOLUTION_DAY_HOSPITALIZATION = 6;
export const SOLUTION_FULL_HOSPITALIZATION = 7;
export const SOLUTION_HELP_AT_HOME = 8;
export const SOLUTION_REHAB_FULL_HOSPITALIZATION = 9;
export const SOLUTION_REHAB_DAY_HOSPITALIZATION = 10;
export const SOLUTION_REHAB_DAY_HOSPITALIZATION_WITH_TRANSPORT_DEPRECATED = 11;
export const SOLUTION_REHAB_MOBILE = 12;
export const SOLUTION_SHARED_FLAT = 13;

export const SOLUTION_TAXI = 14;
export const SOLUTION_RENTAL_CAR_WHEELCHAIR = 15;
export const SOLUTION_RENTAL_CAR_CARRY_CHAIR = 16;
export const SOLUTION_RENTAL_CAR_LYING_DOWN = 17;
export const SOLUTION_KTW_WITH_MEDICAL_CARE = 18;

export const SOLUTION_MEDICAL_SUPPLIES = 19;
export const SOLUTION_HOME_CARE = 20;

export const SOLUTION_KTW_WHEELCHAIR = 21;
export const SOLUTION_KTW_CARRY_CHAIR = 22;
export const SOLUTION_KTW_LYING_DOWN = 23;

export const SOLUTION_HOSPICE_ASSOCIATION = 27;
export const SOLUTION_HOSPICE_STATIC = 28;
export const SOLUTION_HOSPICE_PARTIALLY_MOBILE = 29;
export const SOLUTION_HOSPICE_MOBILE = 30;
export const SOLUTION_NIGHT_CARE_STATIC = 31;
export const SOLUTION_NIGHT_CARE_MOBILE = 32;
export const SOLUTION_ASSISTED_LIVING = 33;
export const SOLUTION_DAILY_LIVING_SUPPORT_SERVICES = 34;

export const MOBILE_SOLUTIONS = [
  SOLUTION_MOBILE_CARE,
  SOLUTION_FULL_TIME_MOBILE_CARE,
  SOLUTION_REHAB_MOBILE,
  SOLUTION_HELP_AT_HOME,
];

export const TRANSPORT_SOLUTIONS = [
  SOLUTION_TAXI,
  SOLUTION_RENTAL_CAR_WHEELCHAIR,
  SOLUTION_RENTAL_CAR_CARRY_CHAIR,
  SOLUTION_RENTAL_CAR_LYING_DOWN,
  SOLUTION_KTW_WITH_MEDICAL_CARE,
  SOLUTION_KTW_WHEELCHAIR,
  SOLUTION_KTW_CARRY_CHAIR,
  SOLUTION_KTW_LYING_DOWN,
];

export const MEDICAL_SUPPLIES_SOLUTIONS = [SOLUTION_MEDICAL_SUPPLIES];
export const HOME_CARE_SOLUTIONS = [SOLUTION_HOME_CARE];

export const FORM_CONFIGURATION_REVISIONS = {
  HEAD: 1,
  ACTIVE: 2,
};

export const SPECIALIZATION_ANGIOLOGY = 1;
export const SPECIALIZATION_DERMATOLOGY = 2;
export const SPECIALIZATION_GASTROENTEROLOGY = 3;
export const SPECIALIZATION_GERIATRICS = 4;
export const SPECIALIZATION_INTERNAL_MEDICINE = 5;
export const SPECIALIZATION_CARDIOLOGY = 6;
export const SPECIALIZATION_PARENTS_AND_CHILD_REHAB = 7;
export const SPECIALIZATION_NEUROLOGY = 8;
export const SPECIALIZATION_ONCOLOGY = 9;
export const SPECIALIZATION_ORTHOPEDICS = 10;
export const SPECIALIZATION_PEDIATRICS = 11;
export const SPECIALIZATION_PHASEB = 12;
export const SPECIALIZATION_PHASEC = 13;
export const SPECIALIZATION_PHASED = 14;
export const SPECIALIZATION_PNEUMOLOGY = 15;
export const SPECIALIZATION_PSYCHOSOMATIC = 16;
export const SPECIALIZATION_RHEUMATISM = 17;
export const SPECIALIZATION_ADDICTION = 18;
export const SPECIALIZATION_UROLOGY = 19;
export const SPECIALIZATION_ACUTE_GERIATRICS = 20;
// export const SPECIALIZATION_EARLY_REHAB = 21;
export const SPECIALIZATION_NEUROPEDIATRIY = 22;
export const SPECIALIZATION_PHASECPLUS = 23;
export const SPECIALIZATION_ONCOLOGY_RESPIRATORY_ORGANS = 24;
export const SPECIALIZATION_ONCOLOGY_LOCOMOTOR_ORGANS = 25;
export const SPECIALIZATION_ONCOLOGY_BREAST_GLANDS = 26;
export const SPECIALIZATION_ONCOLOGY_SKIN = 27;
export const SPECIALIZATION_ONCOLOGY_MALIGNANT_SYSTEMIC_DISEASES = 28;
export const SPECIALIZATION_ONCOLOGY_KIDNEY_URINARY_MALE_GENITAL = 29;
export const SPECIALIZATION_ONCOLOGY_PHARYNX_LARYNX = 30;
export const SPECIALIZATION_ONCOLOGY_THYROID = 31;
export const SPECIALIZATION_ONCOLOGY_DIGESTIVE_ORGANS = 32;
export const SPECIALIZATION_ONCOLOGY_FEMALE_GENITAL = 33;
export const SPECIALIZATION_ONCOLOGY_CENTRAL_NERVOUS_SYSTEM = 34;
export const SPECIALIZATION_ENDOCRINOLOGY = 35;
export const SPECIALIZATION_NEPHROLOGY = 36;
export const SPECIALIZATION_GYNAECOLOGY = 37;
export const SPECIALIZATION_ICU_LOW_CORONA = 38;
export const SPECIALIZATION_ICU_HIGH_CORONA = 39;
export const SPECIALIZATION_ICU_ECMO_CORONA = 40;
export const SPECIALIZATION_NORMAL_STATION = 41;
export const SPECIALIZATION_INFECTIOUS_DISEASE = 42;
// DUPLICATED with 51  export const SPECIALIZATION_COGNITIVE_AND_BEHAVIOURAL = 43;
export const SPECIALIZATION_RADIOLOGY = 44;
export const SPECIALIZATION_TRAUMA_SURGERY = 45;
export const SPECIALIZATION_GENERAL_SURGERY = 46;
export const SPECIALIZATION_ANESTHESIOLOGY = 47;
export const SPECIALIZATION_ICU_NEUROVASCULAR = 48;
export const SPECIALIZATION_ICU_CARDIOLOGY = 49;
export const SPECIALIZATION_ICU_GASTROLOGY = 50;
export const SPECIALIZATION_COGNITIVE_BEHAVIORAL_UNIT = 51;
export const SPECIALIZATION_PALLIATIVE_CARE = 52;
export const SPECIALIZATION_OPHTHALMOLOGY = 53;
export const SPECIALIZATION_PSYCHIATRY = 54;
export const SPECIALIZATION_DIABETIC_FOOT = 55;
export const SPECIALIZATION_SURGICAL_RESUSCITATION = 56;
export const SPECIALIZATION_MEDICAL_REANIMATION = 57;
export const SPECIALIZATION_ENT = 58;
export const SPECIALIZATION_NEUROSURGERY = 59;
export const SPECIALIZATION_HAEMATOLOGY = 60;
export const SPECIALIZATION_DIABETOLOGY = 61;
export const SPECIALIZATION_VASCULAR_SURGERY = 62;
export const SPECIALIZATION_THORACIC_SURGERY = 63;
export const SPECIALIZATION_DIGESTIVE_SURGERY = 64;

export const SPECIALIZATION_ALLERGOLOGY = 65;
export const SPECIALIZATION_HEPATOLOGY = 66;
export const SPECIALIZATION_PHYSICAL_MEDICINE = 67;
export const SPECIALIZATION_PLASTIC_SURGERY = 68;
export const SPECIALIZATION_MAXILLOFACIAL_SURGERY = 69;
export const SPECIALIZATION_ADDICTOLOGY = 70;
export const SPECIALIZATION_ORTHOGERIATRICS = 71;

export const HOME_CARE_SPECIALIZATION_TRACHEOSTOMY_THERAPY = 72;
export const HOME_CARE_SPECIALIZATION_WOUND_CARE = 73;
export const HOME_CARE_SPECIALIZATION_PARENTERAL_NUTRITION = 74;
export const HOME_CARE_SPECIALIZATION_ENTERAL_NUTRITION = 75;
export const HOME_CARE_SPECIALIZATION_RESPIRATORY_THERAPY = 76;
export const HOME_CARE_SPECIALIZATION_VENTILATION = 77;
export const HOME_CARE_SPECIALIZATION_OXYGEN = 78;
export const HOME_CARE_SPECIALIZATION_INCONTINENCE = 79;
export const HOME_CARE_SPECIALIZATION_INCONTINENCE_DISCHARGING = 80;
export const HOME_CARE_SPECIALIZATION_MONITORING = 81;
export const HOME_CARE_SPECIALIZATION_PAIN_THERAPY = 82;
export const HOME_CARE_SPECIALIZATION_INFUSION_THERAPY = 83;
export const HOME_CARE_SPECIALIZATION_DECUBITUS_PROPHYLAXIS = 84;
export const HOME_CARE_SPECIALIZATION_COMPRESSION_THERAPY = 85;
export const HOME_CARE_SPECIALIZATION_STOMA = 86;

export const SPECIALIZATION_WEANING = 87;
export const SPECIALIZATION_CARDIOLOGICAL_EARLY_REHA = 88;
export const SPECIALIZATION_INTERNAL_MEDICAL_EARLY_REHA = 89;
export const SPECIALIZATION_PNEUMOLOGICAL_EARLY_REHA = 90;
export const SPECIALIZATION_OBESITY = 91;

export const SPECIALIZATION_ORTHOPEDICS_UPPER_LIMBS = 92;
export const SPECIALIZATION_ORTHOPEDICS_LOWER_LIMBS = 93;
export const SPECIALIZATION_ORTHOPEDICS_SPINE = 94;
export const SPECIALIZATION_ORTHOPEDICS_SOS_MAINS = 95;

export const TRANSPORT_TYPE_AMBULANCE_LYING_DOWN = 1;
export const TRANSPORT_TYPE_AMBULANCE_SITTING = 2;
export const TRANSPORT_TYPE_PUBLIC_TRANSPORT = 3;
export const TRANSPORT_TYPE_TAXI = 4;
export const TRANSPORT_TYPE_PERSONAL_CAR = 5;
export const TRANSPORT_TYPE_EMERGENCY_VEHICLE = 6;
export const TRANSPORT_TYPE_HELICOPTER = 7;

export const TRANSPORT_TYPE_ASSESSMENT = [
  TRANSPORT_TYPE_AMBULANCE_LYING_DOWN,
  TRANSPORT_TYPE_AMBULANCE_SITTING,
  TRANSPORT_TYPE_PUBLIC_TRANSPORT,
  TRANSPORT_TYPE_TAXI,
  TRANSPORT_TYPE_PERSONAL_CAR,
  TRANSPORT_TYPE_EMERGENCY_VEHICLE,
  TRANSPORT_TYPE_HELICOPTER,
] as const;

export const MARITAL_STATUS_SINGLE = 1;
export const MARITAL_STATUS_MARRIED = 2;
export const MARITAL_STATUS_DIVORCED = 3;
export const MARITAL_STATUS_WIDOWED = 4;

export const MARITAL_STATUS = [
  MARITAL_STATUS_SINGLE,
  MARITAL_STATUS_MARRIED,
  MARITAL_STATUS_DIVORCED,
  MARITAL_STATUS_WIDOWED,
] as const;

export const EMPLOYMENT_STATUS_NOT_EMPLOYED = 1;
export const EMPLOYMENT_STATUS_TRAINEE = 2;
export const EMPLOYMENT_STATUS_UNSKILLED_EMPLOYEE = 3;
export const EMPLOYMENT_STATUS_SEMI_SKILLED_WORKER = 4;
export const EMPLOYMENT_STATUS_SKILLED_WORKER = 5;
export const EMPLOYMENT_STATUS_MASTER_FOREMAN = 6;
export const EMPLOYMENT_STATUS_EMPLOYEE = 7;
export const EMPLOYMENT_STATUS_CIVIL_SERVANT = 8;
export const EMPLOYMENT_STATUS_SELF_EMPLOYED = 9;

export const PRIOR_EMPLOYMENT_NOT_WORKING = 1;
export const PRIOR_EMPLOYMENT_FULL_TIME_NO_ROTATING_SHIFT = 2;
export const PRIOR_EMPLOYMENT_FULL_TIME_ROTATING_SHIFT = 3;
export const PRIOR_EMPLOYMENT_WITH_NIGHT_SHIFTS = 4;
export const PRIOR_EMPLOYMENT_PART_TIME_LESS_THAN_HALF_DAY = 5;
export const PRIOR_EMPLOYMENT_PART_TIME_MORE_THAN_HALF_DAY = 6;
export const PRIOR_EMPLOYMENT_HOUSEHOLD_CARE = 7;
export const PRIOR_EMPLOYMENT_UNEMPLOYED_REGISTERED = 8;
export const PRIOR_EMPLOYMENT_WORK_AT_HOME = 9;
export const PRIOR_EMPLOYMENT_WORKSHOP_FOR_DISABLED_PEOPLE = 10;

export const LANGUAGE_BULGARIAN = 1;
export const LANGUAGE_CZECH = 2;
export const LANGUAGE_CROATIAN = 3;
export const LANGUAGE_DANISH = 4;
export const LANGUAGE_DUTCH = 5;
export const LANGUAGE_ENGLISH = 6;
// export const LANGUAGE_ESTONIAN = 7;
export const LANGUAGE_FINNISH = 8;
export const LANGUAGE_FRENCH = 9;
export const LANGUAGE_GERMAN = 10;
export const LANGUAGE_GREEK = 11;
export const LANGUAGE_HUNGARIAN = 12;
export const LANGUAGE_ITALIAN = 13;
// export const LANGUAGE_LATVIAN = 14;
export const LANGUAGE_LITHUNIAN = 15;
// export const LANGUAGE_MALTESE = 16;
export const LANGUAGE_POLISH = 17;
export const LANGUAGE_PORTUGUESE = 18;
export const LANGUAGE_ROMANIAN = 19;
// export const LANGUAGE_SLOVAK = 20;
// export const LANGUAGE_SLOVENE = 21;
export const LANGUAGE_SPANISH = 22;
export const LANGUAGE_SWEDISH = 23;
export const LANGUAGE_RUSSIAN = 24;
export const LANGUAGE_AFGHAN = 25;
export const LANGUAGE_ALBANIAN = 26;
export const LANGUAGE_AMHARIC = 27;
export const LANGUAGE_ARABIC = 28;
export const LANGUAGE_AZERBAIJANI = 29;
export const LANGUAGE_BENGAL = 30;
export const LANGUAGE_BOSNIAN = 31;
export const LANGUAGE_CANTONESE = 32;
export const LANGUAGE_GEORGIAN = 33;
export const LANGUAGE_HEBREW = 34;
export const LANGUAGE_HINDI = 35;
export const LANGUAGE_INDONESIAN = 36;
export const LANGUAGE_JAPANESE = 37;
export const LANGUAGE_KOREAN = 38;
export const LANGUAGE_KURDISH = 39;
export const LANGUAGE_MANDARIN = 40;
export const LANGUAGE_NORWEGIAN = 41;
export const LANGUAGE_PERSIAN = 42;
export const LANGUAGE_SERBIAN = 43;
export const LANGUAGE_SIGN = 44;
export const LANGUAGE_SWAHILI = 45;
export const LANGUAGE_THAI = 46;
export const LANGUAGE_TIGRINYA = 47;
export const LANGUAGE_TURKISH = 48;
export const LANGUAGE_URKRAINIAN = 49;
export const LANGUAGE_URDU = 50;
export const LANGUAGE_VIETNAMESE = 51;

export const ROOM_TYPE_SINGLE_STANDARD = 1;
export const ROOM_TYPE_DOUBLE_ROOM = 2;
export const ROOM_TYPE_APPARTMENT = 3;
export const ROOM_TYPE_NO_PREFERENCE = 4;
export const ROOM_TYPE_BARRIER_FREE = 5;
export const ROOM_TYPE_ACCOMPANYING_PERSON = 6;
export const ROOM_TYPE_ACCOMPANYING_CHILD = 7;
export const ROOM_TYPE_ACCOMPANYING_SIBLING = 8;

export const GENDER_MALE = 1;
export const GENDER_FEMALE = 2;
export const GENDER_OTHER = 3;
export const GENDER_NO_ENTRY = 4;

export const GENDERS = [GENDER_MALE, GENDER_FEMALE, GENDER_OTHER] as const;
export const GENDERS_EXTENDED = [
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_OTHER,
  GENDER_NO_ENTRY,
] as const;

export const ACCOUNT_TYPE_TOOL = 1;
export const ACCOUNT_TYPE_CAREPROVIDER_DEPRECATED = 2;
export const ACCOUNT_TYPE_CALLCENTER_DEPRECATED = 3;
export const ACCOUNT_TYPE_STAFF = 4;
export const ACCOUNT_TYPE_CLINIC_DEPRECATED = 5;
export const ACCOUNT_TYPE_API = 6;
export const ACCOUNT_TYPE_EXTERNAL = 7;
export const ACCOUNT_TYPE_PROVIDER_SEARCH = 8;

export const ACCOUNT_TYPE = {
  ACCOUNT_TYPE_TOOL,
  ACCOUNT_TYPE_CAREPROVIDER_DEPRECATED,
  ACCOUNT_TYPE_CALLCENTER_DEPRECATED,
  ACCOUNT_TYPE_STAFF,
  ACCOUNT_TYPE_CLINIC_DEPRECATED,
  ACCOUNT_TYPE_API,
  ACCOUNT_TYPE_EXTERNAL,
  ACCOUNT_TYPE_PROVIDER_SEARCH,
} as const;

export const DEPRECATED_ACCOUNT_TYPES = [ACCOUNT_TYPE_CALLCENTER_DEPRECATED];

export const externalUser = (accountType: number | undefined) =>
  (accountType &&
    [
      ACCOUNT_TYPE_CAREPROVIDER_DEPRECATED,
      ACCOUNT_TYPE_CLINIC_DEPRECATED,
      ACCOUNT_TYPE_API,
      ACCOUNT_TYPE_EXTERNAL,
      ACCOUNT_TYPE_PROVIDER_SEARCH,
    ].includes(accountType)) ||
  false;

export const ORIGIN_TOOLS = 1 as const;
export const ORIGIN_RECEIVER = 2 as const;
export const ORIGIN_SENDER = 5 as const;
// export const ORIGIN_COST_PAYER = 6 as const;

export const ORIGIN = {
  ORIGIN_TOOLS,
  ORIGIN_RECEIVER,
  ORIGIN_SENDER,
};

export const ACCOUNT_STATUS_ACTIVE = 1;
export const ACCOUNT_STATUS_INACTIVE = 2;
export const ACCOUNT_STATUS_CREATED = 3;

export const ACCOUNT_ROLE_NO_ROLE = 1 as const;
export const ACCOUNT_ROLE_CARESEEKER_CARE = 2 as const;
export const ACCOUNT_ROLE_ADMIN = 3 as const;
export const ACCOUNT_ROLE_RECEIVER_BASE_ROLE = 4 as const;
export const ACCOUNT_ROLE_CARE = 5 as const; // DEPRECATED by 2
export const ACCOUNT_ROLE_CARESEEKER_HOSPITALIZATION = 6 as const;
// export const ACCOUNT_ROLE_PROJECTMANAGER = 7 as const;
// export const ACCOUNT_ROLE_ACCOUNTMANAGER = 8 as const;
export const ACCOUNT_ROLE_ADMIN_READ_ONLY = 9 as const;
export const ACCOUNT_ROLE_ADMIN_EDIT_CAREPROVIDER = 10 as const;
export const ACCOUNT_ROLE_ADMIN_EDIT_CARESEEKER = 11 as const;
export const ACCOUNT_ROLE_CARESEEKER_REHAB = 12 as const;
// export const ACCOUNT_ROLE_JOUR_FIXE = 13 as const;
// export const ACCOUNT_ROLE_MANAGEMENT = 14 as const;
export const ACCOUNT_ROLE_SENDER_IT = 15 as const;
// export const ACCOUNT_ROLE_SALES = 16 as const;
export const ACCOUNT_ROLE_RECEIVER_CONTACT = 17 as const;
export const ACCOUNT_ROLE_REPORT = 18 as const;
export const ACCOUNT_ROLE_ADMIN_TRANSLATIONS = 19 as const;
export const ACCOUNT_ROLE_SENDER_API_CREATE_PATIENT = 20 as const;
export const ACCOUNT_ROLE_COST_APPROVER = 21 as const;
export const ACCOUNT_ROLE_COMPANY_BASE_ROLE = 22 as const;
export const ACCOUNT_ROLE_TRANSPORT = 23 as const;
export const ACCOUNT_ROLE_MEDICAL_SUPPLIES = 24 as const;
// export const ACCOUNT_ROLE_PROTECTED_PATIENT = 25 as const;
export const ACCOUNT_ROLE_CARESEEKER_USER_MANAGEMENT = 26 as const;
export const ACCOUNT_ROLE_ADMIN_EDIT_CONSULTANT = 27 as const;
export const ACCOUNT_ROLE_CARESEEKER_SSO_USER_MANAGEMENT = 28 as const;

export const ACCOUNT_ROLES = [
  ACCOUNT_ROLE_NO_ROLE,
  ACCOUNT_ROLE_CARESEEKER_CARE,
  ACCOUNT_ROLE_ADMIN,
  ACCOUNT_ROLE_RECEIVER_BASE_ROLE,
  ACCOUNT_ROLE_CARE,
  ACCOUNT_ROLE_CARESEEKER_HOSPITALIZATION,
  ACCOUNT_ROLE_ADMIN_READ_ONLY,
  ACCOUNT_ROLE_ADMIN_EDIT_CAREPROVIDER,
  ACCOUNT_ROLE_ADMIN_EDIT_CARESEEKER,
  ACCOUNT_ROLE_CARESEEKER_REHAB,
  ACCOUNT_ROLE_SENDER_IT,
  ACCOUNT_ROLE_RECEIVER_CONTACT,
  ACCOUNT_ROLE_REPORT,
  ACCOUNT_ROLE_ADMIN_TRANSLATIONS,
  ACCOUNT_ROLE_SENDER_API_CREATE_PATIENT,
  ACCOUNT_ROLE_COST_APPROVER,
  ACCOUNT_ROLE_COMPANY_BASE_ROLE,
  ACCOUNT_ROLE_TRANSPORT,
  ACCOUNT_ROLE_MEDICAL_SUPPLIES,
  ACCOUNT_ROLE_CARESEEKER_USER_MANAGEMENT,
  ACCOUNT_ROLE_ADMIN_EDIT_CONSULTANT,
  ACCOUNT_ROLE_CARESEEKER_SSO_USER_MANAGEMENT,
];

export const CREATE_PATIENT_ROLES = [
  ACCOUNT_ROLE_CARESEEKER_CARE,
  ACCOUNT_ROLE_CARESEEKER_HOSPITALIZATION,
  ACCOUNT_ROLE_CARESEEKER_REHAB,
  ACCOUNT_ROLE_TRANSPORT,
  ACCOUNT_ROLE_MEDICAL_SUPPLIES,
];

export const CARESEEKER_TYPE_HOSPITAL = 1;
// export const CARESEEKER_TYPE_INSURANCE = 2;
export const CARESEEKER_TYPE_EMERGENCY = 3;
export const CARESEEKER_TYPE_PROVIDER_SEARCH = 5;

export const LIVING_SITUATION_ALONE = 1;
export const LIVING_SITUATION_WITH_FAMILY = 2;
export const LIVING_SITUATION_CAREHOME = 4;
export const LIVING_SITUATION_OTHER = 5;
export const LIVING_SITUATION_IN_ELDERLY_HOME = 6;
export const LIVING_SITUATION_SHARED_FLAT = 7;

export const LIVING_SITUATIONS = [
  LIVING_SITUATION_ALONE,
  LIVING_SITUATION_WITH_FAMILY,
  LIVING_SITUATION_CAREHOME,
  LIVING_SITUATION_OTHER,
  LIVING_SITUATION_IN_ELDERLY_HOME,
  LIVING_SITUATION_SHARED_FLAT,
] as const;

export const ORIENTATION_ORIENTATED = 1;
export const ORIENTATION_LIMITED = 2;
export const ORIENTATION_DISORIENTED = 3;

export const MOTIVATION_GOOD = 1;
export const MOTIVATION_MODERATE = 2;
export const MOTIVATION_BAD = 3;

export const COMMUNICATION_GOOD = 1;
export const COMMUNICATION_MODERATE = 2;
export const COMMUNICATION_BAD = 3;

export const MOBILITY_WALK_WITHOUT_HELP = 1;
export const MOBILITY_WALKING_STICK = 2;
export const MOBILITY_WALKING_FRAME = 3;
export const MOBILITY_WHEELCHAIR = 4;
export const MOBILITY_MEDICALISED_BED = 5;
export const MOBILITY_ROLLATOR = 6;
export const MOBILITY_WALK_WITH_HELP = 7;
export const MOBILITY_CRUTCH = 8;

export const SERVICE_COMPANIONSHIP = 1;
export const SERVICE_BASIC_CARE = 2;
export const SERVICE_BASIC_MEDICAL_CARE = 3;
// export const SERVICE_DISABLED = 4;
export const SERVICE_TRACHEOSTOMY = 5;
export const SERVICE_PALLIATIVE_CARE = 7;
// export const SERVICE_MENTALLY_DISABLED = 8;
export const SERVICE_PSYCHIATRIC_CARE = 9;
// export const SERVICE_GERIATRIC_PSYCHIATRY = 10;
export const SERVICE_DEMENTIA = 11;
// export const SERVICE_OBESITY = 12;
export const SERVICE_CLOSED_AREA = 13;
// export const SERVICE_CHILDREN = 14;
// export const SERVICE_PATIENTS_BELOW70 = 15;
// export const SERVICE_PATIENTS_OVER70 = 16;
export const SERVICE_PALLIATIVE_CERTIFICATE = 17;
export const SERVICE_SPECIALISED_INTENSIVE_NURSE = 18;

// Reha services
export const SERVICE_DIALYSIS = 19;
export const SERVICE_PARENTERAL_NUTRITRION = 20;
export const SERVICE_VISUALLY_IMPAIRED = 21;
export const SERVICE_HEARING_IMPAIRED = 22;
export const SERVICE_ENTERALNUTRITION = 24;
export const SERVICE_PROTECTEDAREA = 25;
export const SERVICE_PEOPLEWITHORIENTATIONDISORDER = 26;
export const SERVICE_HELPWITHEATING = 27;
export const SERVICE_PEOPLEWITHADDICTIONS = 28;
export const SERVICE_PEOPLEWHOAREDANGEROUS = 29;
export const SERVICE_TAKINGOVERBODYCARE = 30;
export const SERVICE_MONITORINGCIRCULATION = 31;
export const SERVICE_MONITORINGBREATHING = 32;
export const SERVICE_MONITORINGMETABOLISM = 33;
export const SERVICE_INVASIVEVENTILATION = 34;
export const SERVICE_NONINVASIVEVENTILATION = 35;
export const SERVICE_OXYGENTHERAPY = 36;

export const SERVICE_STATIC_CARE_DISABLED_PATIENTS = 37;

export const FACILITY_MOBILE_CARE = 1;
export const FACILITY_FULLTIME_MOBILE_CARE = 2;
export const FACILITY_COMPANIONSHIP = 3;
export const FACILITY_FULLTIME_COMPANIONSHIP = 4;
export const FACILITY_DAY_CARE = 5;
export const FACILITY_SHORT_TERM_CARE = 6;
export const FACILITY_STATIC_CARE = 7;
export const FACILITY_PURE_INTENSIVE_CARE = 8;
export const FACILITY_DAY_HOSPITALIZATION = 9;
export const FACILITY_FULL_HOSPITALIZATION = 10;
export const FACILITY_SHARED_FLAT = 12;
export const FACILITY_REHAB_FULL_HOSPITALIZATION = 13;
export const FACILITY_REHAB_DAY_HOSPITALIZATION = 14;
// export const DEPRECATED_FACILITY_REHAB_DAY_HOSPITALIZATION_WITH_TRANSPORT = 15;
export const FACILITY_REHAB_MOBILE = 16;
export const FACILITY_TAXI = 17;
export const FACILITY_RENTAL_CAR_WHEELCHAIR = 18;
export const FACILITY_TAXI_CARRY_CHAIR = 19;
export const FACILITY_RENTAL_CAR_LYING_DOWN = 20;
export const FACILITY_KTW_MEDICAL_CARE = 21;

export const FACILITY_MEDICAL_SUPPLIES = 22;
export const FACILITY_HOME_CARE = 23;

export const FACILITY_KTW_WHEELCHAIR = 24;
export const FACILITY_KTW_CARRY_CHAIR = 25;
export const FACILITY_KTW_LYING_DOWN = 26;

export const FACILITY_HOSPICE_ASSOCIATION = 27;
export const FACILITY_HOSPICE_STATIC = 28;
export const FACILITY_HOSPICE_PARTIALLY_MOBILE = 29;
export const FACILITY_HOSPICE_MOBILE = 30;
export const FACILITY_NIGHT_CARE_STATIC = 31;
export const FACILITY_NIGHT_CARE_MOBILE = 32;
export const FACILITY_ASSISTED_LIVING = 33;
export const FACILITY_DAILY_LIVING_SUPPORT_SERVICES = 34;

export const TRANSPORT_FACILITIES = [
  FACILITY_TAXI,
  FACILITY_RENTAL_CAR_WHEELCHAIR,
  FACILITY_TAXI_CARRY_CHAIR,
  FACILITY_RENTAL_CAR_LYING_DOWN,
  FACILITY_KTW_MEDICAL_CARE,
  FACILITY_KTW_WHEELCHAIR,
  FACILITY_KTW_CARRY_CHAIR,
  FACILITY_KTW_LYING_DOWN,
];

export const MEDICAL_SUPPLIES_FACILITIES = [FACILITY_MEDICAL_SUPPLIES];
export const HOME_CARE_FACILITIES = [FACILITY_HOME_CARE];

export const GRANT_STATUS_EXISTS = 1 as const;
export const GRANT_STATUS_APPLIED_FOR = 2 as const;
export const GRANT_STATUS_NOT_APPLIED = 3 as const;

export const GRANT_STATUSES = [
  GRANT_STATUS_EXISTS,
  GRANT_STATUS_APPLIED_FOR,
  GRANT_STATUS_NOT_APPLIED,
] as const;

export const SHORT_TERM_CARE_GRANT_NONE = 1 as const;
export const SHORT_TERM_CARE_GRANT_42 = 42 as const;
export const SHORT_TERM_CARE_GRANT_39 = 39 as const;
export const SHORT_TERM_CARE_GRANT_COMBINATION = 43 as const;

export const SHORT_TERM_GRANT_STATUSES = [
  SHORT_TERM_CARE_GRANT_NONE,
  SHORT_TERM_CARE_GRANT_42,
  SHORT_TERM_CARE_GRANT_39,
  SHORT_TERM_CARE_GRANT_COMBINATION,
] as const;

export const RELIEF_SERVICES_PARAGRAPH_A = 1;
export const RELIEF_SERVICES_PARAGRAPH_B = 2;

export const CARESEEKER_STATUS_CREATED = 0;
export const CARESEEKER_STATUS_ACTIVE = 1;
export const CARESEEKER_STATUS_INACTIVE = 2;
// export const CARESEEKER_STATUS_TEST = 3;
export const CARESEEKER_STATUS_ONBOARDING = 4;
export const CARESEEKER_STATUS_LOST = 5;
export const CARESEEKER_STATUS_SALES = 6;
export const CARESEEKER_STATUS_DUPLICATE = 7;
export const CARESEEKER_STATUS_TRASH = 8;

export const REHABILITATION_POTENTIAL_EVALUATION_POSITIVE = 1;
export const REHABILITATION_POTENTIAL_EVALUATION_MODERATE = 2;
export const REHABILITATION_POTENTIAL_EVALUATION_NEGATIVE = 3;
export const REHABILITATION_POTENTIAL_EVALUATION_EXTREMELY_POSITIVE = 4;
export const REHABILITATION_POTENTIAL_EVALUATION_EXTREMELY_NEGATIVE = 5;

// export const PATIENT_STATUS_NONE = 0;
// export const PATIENT_STATUS_AUTOMATIC_SUCCESS = 1;
// export const PATIENT_STATUS_MANUAL_ASSIGN = 2;
// export const PATIENT_STATUS_USER_ERROR = 4;
// export const PATIENT_STATUS_FAIL_NO_POSITIVE_RESPONSE = 5;
// export const PATIENT_STATUS_FAIL_WITH_POSITIVE_RESPONSES = 6;
// export const PATIENT_STATUS_IN_PROGRESS = 7;
// export const PATIENT_STATUS_CANCELLED_THIRD_PARTY_REASONS = 8;
// export const PATIENT_STATUS_PAUSED = 9;
// export const PATIENT_STATUS_NOT_STARTED = 10;

// export const COST_COVERAGE_NOT_APPLIED = 1;
// export const COST_COVERAGE_APPLIED = 2;
// export const COST_COVERAGE_APPROVED = 3;
// export const COST_COVERAGE_DECLINED = 4;

export const FORM_YES_NO_POSITIVE = 1;
export const FORM_YES_NO_NEGATIVE = 0;

export const SEARCH_TYPE_CARE = 1 as const;
export const SEARCH_TYPE_HOSPITAL = 2 as const;
export const SEARCH_TYPE_REHABILITATION = 3 as const;
export const SEARCH_TYPE_TRANSPORT = 4 as const;
export const SEARCH_TYPE_MEDICAL_SUPPLIES = 5 as const;
export const SEARCH_TYPE_HOME_CARE = 6 as const;

export const SEARCH_TYPES = [
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_HOME_CARE,
] as const;

export const PROVIDER_SELFONBOARDING_TYPES: SearchType[] = [SEARCH_TYPE_CARE];

export const CARE_SERVICES = [
  SERVICE_COMPANIONSHIP,
  SERVICE_BASIC_CARE,
  SERVICE_BASIC_MEDICAL_CARE,
  SERVICE_TRACHEOSTOMY,
  SERVICE_PALLIATIVE_CARE,
  SERVICE_PSYCHIATRIC_CARE,
  SERVICE_DEMENTIA,
  SERVICE_CLOSED_AREA,
  SERVICE_PALLIATIVE_CERTIFICATE,
  SERVICE_SPECIALISED_INTENSIVE_NURSE,
  SERVICE_STATIC_CARE_DISABLED_PATIENTS,
];

export const HOSPITAL_SERVICES = [
  SERVICE_INVASIVEVENTILATION,
  SERVICE_TRACHEOSTOMY,
  SERVICE_NONINVASIVEVENTILATION,
  SERVICE_CLOSED_AREA,
  SERVICE_PALLIATIVE_CARE,
];

export const REHA_SERVICES = [
  SERVICE_DIALYSIS,
  SERVICE_PARENTERAL_NUTRITRION,
  SERVICE_VISUALLY_IMPAIRED,
  SERVICE_HEARING_IMPAIRED,
  SERVICE_ENTERALNUTRITION,
  SERVICE_PROTECTEDAREA,
  SERVICE_PEOPLEWITHORIENTATIONDISORDER,
  SERVICE_HELPWITHEATING,
  SERVICE_PEOPLEWITHADDICTIONS,
  SERVICE_PEOPLEWHOAREDANGEROUS,
  SERVICE_TAKINGOVERBODYCARE,
  SERVICE_MONITORINGCIRCULATION,
  SERVICE_MONITORINGBREATHING,
  SERVICE_MONITORINGMETABOLISM,
  SERVICE_INVASIVEVENTILATION,
  SERVICE_NONINVASIVEVENTILATION,
  SERVICE_OXYGENTHERAPY,
  SERVICE_TRACHEOSTOMY,
];

export const SERVICES_TYPE_CARE = [
  SERVICE_COMPANIONSHIP,
  SERVICE_BASIC_CARE,
  SERVICE_BASIC_MEDICAL_CARE,
  SERVICE_PALLIATIVE_CARE,
  SERVICE_PALLIATIVE_CERTIFICATE,
];

export const PREREQUISITES = [
  SERVICE_TRACHEOSTOMY,
  SERVICE_PALLIATIVE_CARE,
  SERVICE_PSYCHIATRIC_CARE,
  SERVICE_SPECIALISED_INTENSIVE_NURSE,
];

export const MAIL_TYPES = {
  AUCTION_REQUEST: 2,
  PATIENT_UNAVAILABLE: 5,
  ATTRIBUTION_CONFIRMATION: 6,
  WELCOME: 9,
  NEW_ANSWER: 10,
  NEW_MESSAGE_FROM_CLINIC: 12,
  SEARCH_REMINDER: 17,
  ATTRIBUTION_CANCELLATION: 18,
  RESET_PASSWORD: 19,
  CAREPROVIDER_ACCOUNT_INVITATION: 20,
  CAREPROVIDER_ACCOUNT_ADDED: 21,
  PROFILE_EDITED_NOTIFICATION: 23,
  ONBOARDING: 24,
  PASSWORD_CHANGED: 25,
  SOCIAL_WORKER_ASSIGNED: 27,
  AUCTION_REQUEST_DECLINED: 28,
  SUMMARY: 29,
  RECOMMENDATION_REMINDER: 30,
  ONBOARD_REMINDER: 45,
  AUCTION_REQUEST_AVAILABLE: 46,
  OCCUPANCY_DURATION_EXPIRATION: 47,
  REQUEST_ASSIGNED: 48,
  CAPACITY_SETTING_REMINDER: 51,
  DEVICE_VERIFICATION: 52,
  ONBOARDING_HOME_CARE_MEDICAL_SUPPLIES: 54,
  SECURITY_CHECK: 55,
  UPDATED_TERMS_AND_CONDITIONS: 56,
  CTA_ELECTIVE_PATIENT: 57,
  REVERSE_SEARCH_AUCTION_REQUEST: 58,
  NEW_FILE_FROM_CLINIC: 59,
  NEW_FILE_FROM_RECEIVER: 60,
  PROVIDER_SEARCH_REGISTRATION: 61,
  PROVIDER_SEARCH_LOGIN: 62,
  PROVIDER_SEARCH_REQUEST: 63,
  PROVIDER_SEARCH_REQUEST_UNAVAILABLE: 64,
  PROVIDER_SEARCH_NEW_ACTIVITY: 65,
  PROVIDER_SEARCH_PATIENT_ATTRIBUTED: 66,
  PROVIDER_SEARCH_NEW_MESSAGE: 67,
  PPM_MIGRATION: 68,
  PROVIDER_SEARCH_ACCOUNT_DELETION: 69,
} as const;

export const REHA_SPECIALIZATIONS = [
  SPECIALIZATION_ANGIOLOGY,
  SPECIALIZATION_DERMATOLOGY,
  SPECIALIZATION_GASTROENTEROLOGY,
  SPECIALIZATION_GERIATRICS,
  SPECIALIZATION_INTERNAL_MEDICINE,
  SPECIALIZATION_CARDIOLOGY,
  SPECIALIZATION_PARENTS_AND_CHILD_REHAB,
  SPECIALIZATION_ONCOLOGY,
  SPECIALIZATION_ORTHOPEDICS,
  SPECIALIZATION_PEDIATRICS,
  SPECIALIZATION_PHASEB,
  SPECIALIZATION_PHASEC,
  SPECIALIZATION_PHASED,
  SPECIALIZATION_PNEUMOLOGY,
  SPECIALIZATION_PSYCHOSOMATIC,
  SPECIALIZATION_RHEUMATISM,
  SPECIALIZATION_ADDICTION,
  SPECIALIZATION_UROLOGY,
  SPECIALIZATION_PHASECPLUS,
  SPECIALIZATION_NEUROPEDIATRIY,
  SPECIALIZATION_ONCOLOGY_RESPIRATORY_ORGANS,
  SPECIALIZATION_ONCOLOGY_LOCOMOTOR_ORGANS,
  SPECIALIZATION_ONCOLOGY_BREAST_GLANDS,
  SPECIALIZATION_ONCOLOGY_SKIN,
  SPECIALIZATION_ONCOLOGY_MALIGNANT_SYSTEMIC_DISEASES,
  SPECIALIZATION_ONCOLOGY_KIDNEY_URINARY_MALE_GENITAL,
  SPECIALIZATION_ONCOLOGY_PHARYNX_LARYNX,
  SPECIALIZATION_ONCOLOGY_THYROID,
  SPECIALIZATION_ONCOLOGY_DIGESTIVE_ORGANS,
  SPECIALIZATION_ONCOLOGY_FEMALE_GENITAL,
  SPECIALIZATION_ONCOLOGY_CENTRAL_NERVOUS_SYSTEM,
  SPECIALIZATION_ENDOCRINOLOGY,
  SPECIALIZATION_NEPHROLOGY,
  SPECIALIZATION_GYNAECOLOGY,
  SPECIALIZATION_ICU_HIGH_CORONA,
  SPECIALIZATION_OBESITY,
];

export const ADDITIONAL_SPECIALIZATIONS = [
  SPECIALIZATION_ALLERGOLOGY,
  SPECIALIZATION_PEDIATRICS,
  SPECIALIZATION_RADIOLOGY,
  SPECIALIZATION_UROLOGY,
  SPECIALIZATION_PALLIATIVE_CARE,
  SPECIALIZATION_ORTHOPEDICS,
  SPECIALIZATION_ONCOLOGY,
  SPECIALIZATION_OPHTHALMOLOGY,
  SPECIALIZATION_PSYCHIATRY,
  SPECIALIZATION_DIABETIC_FOOT,
  SPECIALIZATION_SURGICAL_RESUSCITATION,
  SPECIALIZATION_MEDICAL_REANIMATION,
  SPECIALIZATION_RHEUMATISM,
  SPECIALIZATION_PNEUMOLOGY,
  SPECIALIZATION_INTERNAL_MEDICINE,
  SPECIALIZATION_NEUROLOGY,
  SPECIALIZATION_GYNAECOLOGY,
  SPECIALIZATION_NEPHROLOGY,
  SPECIALIZATION_ENT,
  SPECIALIZATION_NEUROSURGERY,
  SPECIALIZATION_INFECTIOUS_DISEASE,
  SPECIALIZATION_HAEMATOLOGY,
  SPECIALIZATION_HEPATOLOGY,
  SPECIALIZATION_GERIATRICS,
  SPECIALIZATION_PHYSICAL_MEDICINE,
  SPECIALIZATION_GASTROENTEROLOGY,
  SPECIALIZATION_DIABETOLOGY,
  SPECIALIZATION_PLASTIC_SURGERY,
  SPECIALIZATION_ENDOCRINOLOGY,
  SPECIALIZATION_DERMATOLOGY,
  SPECIALIZATION_VASCULAR_SURGERY,
  SPECIALIZATION_THORACIC_SURGERY,
  SPECIALIZATION_DIGESTIVE_SURGERY,
  SPECIALIZATION_MAXILLOFACIAL_SURGERY,
  SPECIALIZATION_CARDIOLOGY,
  SPECIALIZATION_ADDICTOLOGY,
];

export const HOSPITAL_SPECIALIZATIONS_FR = [
  SPECIALIZATION_ACUTE_GERIATRICS,
  SPECIALIZATION_INTERNAL_MEDICINE,
  SPECIALIZATION_ICU_HIGH_CORONA,
  SPECIALIZATION_NORMAL_STATION,
  SPECIALIZATION_CARDIOLOGY,
  SPECIALIZATION_GASTROENTEROLOGY,
  SPECIALIZATION_PNEUMOLOGY,
  SPECIALIZATION_INFECTIOUS_DISEASE,
  SPECIALIZATION_NEUROLOGY,
  SPECIALIZATION_ONCOLOGY,
  SPECIALIZATION_PEDIATRICS,
  SPECIALIZATION_RADIOLOGY,
  SPECIALIZATION_TRAUMA_SURGERY,
  SPECIALIZATION_GENERAL_SURGERY,
  SPECIALIZATION_ANESTHESIOLOGY,
  SPECIALIZATION_ICU_NEUROVASCULAR,
  SPECIALIZATION_ICU_CARDIOLOGY,
  SPECIALIZATION_ICU_GASTROLOGY,
  SPECIALIZATION_COGNITIVE_BEHAVIORAL_UNIT,
  SPECIALIZATION_UROLOGY,
  SPECIALIZATION_PALLIATIVE_CARE,
  SPECIALIZATION_OPHTHALMOLOGY,
  SPECIALIZATION_PSYCHIATRY,
  SPECIALIZATION_DIABETIC_FOOT,
  SPECIALIZATION_SURGICAL_RESUSCITATION,
  SPECIALIZATION_MEDICAL_REANIMATION,
  SPECIALIZATION_RHEUMATISM,
  SPECIALIZATION_GYNAECOLOGY,
  SPECIALIZATION_NEPHROLOGY,
  SPECIALIZATION_ENT,
  SPECIALIZATION_NEUROSURGERY,
  SPECIALIZATION_HAEMATOLOGY,
  SPECIALIZATION_DIABETOLOGY,
  SPECIALIZATION_DERMATOLOGY,
  SPECIALIZATION_VASCULAR_SURGERY,
  SPECIALIZATION_THORACIC_SURGERY,
  SPECIALIZATION_DIGESTIVE_SURGERY,
  SPECIALIZATION_ORTHOGERIATRICS,
  SPECIALIZATION_ORTHOPEDICS,
  SPECIALIZATION_ORTHOPEDICS_UPPER_LIMBS,
  SPECIALIZATION_ORTHOPEDICS_LOWER_LIMBS,
  SPECIALIZATION_ORTHOPEDICS_SPINE,
  SPECIALIZATION_ORTHOPEDICS_SOS_MAINS,
  SPECIALIZATION_ENDOCRINOLOGY,
];

export const HOSPITAL_SPECIALIZATIONS_DE = [
  SPECIALIZATION_ACUTE_GERIATRICS,
  SPECIALIZATION_INTERNAL_MEDICINE,
  SPECIALIZATION_ICU_HIGH_CORONA,
  SPECIALIZATION_ICU_ECMO_CORONA,
  SPECIALIZATION_ICU_LOW_CORONA,
  SPECIALIZATION_NORMAL_STATION,
  SPECIALIZATION_RADIOLOGY,
  SPECIALIZATION_TRAUMA_SURGERY,
  SPECIALIZATION_GENERAL_SURGERY,
  SPECIALIZATION_ANESTHESIOLOGY,
  SPECIALIZATION_ICU_NEUROVASCULAR,
  SPECIALIZATION_ICU_CARDIOLOGY,
  SPECIALIZATION_ICU_GASTROLOGY,
  SPECIALIZATION_COGNITIVE_BEHAVIORAL_UNIT,
  SPECIALIZATION_UROLOGY,
  SPECIALIZATION_PALLIATIVE_CARE,
  SPECIALIZATION_ONCOLOGY,
  SPECIALIZATION_OPHTHALMOLOGY,
  SPECIALIZATION_PSYCHIATRY,
  SPECIALIZATION_DIABETIC_FOOT,
  SPECIALIZATION_SURGICAL_RESUSCITATION,
  SPECIALIZATION_MEDICAL_REANIMATION,
  SPECIALIZATION_RHEUMATISM,
  SPECIALIZATION_PNEUMOLOGY,
  SPECIALIZATION_NEUROLOGY,
  SPECIALIZATION_GYNAECOLOGY,
  SPECIALIZATION_NEPHROLOGY,
  SPECIALIZATION_ENT,
  SPECIALIZATION_NEUROSURGERY,
  SPECIALIZATION_INFECTIOUS_DISEASE,
  SPECIALIZATION_HAEMATOLOGY,
  SPECIALIZATION_GASTROENTEROLOGY,
  SPECIALIZATION_DIABETOLOGY,
  SPECIALIZATION_DERMATOLOGY,
  SPECIALIZATION_VASCULAR_SURGERY,
  SPECIALIZATION_THORACIC_SURGERY,
  SPECIALIZATION_DIGESTIVE_SURGERY,
  SPECIALIZATION_CARDIOLOGY,
  SPECIALIZATION_PEDIATRICS,
  SPECIALIZATION_ORTHOGERIATRICS,
  SPECIALIZATION_ORTHOPEDICS,
  SPECIALIZATION_WEANING,
  SPECIALIZATION_CARDIOLOGICAL_EARLY_REHA,
  SPECIALIZATION_INTERNAL_MEDICAL_EARLY_REHA,
  SPECIALIZATION_PNEUMOLOGICAL_EARLY_REHA,
  SPECIALIZATION_ORTHOPEDICS_SPINE,
  SPECIALIZATION_ENDOCRINOLOGY,
];

export const HOME_CARE_SPECIALIZATIONS = [
  HOME_CARE_SPECIALIZATION_TRACHEOSTOMY_THERAPY,
  HOME_CARE_SPECIALIZATION_WOUND_CARE,
  HOME_CARE_SPECIALIZATION_PARENTERAL_NUTRITION,
  HOME_CARE_SPECIALIZATION_ENTERAL_NUTRITION,
  HOME_CARE_SPECIALIZATION_RESPIRATORY_THERAPY,
  HOME_CARE_SPECIALIZATION_VENTILATION,
  HOME_CARE_SPECIALIZATION_INCONTINENCE,
  HOME_CARE_SPECIALIZATION_MONITORING,
  HOME_CARE_SPECIALIZATION_PAIN_THERAPY,
  HOME_CARE_SPECIALIZATION_INFUSION_THERAPY,
  HOME_CARE_SPECIALIZATION_DECUBITUS_PROPHYLAXIS,
  HOME_CARE_SPECIALIZATION_COMPRESSION_THERAPY,
  HOME_CARE_SPECIALIZATION_OXYGEN,
  HOME_CARE_SPECIALIZATION_INCONTINENCE_DISCHARGING,
  HOME_CARE_SPECIALIZATION_STOMA,
];

export const MAX_HEIGHT = 300;
export const MAX_WEIGHT = 500;
export const OVERHEIGHT = 200;
export const OVERWEIGHT_CARE = 100;
export const OVERWEIGHT_REHAB = 120;
export const OVERWEIGHT_HOSPITAL = 120;

export const CAPACITY_STATUS_FREE = 1 as const;
export const CAPACITY_STATUS_UNKNOWN = 2 as const;
export const CAPACITY_STATUS_FULL = 3 as const;

export const CAPACITY_STATUSES = [
  CAPACITY_STATUS_FREE,
  CAPACITY_STATUS_UNKNOWN,
  CAPACITY_STATUS_FULL,
] as const;

export const typeOfCareToRights: { [key in SearchType]: Array<number> } = {
  [SEARCH_TYPE_CARE]: [ACCOUNT_ROLE_CARESEEKER_CARE],
  [SEARCH_TYPE_HOSPITAL]: [ACCOUNT_ROLE_CARESEEKER_HOSPITALIZATION],
  [SEARCH_TYPE_REHABILITATION]: [ACCOUNT_ROLE_CARESEEKER_REHAB],
  [SEARCH_TYPE_TRANSPORT]: [ACCOUNT_ROLE_TRANSPORT],
  [SEARCH_TYPE_MEDICAL_SUPPLIES]: [ACCOUNT_ROLE_MEDICAL_SUPPLIES],
  [SEARCH_TYPE_HOME_CARE]: [ACCOUNT_ROLE_MEDICAL_SUPPLIES],
};

export const REHABILITATION_PAYMENT_OPTION = {
  INSURANCE: 1,
  CIVIL_SERVANTS_AID: 2,
  PUBLIC_ACCIDENT_INSURANCE: 3,
  PUBLIC_PENSION: 4,
  SOCIAL_SERVICE_DEPARTMENT: 5,
  SELF_PAYER: 6,
  OTHER: 7,
} as const;

export const PAYERS_MAPPING: Record<SelectedPaymentMethod, keyof Payers> = {
  [REHABILITATION_PAYMENT_OPTION.INSURANCE]: "insurance",
  [REHABILITATION_PAYMENT_OPTION.CIVIL_SERVANTS_AID]: "civil_servants_aid",
  [REHABILITATION_PAYMENT_OPTION.PUBLIC_ACCIDENT_INSURANCE]:
    "public_accident_insurance",
  [REHABILITATION_PAYMENT_OPTION.PUBLIC_PENSION]: "public_pension",
  [REHABILITATION_PAYMENT_OPTION.SOCIAL_SERVICE_DEPARTMENT]:
    "social_service_department",
  [REHABILITATION_PAYMENT_OPTION.SELF_PAYER]: "self_payer",
  [REHABILITATION_PAYMENT_OPTION.OTHER]: "other_payer",
} as const;

export const REHABILITATION_PAYMENT_OPTIONS = [
  REHABILITATION_PAYMENT_OPTION.INSURANCE,
  REHABILITATION_PAYMENT_OPTION.CIVIL_SERVANTS_AID,
  REHABILITATION_PAYMENT_OPTION.PUBLIC_ACCIDENT_INSURANCE,
  REHABILITATION_PAYMENT_OPTION.PUBLIC_PENSION,
  REHABILITATION_PAYMENT_OPTION.SOCIAL_SERVICE_DEPARTMENT,
  REHABILITATION_PAYMENT_OPTION.SELF_PAYER,
  REHABILITATION_PAYMENT_OPTION.OTHER,
] as const;

export const CARE_SOLUTIONS = [
  SOLUTION_HELP_AT_HOME,
  SOLUTION_MOBILE_CARE,
  // SOLUTION_FULL_TIME_MOBILE_CARE,
  // SOLUTION_DAY_CARE,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
];

export const HOSPITAL_SOLUTIONS = [
  SOLUTION_DAY_HOSPITALIZATION,
  SOLUTION_FULL_HOSPITALIZATION,
];

export const REHABILITATION_SOLUTIONS = [
  SOLUTION_REHAB_FULL_HOSPITALIZATION,
  SOLUTION_REHAB_DAY_HOSPITALIZATION,
  SOLUTION_REHAB_MOBILE,
];

export const CARE_FACILITIES = [
  FACILITY_MOBILE_CARE,
  FACILITY_FULLTIME_MOBILE_CARE,
  FACILITY_COMPANIONSHIP,
  FACILITY_FULLTIME_COMPANIONSHIP,
  FACILITY_DAY_CARE,
  FACILITY_SHORT_TERM_CARE,
  FACILITY_STATIC_CARE,
  FACILITY_SHARED_FLAT,
  FACILITY_HOSPICE_ASSOCIATION,
  FACILITY_HOSPICE_STATIC,
  FACILITY_HOSPICE_PARTIALLY_MOBILE,
  FACILITY_HOSPICE_MOBILE,
  FACILITY_NIGHT_CARE_STATIC,
  FACILITY_NIGHT_CARE_MOBILE,
  FACILITY_ASSISTED_LIVING,
  FACILITY_DAILY_LIVING_SUPPORT_SERVICES,
];

export const MOBILE_CARE_FACILITIES = [
  FACILITY_MOBILE_CARE,
  FACILITY_FULLTIME_MOBILE_CARE,
  FACILITY_COMPANIONSHIP,
  FACILITY_FULLTIME_COMPANIONSHIP,
  FACILITY_PURE_INTENSIVE_CARE,
  FACILITY_HOSPICE_MOBILE,
  FACILITY_DAILY_LIVING_SUPPORT_SERVICES,
  FACILITY_NIGHT_CARE_MOBILE,
];

export const HOSPITAL_FACILITIES = [
  FACILITY_DAY_HOSPITALIZATION,
  FACILITY_FULL_HOSPITALIZATION,
];

export const REHABILITATION_FACILITIES = [
  FACILITY_REHAB_FULL_HOSPITALIZATION,
  FACILITY_REHAB_DAY_HOSPITALIZATION,
  FACILITY_REHAB_MOBILE,
];

export const ACCOUNT_ACTION_ACTIVATE = 1;
export const ACCOUNT_ACTION_RESET_PASSWORD = 2;
export const ACCOUNT_ACTION_INVITE = 3;

export const ACADEMIC_TITLE_DOCTOR = 1;
export const ACADEMIC_TITLE_DOCTOR_MULTIPLEX = 2;
export const ACADEMIC_TITLE_DOCTOR_MEDICINE = 3;
export const ACADEMIC_TITLE_DOCTOR_DOCTOR_MEDICINE = 4;
export const ACADEMIC_TITLE_PROFESSOR = 5;
export const ACADEMIC_TITLE_PROFESSOR_DOCTOR = 6;
export const ACADEMIC_TITLE_PROFESSOR_ORDINARIUS = 7;

export const APP_LANGUAGE = {
  EN: "en",
  DE: "de",
} as const;

export const CHECKBOX_SPACE = {
  NONE: 0,
  SMALL: 3,
  MEDIUM: 4,
} as const;

export const CHECKBOX_SIZE = {
  MEDIUM: 1,
  LARGE: 1.3,
} as const;

export const LOCATION_FILTER_ZIPCODES = 1;
export const LOCATION_FILTER_RADIUS_IN_KM = 2;

export const RADIUS_IN_KM_15 = 15;
export const RADIUS_IN_KM_25 = 25;
export const RADIUS_IN_KM_30 = 30;
export const RADIUS_IN_KM_50 = 50;
export const RADIUS_IN_KM_200 = 200;

export const VALIDATION_INVALID = "validation_invalid";
export const VALIDATION_MISSING = "validation_missing";

export const NO_CAPACITY_FOR_ROOM = 1 as const;
export const NO_CAPACITY_FOR_GENDER = 2 as const;
export const NO_CAPACITY_FOR_INFECTION = 3 as const;
export const NO_CAPACITY_FOR_SERVICE = 4 as const;
export const NO_CAPACITY_FOR_SOLUTION = 5 as const;
export const NO_CAPACITY_FOR_CARELEVEL = 6 as const;
export const NO_CAPACITY_FOR_SPECIALIZATION = 7 as const;

export const REQUEST_UNAVAILABLE_REASON_SUCCESS = 1;
export const REQUEST_UNAVAILABLE_REASON_STOPPED = 2;
export const REQUEST_UNAVAILABLE_REASON_ACCEPTED = 3;

export const FILTER_SERVICE = 1;
export const FILTER_STATUS = 4;
export const FILTER_ZIPCODES = 5;
export const FILTER_CAPACITY_START_DATE = 6;
export const FILTER_CONTACTED = 7;
export const FILTER_AGE = 8;
export const FILTER_DISTANCE = 11;
export const FILTER_THROTTLE = 12;
export const FILTER_PREREQUISITE = 14;
export const FILTER_SPECIALIZATION = 15;
// export const FILTER_ADDITIONAL_SPECIALIZATION = 16;
export const FILTER_SOLUTION = 17;
export const FILTER_CAPACITY_BACKOFF = 18;
export const FILTER_NO_BED_AVAILABLE = 19;
export const FILTER_REMOVED_SOLUTION = 20;
export const FILTER_RECEIVER_BLACKLISTED = 21;
export const FILTER_BLACKLISTED_INSURANCE = 22;
export const FILTER_SENDER_BLACKLISTED = 23;
export const FILTER_WEIGHT = 25;

export const RECEIVER_INACTIVE_REASONS = {
  NO_INTEREST_DO_NOT_CONTACT: 1,
  CLOSED: 2,
  NEVER_REACHED_AGAIN: 3,
  UNSUPPORTED_FACILITY: 4,
  NO_INTEREST_MAYBE_LATER: 5,
  CHURNED: 6,
  TEMPORARY_CLOSED: 7,
  UNNNEEDED_CHAIN_CENTER: 8,
  UNNNEEDED_CHAIN_SUBSIDIARY: 9,
  NO_ACCOUNT: 10,
  ASKLEPIOS_INHOUSE_SOLUTION: 11,
  MEDIAN_INHOUSE_SOLUTION: 12,
  RECEIVER_CLOSING: 13,
  HIGH_LEVEL_DECISION: 14,
} as const;

export const INFECTION_STATUS = {
  HAS_INFECTION: 1,
  NONE: 2,
  UNKNOWN: 3,
};

export const FILTER_TYPES = {
  FILTER_DISTANCE,
  FILTER_AGE,
  FILTER_STATUS,
  FILTER_ZIPCODES,
  FILTER_SERVICE,
  FILTER_PREREQUISITE,
  FILTER_CONTACTED,
  FILTER_SPECIALIZATION,
  FILTER_SOLUTION,
  FILTER_CAPACITY_START_DATE,
  FILTER_CAPACITY_BACKOFF,
  FILTER_THROTTLE,
  FILTER_NO_BED_AVAILABLE,
  FILTER_REMOVED_SOLUTION,
  FILTER_BLACKLISTED_INSURANCE,
  FILTER_RECEIVER_BLACKLISTED,
  FILTER_SENDER_BLACKLISTED,
  FILTER_WEIGHT,
};

export const CARDIOVASCULAR_EQUIPMENT = "cardiovascularExploration";

export const TECHNICAL_EQUIPMENT_CARDIAC_MRI = 1;
export const TECHNICAL_EQUIPMENT_CORONARY_SCANNER = 2;
export const TECHNICAL_EQUIPMENT_CORONAROGRAPHY = 21;
export const TECHNICAL_EQUIPMENT_EXERCISE_TEST = 3;
export const TECHNICAL_EQUIPMENT_HOLTER_ECG = 4;
export const TECHNICAL_EQUIPMENT_RYTHMOLOGY = 5;
export const TECHNICAL_EQUIPMENT_TOE = 6;
export const TECHNICAL_EQUIPMENT_TTE = 7;

export const DIGESTIVE_ENDOSCOPY = "digestiveEndoscopy";

export const TECHNICAL_EQUIPMENT_COLONOSCOPY = 8;
export const TECHNICAL_EQUIPMENT_ENDOSCOPIC_ULTRASOUND = 9;
export const TECHNICAL_EQUIPMENT_ERCP = 10;
export const TECHNICAL_EQUIPMENT_GASTROSCOPY = 11;

export const OTHER_EQUIPMENT = "otherTechnicalEquipment";

export const TECHNICAL_EQUIPMENT_DIALYSIS = 12;
export const TECHNICAL_EQUIPMENT_MONITORING_BREATHING = 13;
export const TECHNICAL_EQUIPMENT_MONITORING_CIRCULATION = 14;
export const TECHNICAL_EQUIPMENT_MONITORING_METABOLISM = 15;

export const IMAGERY = "imagery";

export const TECHNICAL_EQUIPMENT_CTSCAN = 16;
export const TECHNICAL_EQUIPMENT_DOPPLERS = 17;
export const TECHNICAL_EQUIPMENT_INTERVENTION_RADIOLOGY = 18;
export const TECHNICAL_EQUIPMENT_MRI = 19;
export const TECHNICAL_EQUIPMENT_ULTRASOUND = 20;
export const TECHNICAL_EQUIPMENT_XRAY = 21;

export const INFECTION_AND_GERMS_REQUIRES_ISOLATION = 1;
export const INFECTION_AND_GERMS_REQUIRES_MRSA = 2;
export const INFECTION_AND_GERMS_REQUIRES_THREE_MRGN = 3;
export const INFECTION_AND_GERMS_REQUIRES_FOUR_MRGN = 4;
export const INFECTION_AND_GERMS_REQUIRES_CLOSTRIDIEN = 5;
export const INFECTION_AND_GERMS_REQUIRES_VRE = 6;

export const VIGILANCE_AGITATED = 1;
export const VIGILANCE_AWAKE = 2;
export const VIGILANCE_CALM = 3;
export const VIGILANCE_COMATOSE = 4;
export const VIGILANCE_RESPONSIVE = 5;
export const VIGILANCE_SOMNOLENT = 6;
export const VIGILANCE_SOPORIFIC = 7;

export const SORT_ORDER_VIGILANCE = [
  VIGILANCE_AWAKE,
  VIGILANCE_CALM,
  VIGILANCE_AGITATED,
  VIGILANCE_RESPONSIVE,
  VIGILANCE_SOMNOLENT,
  VIGILANCE_SOPORIFIC,
  VIGILANCE_COMATOSE,
];

export const DIFF_FIELDS = {
  AUCTION_RADIUS_IN_METER: "Auction.RadiusInMeter",
  AUCTION_SOLUTIONS: "Auction.Solutions",
  AUCTION_SPECIALIZATION: "Auction.Specialization",
  AUCTION_SERVICES: "Auction.Services",
  PROFILE: "Profile",
  DIAGNOSIS: "Diagnosis",
  CARE_NEEDS: "CareNeeds",
  TRANSPORT: "Transport",
  SOCIAL_WORKER_ID: "SocialWorkerID",
  STATION_FULL_ID: "StationFullID",
  MENTAL_HEALTH: "Diagnosis.MentalHealth",
  PHYSICAL_DISABILITIES: "Diagnosis.PhysicalDisabilities",
};

export const ASSESSMENT_VARIANTS = {
  MOBILE_SHORT: "mobile-short",
  ELECTIVE_REHAB: "elective-rehab",
  REHAB_SHORT: "rehab-short",
  NO_VARIANT: "",
} as const;

export const ASSESSMENT_SLUG_FIRST_STEP = "step" as const;

export const ASSESSMENT_SLUG_TRANSFER = "transfer" as const;
export const ASSESSMENT_SLUG_MOBILITY = "mobility" as const;
export const ASSESSMENT_SLUG_INFO = "info" as const;
export const ASSESSMENT_SLUG_MEDICAL = "medical" as const;
export const ASSESSMENT_SLUG_MENTAL = "mental" as const;
export const ASSESSMENT_SLUG_NEEDS = "needs" as const;
export const ASSESSMENT_SLUG_TRANSPORT = "transport" as const;
export const ASSESSMENT_SLUG_MOBILE_SHORT = "mobile-short" as const;
export const ASSESSMENT_SLUG_TRANSPORT_INFO = "transport-info" as const;
export const ASSESSMENT_SLUG_TRANSPORT_SEARCH = "transport-search" as const;
export const ASSESSMENT_SLUG_MEDICAL_SUPPLIES_INFO =
  "medical-supplies-info" as const;
export const ASSESSMENT_SLUG_MEDICAL_SUPPLIES_CATALOGUE =
  "medical-supplies-catalogue" as const;
export const ASSESSMENT_SLUG_HOME_CARE_INFO = "home-care-info" as const;
export const ASSESSMENT_SLUG_HOME_CARE_SEARCH = "home-care-search" as const;
export const ASSESSMENT_SLUG_ELECTIVE_REHAB_INFO =
  "elective-rehab-info" as const;
export const ASSESSMENT_SLUG_ELECTIVE_REHAB_MEDICAL =
  "elective-rehab-medical" as const;
export const ASSESSMENT_SLUG_SHORT_REHAB_INFO = "rehab-short-info" as const;
export const ASSESSMENT_SLUG_SHORT_REHAB_MEDICAL =
  "rehab-short-medical" as const;

export const ASSESSMENT_SLUGS = [
  ASSESSMENT_SLUG_FIRST_STEP,
  ASSESSMENT_SLUG_TRANSFER,
  ASSESSMENT_SLUG_MOBILITY,
  ASSESSMENT_SLUG_INFO,
  ASSESSMENT_SLUG_MEDICAL,
  ASSESSMENT_SLUG_MENTAL,
  ASSESSMENT_SLUG_NEEDS,
  ASSESSMENT_SLUG_TRANSPORT,
  ASSESSMENT_SLUG_MOBILE_SHORT,
  ASSESSMENT_SLUG_TRANSPORT_INFO,
  ASSESSMENT_SLUG_TRANSPORT_SEARCH,
  ASSESSMENT_SLUG_MEDICAL_SUPPLIES_INFO,
  ASSESSMENT_SLUG_MEDICAL_SUPPLIES_CATALOGUE,
  ASSESSMENT_SLUG_HOME_CARE_INFO,
  ASSESSMENT_SLUG_HOME_CARE_SEARCH,
  ASSESSMENT_SLUG_ELECTIVE_REHAB_INFO,
  ASSESSMENT_SLUG_ELECTIVE_REHAB_MEDICAL,
  ASSESSMENT_SLUG_SHORT_REHAB_INFO,
  ASSESSMENT_SLUG_SHORT_REHAB_MEDICAL,
] as const;

export const CORONA_TESTED = 1;
export const CORONA_NOT_TESTED = 2;
export const CORONA_UNKNOWN = 3;

export const CORONA_TESTED_CURED = 1;
export const CORONA_TESTED_NOT_CURED = 2;
export const CORONA_TESTED_NEGATIVE = 3;
export const CORONA_TEST_PENDING = 4;

// deprecated: only used for old events now
export const PATIENT_ELIGIBILITY_TO_BE_PROCESSED = 1;
export const PATIENT_ELIGIBILITY_IN_PROCESS = 2;
export const PATIENT_ELIGIBILITY_APPROVED = 3;
export const PATIENT_ELIGIBILITY_REJECTED = 4;

// deprecated: only used for old events now
export const PATIENT_FORM_DRAFT = 1;
export const PATIENT_FORM_READY = 2;
export const PATIENT_FORM_ALREADY_SHARED = 3;

export const HEALTH_INSURANCE_MANDATORY = 1;
export const HEALTH_INSURANCE_OPTIONAL = 2;

export const ELIGIBILITY_REJECT_NOT_ELIGIBLE = 1;
export const ELIGIBILITY_REJECT_DIFFERENT_COST_PAYER_RESPONSIBLE = 2;
export const ELIGIBILITY_REJECT_ANOTHER_FORM_OF_REHAB_SOUGHT = 3;
export const ELIGIBILITY_REJECT_OTHER = 4;

export const SENDER_DASHBOARD_FILTERS = "sender-dashboard-filters" as const;
export const RECEIVER_DASHBOARD_FILTERS = "receiver-dashboard-filters" as const;
export const B2C_DASHBOARD_FILTERS = "b2c-dashboard-filters" as const;
export const PROVIDER_SEARCH_DASHBOARD_FILTERS =
  "provider-search-dashboard-filters" as const;

export const FAX_TYPE_REQUEST = 1;
export const FAX_TYPE_ATTRIBUTION = 2;

export const FORM_TYPE_STATIC = 1;
export const FORM_TYPE_DYNAMIC = 2;

export const PAGINATION_COUNT = "count" as const;
export const PAGINATION_COUNT_10 = 10;
export const PAGINATION_COUNT_15 = 15;

export const PAGINATION_START = "start" as const;
export const PAGINATION_START_0 = 0;

export const SORT_ORDER_ASC = "asc" as const;
export const SORT_ORDER_DESC = "desc" as const;

export const ONBOARDING_EMAIL_REGULAR = 1;
export const ONBOARDING_EMAIL_PRIO = 2;
export const ONBOARDING_EMAIL_REMINDER = 3;

export const DELIVERY_ADDRESS_CLINIC_ADDRESS = 1;
export const DELIVERY_ADDRESS_PATIENT_ADDRESS = 2;
export const DELIVERY_ADDRESS_NEW_ADDRESS = 3;

export const FAX_SEARCH_TYPES: SearchType[] = [
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_REHABILITATION,
];

export const FAX_CAREPROVIDER_STATUS: CareproviderStatus[] = [
  CAREPROVIDER_INACTIVE,
  CAREPROVIDER_CREATED,
];

export const FILE_TYPES = {
  IMAGES: 1,
  DOCUMENTS: 2,
};

// export const DOCUMENT_TYPE_DPA = "dpa"; deprecated

export const JPG_JPEG = "image/jpeg";
export const PNG = "image/png";
export const TEXT = "text/plain";
export const PDF = "application/pdf";
export const DOC = "application/msword";
export const DOCX =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const REQUEST_ACTION_SEND_REQUEST_TO_SUGGESTED_PROVIDER =
  "send_request_to_suggested_provider";
export const REQUEST_ACTION_VALIDATE_PROVIDER = "validate_provider";
export const REQUEST_ACTION_REJECT_PROVIDER = "reject_provider";
export const REQUEST_ACTION_ACCEPT_REQUEST = "accept_request";
export const REQUEST_ACTION_DECLINE_REQUEST = "decline_request";
export const REQUEST_ACTION_SET_RECEIVER_ASSIGNEE = "set_receiver_assignee";
export const REQUEST_ACTION_SEND_MESSAGE = "send_message";
export const REQUEST_ACTION_SEND_FILE = "send_file";
export const REQUEST_ACTION_ACCESS_TO_DATA = "access_to_data";
export const REQUEST_ACTION_ACCESS_PATIENT_DATA = "access_patient_data";
export const REQUEST_ACTION_NEXT_REQUEST = "get_next_request";
export const REQUEST_ACTION_PATIENT_PREFERRED = "set_patient_preferred";

export const REQUEST_ACTION_TYPES = [
  REQUEST_ACTION_ACCEPT_REQUEST,
  REQUEST_ACTION_ACCESS_TO_DATA,
  REQUEST_ACTION_ACCESS_PATIENT_DATA,
  REQUEST_ACTION_DECLINE_REQUEST,
  REQUEST_ACTION_NEXT_REQUEST,
  REQUEST_ACTION_REJECT_PROVIDER,
  REQUEST_ACTION_SEND_FILE,
  REQUEST_ACTION_SEND_MESSAGE,
  REQUEST_ACTION_SEND_REQUEST_TO_SUGGESTED_PROVIDER,
  REQUEST_ACTION_SET_RECEIVER_ASSIGNEE,
  REQUEST_ACTION_VALIDATE_PROVIDER,
  REQUEST_ACTION_PATIENT_PREFERRED,
] as const;

export const UNDO_REQUEST_ACTION_REJECT_PROVIDER = "undo_reject_provider";
export const UNDO_REQUEST_ACTION_ACCEPT_REQUEST = "undo_accept_request";
export const UNDO_REQUEST_ACTION_REQUEST_DECLINATION = "undo_decline_request";
export const UNDO_REQUEST_ACTION_MANUALLY_ARCHIVED = "undo_manual_archive";
export const UNDO_REQUEST_ACTION_SUGGEST_PROVIDER = "undo_suggest_provider";

export const REQUEST_ACTION_UNDO_TYPES = [
  UNDO_REQUEST_ACTION_ACCEPT_REQUEST,
  UNDO_REQUEST_ACTION_REJECT_PROVIDER,
  UNDO_REQUEST_ACTION_REQUEST_DECLINATION,
  UNDO_REQUEST_ACTION_MANUALLY_ARCHIVED,
  UNDO_REQUEST_ACTION_SUGGEST_PROVIDER,
] as const;

export const DISABLED_REQUEST_ACTION_BAD_REQUEST_STATUS = "request_bad_status";

export const REQUEST_CONTEXT_DISABLED_TYPES = [
  DISABLED_REQUEST_ACTION_BAD_REQUEST_STATUS,
] as const;

// SEARCH ACTIONS
export const SEARCH_ACTION_START_MANUAL_SEARCH = "start_manual_search";
export const SEARCH_ACTION_START_AUTOMATIC_SEARCH = "start_automatic_search";
export const SEARCH_ACTION_RESTART_SEARCH = "restart_search";
export const SEARCH_ACTION_EDIT_SEARCH = "edit_search";
export const SEARCH_ACTION_SEND_REQUEST = "send_request";
export const SEARCH_ACTION_SHARE_PATIENT_DATA = "share_patient_data";
export const SEARCH_ACTION_PRINT_DISCHARGE = "print_discharge" as const;
export const SEARCH_ACTION_CANCEL_ATTRIBUTION = "cancel_attribution";
export const SEARCH_ACTION_MOVE_TO_AUTO_SEARCH = "move_to_auto_search";
export const SEARCH_ACTION_CANCEL_SEARCH = "cancel_search";
export const SEARCH_ACTION_ATTRIBUTE_WINNER = "attribute_winner";
export const SEARCH_ACTION_GENERATE_TRANSITIONAL_CARE_FORM =
  "generate_transitional_care";
export const SEARCH_ACTION_DELETE_TRANSITIONAL_CARE_FORM =
  "delete_transitional_care";
export const SEARCH_ACTION_DISPLAY_FILES_TAB = "display_files_tab";
export const SEARCH_ACTION_GENERATE_REHAB_FORMS = "generate_rehab_forms";
export const SEARCH_ACTION_DELETE_REHAB_FORMS = "delete_rehab_forms";
export const SEARCH_ACTION_UPDATE_SOCIAL_WORKER = "update_social_worker";

//to be deprecated
export const SEARCH_ACTION_GENERATE_AHB_FORMS = "generate_ahb_forms";

export const SEARCH_ACTIONS = [
  SEARCH_ACTION_START_MANUAL_SEARCH,
  SEARCH_ACTION_START_AUTOMATIC_SEARCH,
  SEARCH_ACTION_RESTART_SEARCH,
  SEARCH_ACTION_EDIT_SEARCH,
  SEARCH_ACTION_SEND_REQUEST,
  SEARCH_ACTION_GENERATE_AHB_FORMS,
  SEARCH_ACTION_PRINT_DISCHARGE,
  SEARCH_ACTION_CANCEL_ATTRIBUTION,
  SEARCH_ACTION_MOVE_TO_AUTO_SEARCH,
  SEARCH_ACTION_CANCEL_SEARCH,
  SEARCH_ACTION_ATTRIBUTE_WINNER,
  SEARCH_ACTION_GENERATE_TRANSITIONAL_CARE_FORM,
  SEARCH_ACTION_DELETE_TRANSITIONAL_CARE_FORM,
  SEARCH_ACTION_DISPLAY_FILES_TAB,
  SEARCH_ACTION_SHARE_PATIENT_DATA,
  SEARCH_ACTION_GENERATE_REHAB_FORMS,
  SEARCH_ACTION_DELETE_REHAB_FORMS,
  SEARCH_ACTION_UPDATE_SOCIAL_WORKER,
] as const;

export const DISABLED_SEARCH_ACTION_DEACTIVATED_AUTOMATIC_SEARCH =
  "disable_automatic_search";
export const DISABLED_SEARCH_ACTION_DEACTIVATED_MANUAL_SEARCH =
  "disable_manual_search";
export const DISABLED_SEARCH_ACTION_DEACTIVATED_PAUSED_ACTION = "disable_pause";
export const DISABLED_SEARCH_ACTION_SEND_REQUESTS_NOT_STARTED =
  "search_not_started";
export const DISABLED_SEARCH_ACTION_SEND_REQUESTS_ATTRIBUTED =
  "search_attributed";
export const DISABLED_SEARCH_ACTION_SEND_REQUESTS_CANCELLED =
  "search_cancelled";
export const DISABLED_SEARCH_ACTION_SEND_REQUESTS_ACCEPTED = "search_accepted";
export const DISABLED_SEARCH_ACTION_SEND_REQUESTS_TRANSPORT_LIMIT =
  "transport_manual_limit";
export const DISABLED_SEARCH_ACTION_TYPES = [
  DISABLED_SEARCH_ACTION_DEACTIVATED_AUTOMATIC_SEARCH,
  DISABLED_SEARCH_ACTION_DEACTIVATED_MANUAL_SEARCH,
  DISABLED_SEARCH_ACTION_DEACTIVATED_PAUSED_ACTION,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_NOT_STARTED,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_ATTRIBUTED,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_CANCELLED,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_ACCEPTED,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_TRANSPORT_LIMIT,
] as const;

export const SEARCH_ACTION_VARIANT_FAST_REHAB = "fast_rehab";

export const SEARCH_ACTION_VARIANTS = [
  SEARCH_ACTION_VARIANT_FAST_REHAB,
] as const;

export const CARE_DURATION_UNKNOWN = -1;
export const CARE_DURATION_ONE_DAY = 1;
export const CARE_DURATION_ONE_WEEK = 7;
export const CARE_DURATION_TWO_WEEKS = 14;
export const CARE_DURATION_THREE_WEEKS = 21;
export const CARE_DURATION_ONE_MONTH = 28;
export const CARE_DURATION_TWO_MONTHS = 60;
export const CARE_DURATION_THREE_MONTHS = 90;
export const CARE_DURATION_FOUR_MONTHS = 120;
export const CARE_DURATION_FIVE_MONTHS = 150;
export const CARE_DURATION_SIX_MONTHS_PLUS = 180;

export const MESSENGER_TAB_INFO = "info" as const;
export const MESSENGER_TAB_MESSENGER = "messenger" as const;
export const MESSENGER_TAB_FILES = "files" as const;
export const MESSENGER_TABS = [
  MESSENGER_TAB_MESSENGER,
  MESSENGER_TAB_FILES,
] as const;
export const MESSENGER_TABS_MOBILE = [
  MESSENGER_TAB_INFO,
  ...MESSENGER_TABS,
] as const;
export const ALL_MESSENGER_TABS = [...MESSENGER_TABS_MOBILE, ...MESSENGER_TABS];

export const FILE_SHARE_MODE_ALL = 1;
export const FILE_SHARE_MODE_SELECTED = 2;
export const FILE_SHARE_MODE_NONE = 3;
export const FILE_SHARE_MODE_KINDS = [
  FILE_SHARE_MODE_ALL,
  FILE_SHARE_MODE_NONE,
  FILE_SHARE_MODE_SELECTED,
] as const;

export const FILE_CATEGORY_MP = 1;
export const FILE_CATEGORY_RBA = 2;
export const FILE_CATEGORY_MR = 3;
export const FILE_CATEGORY_CTB = 4;
export const FILE_CATEGORY_MDL = 5;
export const FILE_CATEGORY_BIX = 6;
export const FILE_CATEGORY_CC = 7;
export const FILE_CATEGORY_AND = 8;
export const FILE_CATEGORY_AD = 9;
export const FILE_CATEGORY_PR = 10;
/** @deprecated */ export const FILE_CATEGORY_ERR = 11;
/** @deprecated */ export const FILE_CATEGORY_PCR = 12;
/** @deprecated */ export const FILE_CATEGORY_BTR = 13;
/** @deprecated */ export const FILE_CATEGORY_IMG = 14;
/** @deprecated */ export const FILE_CATEGORY_ECG = 15;
/** @deprecated */ export const FILE_CATEGORY_SN = 16;
export const FILE_CATEGORY_PRE = 17;
export const FILE_CATEGORY_RER = 18;
export const FILE_CATEGORY_WP = 19;
export const FILE_CATEGORY_MSR = 20;
export const FILE_CATEGORY_GI = 21;
export const FILE_CATEGORY_APF = 22;
export const FILE_CATEGORY_ISO = 23;
export const FILE_CATEGORY_FSO = 24;
export const FILE_CATEGORIES = [
  FILE_CATEGORY_MP,
  FILE_CATEGORY_RBA,
  FILE_CATEGORY_MR,
  FILE_CATEGORY_CTB,
  FILE_CATEGORY_MDL,
  FILE_CATEGORY_BIX,
  FILE_CATEGORY_CC,
  FILE_CATEGORY_AND,
  FILE_CATEGORY_AD,
  FILE_CATEGORY_PR,
  FILE_CATEGORY_SN,
  FILE_CATEGORY_PRE,
  FILE_CATEGORY_RER,
  FILE_CATEGORY_WP,
  FILE_CATEGORY_MSR,
  FILE_CATEGORY_GI,
  FILE_CATEGORY_APF,
  FILE_CATEGORY_ISO,
  FILE_CATEGORY_FSO,
] as const;
export const FILE_CATEGORIES_FR = [
  FILE_CATEGORY_CC,
  FILE_CATEGORY_AND,
  FILE_CATEGORY_AD,
  FILE_CATEGORY_PR,
  FILE_CATEGORY_PRE,
  FILE_CATEGORY_WP,
  FILE_CATEGORY_GI,
  FILE_CATEGORY_APF,
  FILE_CATEGORY_ISO,
  FILE_CATEGORY_FSO,
] as const;

export const FILE_ERROR_FILE_SIZE = 1 as const;
export const FILE_ERROR_FILE_TYPE = 2 as const;
export const FILE_ERROR_TYPE = [FILE_ERROR_FILE_SIZE, FILE_ERROR_FILE_TYPE];

export const BLUR_NONE = 0;
export const BLUR_LAST = 1;
export const BLUR_ALL = 2;

export const SEALD_ENCRYPTION_NO_ACCESS = 0 as const;
export const SEALD_ENCRYPTION_FULL_ACCESS = 1 as const;
export const SEALD_ENCRYPTION_PENDING_ACCESS = 2 as const;
export const SEALD_ENCRYPTION_BROKEN_ACCESS = 3 as const;
export const SEALD_ENCRYPTION_USER_NOT_REGISTERED = 4 as const;
export const SEALD_ENCRYPTION_NO_GROUP_CREATED = 5 as const;
export const SEALD_ENCRYPTION_PARTIAL_ACCESS = 6 as const;
export const SEALD_ENCRYPTION_PARTIAL_ACCESS_WITH_PENDING = 7 as const;

export const SEALD_ENCRYPTION_ACCESS_STATUS = [
  SEALD_ENCRYPTION_NO_ACCESS,
  SEALD_ENCRYPTION_FULL_ACCESS,
  SEALD_ENCRYPTION_PENDING_ACCESS,
  SEALD_ENCRYPTION_BROKEN_ACCESS,
  SEALD_ENCRYPTION_USER_NOT_REGISTERED,
  SEALD_ENCRYPTION_NO_GROUP_CREATED,
  SEALD_ENCRYPTION_PARTIAL_ACCESS,
  SEALD_ENCRYPTION_PARTIAL_ACCESS_WITH_PENDING,
] as const;

export const EXTENSION_QRAGO = 1 as const;
export const EXTENSION_CONNEXT = 2 as const;
export const EXTENSION_KORIAN = 4 as const;

export const EXTENSIONS = {
  [EXTENSION_QRAGO]: "Qrago",
  [EXTENSION_CONNEXT]: "Connext",
  [EXTENSION_KORIAN]: "Korian",
} as const;

export const NOTIFICATION_PERMISSION_GRANTED = "granted";
export const NOTIFICATION_PERMISSION_DENIED = "denied";
export const NOTIFICATION_PERMISSION_DEFAULT = "default";
export const NOTIFICATION_PERMISSION_PROMPT = "prompt";

export const SEALD_CONTEXT_TYPE_GROUP = 1;
export const SEALD_CONTEXT_TYPE_SESSION = 2;

export const SEALD_FLOW_DISABLED = 1;
export const SEALD_FLOW_PASSWORD = 2;
export const SEALD_FLOW_CHALLENGE = 3;

export const SEALD_FLOWS = [
  SEALD_FLOW_DISABLED,
  SEALD_FLOW_PASSWORD,
  SEALD_FLOW_CHALLENGE,
] as const;

export const COST_PAYER_TYPE_PRIVATE_HEALTH_INSURANCE = 1;
export const COST_PAYER_TYPE_PUBLIC_HEALTH_INSURANCE = 2;
export const COST_PAYER_TYPE_PUBLIC_PENSION = 3;
export const COST_PAYER_TYPE_ACCIDENT_INSURANCE = 4;

export const HEALTH_INSURANCE_TYPES = [
  COST_PAYER_TYPE_PUBLIC_HEALTH_INSURANCE,
  COST_PAYER_TYPE_PRIVATE_HEALTH_INSURANCE,
] as const;

export const COST_PAYER_TYPES = [
  ...HEALTH_INSURANCE_TYPES,
  COST_PAYER_TYPE_PUBLIC_PENSION,
  COST_PAYER_TYPE_ACCIDENT_INSURANCE,
] as const;

export const PAGES_FETCHED_COUNTER = "pages-fetched-counter";
export const SCROLL_ITEM_ID_POSITION = "scroll-position-item-id-marker";
export const SCROLL_RESTORATION_TIMESTAMP = "scroll-restoration-timestamp";

export const CARELEVEL_UNKNOWN = -1;
export const CARELEVEL_NONE = 0;
export const CARELEVEL_ONE = 1;
export const CARELEVEL_TWO = 2;
export const CARELEVEL_THREE = 3;
export const CARELEVEL_FOUR = 4;
export const CARELEVEL_FIVE = 5;

export const PATIENT_TAB_SEARCH = "search";
export const PATIENT_TAB_PROFILE = "profile";
export const PATIENT_TAB_FILES = "files";
export const PATIENT_TAB_TIMELINE = "timeline";
export const PATIENT_TAB_MAP = "map";
export const PATIENT_TAB_TRANSITIONAL_CARE = "transitional";
export const PATIENT_TAB_REHAB_FORMS = "rehab-forms";

export const PATIENT_TABS = [
  PATIENT_TAB_SEARCH,
  PATIENT_TAB_PROFILE,
  PATIENT_TAB_TIMELINE,
  PATIENT_TAB_FILES,
  PATIENT_TAB_MAP,
  PATIENT_TAB_TRANSITIONAL_CARE,
  PATIENT_TAB_REHAB_FORMS,
] as const;

// chip_status sorted from lowest to highest prio
export const REQUEST_CHIP_STATUS_PROVIDER_INACTIVE = 0;
export const REQUEST_CHIP_STATUS_FAX_SENT = 1;
export const REQUEST_CHIP_STATUS_REQUEST_NOT_SEEN = 2;
export const REQUEST_CHIP_STATUS_REQUEST_SEEN = 3;
export const REQUEST_CHIP_STATUS_SENT_MESSAGE_NOT_SEEN = 4;
export const REQUEST_CHIP_STATUS_SENT_MESSAGE_SEEN = 5;
export const REQUEST_CHIP_STATUS_ONGOING_CHAT = 6;
export const REQUEST_CHIP_STATUS_NEW_FILE = 7;
export const REQUEST_CHIP_STATUS_NEW_MESSAGE = 8;

export const REQUEST_CHIP_STATUSES = [
  REQUEST_CHIP_STATUS_PROVIDER_INACTIVE,
  REQUEST_CHIP_STATUS_FAX_SENT,
  REQUEST_CHIP_STATUS_REQUEST_NOT_SEEN,
  REQUEST_CHIP_STATUS_REQUEST_SEEN,
  REQUEST_CHIP_STATUS_SENT_MESSAGE_NOT_SEEN,
  REQUEST_CHIP_STATUS_SENT_MESSAGE_SEEN,
  REQUEST_CHIP_STATUS_ONGOING_CHAT,
  REQUEST_CHIP_STATUS_NEW_FILE,
  REQUEST_CHIP_STATUS_NEW_MESSAGE,
] as const;

export const CANDIDATES_STATUS_FAILED = -1;
export const CANDIDATES_STATUS_NOT_STARTED = 0;
export const CANDIDATES_STATUS_PENDING = 1;
export const CANDIDATES_STATUS_SUCCESS = 2;

export const CANDIDATES_STATUS = [
  CANDIDATES_STATUS_FAILED,
  CANDIDATES_STATUS_NOT_STARTED,
  CANDIDATES_STATUS_PENDING,
  CANDIDATES_STATUS_SUCCESS,
] as const;

export const SEARCH_TABLE_ACCEPTED = "accepted" as const;
export const SEARCH_TABLE_CONTACTED = "contacted" as const;
export const SEARCH_TABLE_POTENTIAL = "potential" as const;
export const SEARCH_TABLE_FILTERED = "filtered" as const;
export const SEARCH_TABLE_REJECTED = "rejected" as const;
export const SEARCH_TABLE_DECLINED = "declined" as const;
export const SEARCH_TABLE_VALIDATED = "validated" as const;

export const SEARCH_TABLE_TABLES = [
  SEARCH_TABLE_ACCEPTED,
  SEARCH_TABLE_CONTACTED,
  SEARCH_TABLE_DECLINED,
  SEARCH_TABLE_FILTERED,
  SEARCH_TABLE_POTENTIAL,
  SEARCH_TABLE_REJECTED,
  SEARCH_TABLE_VALIDATED,
] as const;

export const SEARCH_MERGE_PRINT_SECTIONS_PARAM = "print_sections";

export const TABLE_PAYLOAD_ACCEPTED = "accepted_requests" as const;
export const TABLE_PAYLOAD_CONTACTED = "contacted_requests" as const;
export const TABLE_PAYLOAD_DECLINED = "declined_requests" as const;
export const TABLE_PAYLOAD_FILTERED = "filtered_candidates" as const;
export const TABLE_PAYLOAD_POTENTIAL = "potential_candidates" as const;
export const TABLE_PAYLOAD_REJECTED = "rejected_requests" as const;
export const TABLE_PAYLOAD_VALIDATED = "validated_request" as const;

// order dictates print modal checkbox order
export const TABLES_PAYLOAD = [
  TABLE_PAYLOAD_VALIDATED,
  TABLE_PAYLOAD_ACCEPTED,
  TABLE_PAYLOAD_CONTACTED,
  TABLE_PAYLOAD_POTENTIAL,
  TABLE_PAYLOAD_DECLINED,
  TABLE_PAYLOAD_REJECTED,
  TABLE_PAYLOAD_FILTERED,
] as const;

export const SEARCH_TABLE_ROW_COUNT_10 = 10;
export const SEARCH_TABLE_ROW_COUNT_30 = 30;
export const SEARCH_TABLE_ROW_COUNT_50 = 50;

export const SEARCH_TABLE_ROW_COUNTS = [
  SEARCH_TABLE_ROW_COUNT_10,
  SEARCH_TABLE_ROW_COUNT_30,
  SEARCH_TABLE_ROW_COUNT_50,
] as const;

export const SEARCH_TABLE_SORTING_DEFAULT = 1;
export const SEARCH_TABLE_SORTING_NAME = 2;
export const SEARCH_TABLE_SORTING_DISTANCE = 3;

export const SEARCH_MERGE_STATUS_STOPPED = -1 as const;
export const SEARCH_MERGE_STATUS_NOT_STARTED = 0 as const;
export const SEARCH_MERGE_STATUS_STARTED = 1 as const;
// export const SEARCH_MERGE_STATUS_REQUESTS_SENT = 2 as const;
export const SEARCH_MERGE_STATUS_WAITING_FOR_RESPONSE = 3 as const;
export const SEARCH_MERGE_STATUS_ACCEPTED = 4 as const;
export const SEARCH_MERGE_STATUS_ATTRIBUTED = 5 as const;

export const ACTIVE_SEARCH_MERGE_STATUSES = [
  SEARCH_MERGE_STATUS_NOT_STARTED,
  SEARCH_MERGE_STATUS_STARTED,
  SEARCH_MERGE_STATUS_WAITING_FOR_RESPONSE,
  SEARCH_MERGE_STATUS_ACCEPTED,
  SEARCH_MERGE_STATUS_ATTRIBUTED,
] as const;

export const SEARCH_MERGE_STATUSES = [
  ...ACTIVE_SEARCH_MERGE_STATUSES,
  SEARCH_MERGE_STATUS_STOPPED,
] as const;

export const AUCTION_STEP_STARTED = 0 as const;
export const AUCTION_STEP_REQUESTS_SENT = 1 as const;
export const AUCTION_STEP_WAITING_FOR_RESPONSE = 2 as const;
export const AUCTION_STEP_ANSWER_RECEIVED = 3 as const;
export const AUCTION_STEP_ATTRIBUTED = 4 as const;

export const AUCTION_STEPPER_STEPS = [
  AUCTION_STEP_STARTED,
  AUCTION_STEP_REQUESTS_SENT,
  AUCTION_STEP_WAITING_FOR_RESPONSE,
  AUCTION_STEP_ANSWER_RECEIVED,
  AUCTION_STEP_ATTRIBUTED,
] as const;

export const STATUS_STEP_MAP: {
  [index in ActiveSearchMergeStatus]: AuctionStepperStepNumber;
} = {
  [SEARCH_MERGE_STATUS_NOT_STARTED]: AUCTION_STEP_STARTED,
  [SEARCH_MERGE_STATUS_STARTED]: AUCTION_STEP_STARTED,
  [SEARCH_MERGE_STATUS_WAITING_FOR_RESPONSE]: AUCTION_STEP_WAITING_FOR_RESPONSE,
  [SEARCH_MERGE_STATUS_ACCEPTED]: AUCTION_STEP_ATTRIBUTED,
  [SEARCH_MERGE_STATUS_ATTRIBUTED]: AUCTION_STEP_ATTRIBUTED,
};

export const SEARCH_TABLE_SORTING_DEFAULT_PARAM = "default";
export const SEARCH_TABLE_SORTING_NAME_PARAM = "name";
export const SEARCH_TABLE_SORTING_DISTANCE_PARAM = "distance";

export const SORTING_MAP = {
  [SEARCH_TABLE_SORTING_DEFAULT]: SEARCH_TABLE_SORTING_DEFAULT_PARAM,
  [SEARCH_TABLE_SORTING_NAME]: SEARCH_TABLE_SORTING_NAME_PARAM,
  [SEARCH_TABLE_SORTING_DISTANCE]: SEARCH_TABLE_SORTING_DISTANCE_PARAM,
} as const;

// Files specific column type
export const COLUMN_TYPE_FILES_CATEGORY = "files_category" as const;
export const COLUMN_TYPE_FILES_SHARED_WITH = "files_shared_with" as const;
export const COLUMN_TYPE_FILES_UNAVAILABLE_REASON =
  "files_unavailable_reason" as const;
export const COLUMN_TYPE_FILES_FILE_NAME = "files_file_name" as const;
export const COLUMN_TYPE_FILES_DATE_AND_SOURCE =
  "files_date_and_source" as const;

// Accounts specific column type
export const COLUMN_TYPE_ACCOUNT_ROLES = "account_roles" as const;

export const FILE_TABLE_ACTION_DELETE = "delete" as const;
export const FILE_TABLE_ACTION_DOWNLOAD = "download" as const;
export const FILE_TABLE_ACTION_SEE_DETAILS = "see_details" as const;
export const FILE_TABLE_ACTION_SHARE = "share" as const;
export const FILE_TABLE_ACTION_SYNC_VERSION = "sync_version" as const;
export const FILE_TABLE_ACTION_UPLOAD = "upload" as const;
export const FILE_TABLE_ACTION_UPLOAD_AND_SHARE_HIS =
  "upload_and_share_his" as const;
export const FILE_TABLE_ACTION_UPLOAD_DRAGNDROP = "upload_drag_n_drop" as const;
export const FILE_TABLE_ACTION_UPLOAD_HIS = "upload_his" as const;

export const FILE_TABLE_ACTIONS = [
  FILE_TABLE_ACTION_DELETE,
  FILE_TABLE_ACTION_DOWNLOAD,
  FILE_TABLE_ACTION_SEE_DETAILS,
  FILE_TABLE_ACTION_SHARE,
  FILE_TABLE_ACTION_SYNC_VERSION,
  FILE_TABLE_ACTION_UPLOAD_AND_SHARE_HIS,
  FILE_TABLE_ACTION_UPLOAD_DRAGNDROP,
  FILE_TABLE_ACTION_UPLOAD_HIS,
  FILE_TABLE_ACTION_UPLOAD,
];

export const ACCOUNT_TABLE_ACTION_EDIT = "edit" as const;
export const ACCOUNT_TABLE_ACTION_DEACTIVATE = "deactivate" as const;
export const ACCOUNT_TABLE_ACTION_REACTIVATE = "reactivate" as const;
export const ACCOUNT_TABLE_ACTION_SEND_RESET_PASSWORD_EMAIL =
  "send_reset_password_email" as const;
export const ACCOUNT_TABLE_ACTION_SEND_REACTIVATION_EMAIL =
  "send_reactivation_email" as const;

export const ACCOUNT_TABLE_ACTIONS = [
  ACCOUNT_TABLE_ACTION_EDIT,
  ACCOUNT_TABLE_ACTION_DEACTIVATE,
  ACCOUNT_TABLE_ACTION_REACTIVATE,
  ACCOUNT_TABLE_ACTION_SEND_RESET_PASSWORD_EMAIL,
  ACCOUNT_TABLE_ACTION_SEND_REACTIVATION_EMAIL,
];

export const SEARCH_MERGE_BANNER_STALE = "stale_banner" as const;
export const SEARCH_MERGE_BANNER_MAX_ACCEPTS = "max_accepts_banner" as const;
export const SEARCH_MERGE_BANNER_ACCEPTED = "accepted_banner" as const;
export const SEARCH_MERGE_BANNER_ALL_NEGATIVE = "all_negative_banner" as const;
export const SEARCH_MERGE_BANNER_NO_CANDIDATES =
  "no_candidates_banner" as const;
export const SEARCH_MERGE_BANNER_AUCTION_TOO_OLD = "too_old_banner" as const;
export const SEARCH_MERGE_BANNER_ELECTIVE_PROFILE_INCOMPLETE =
  "elective_profile_incomplete_banner" as const;

export const SEARCH_MERGE_BANNERS = [
  SEARCH_MERGE_BANNER_STALE,
  SEARCH_MERGE_BANNER_MAX_ACCEPTS,
  SEARCH_MERGE_BANNER_ACCEPTED,
  SEARCH_MERGE_BANNER_ALL_NEGATIVE,
  SEARCH_MERGE_BANNER_NO_CANDIDATES,
  SEARCH_MERGE_BANNER_AUCTION_TOO_OLD,
  SEARCH_MERGE_BANNER_ELECTIVE_PROFILE_INCOMPLETE,
] as const;

export const ACCEPT_BUTTON_CLICKED = "Accept Button Clicked" as const;
export const BACK_BUTTON_CLICKED = "Back Button Clicked" as const;
export const DECLINE_BUTTON_CLICKED = "Decline Button Clicked" as const;
export const REJECT_BUTTON_CLICKED = "Reject Button Clicked" as const;
export const SEND_REQUEST_BUTTON_CLICKED =
  "Send Request Button Clicked" as const;
export const VALIDATE_BUTTON_CLICKED = "Validate Button Clicked" as const;

export const TRACK_EVENTS = {
  ACCOUNT_ACTIVATION_FAILED: "Account Activation Failed",
  ACCOUNT_ACTIVATION_SUCCEEDED: "Account Activation Succeeded",
  ACCOUNT_ACTIVATION_TOKEN_INVALID: "Account Activation Token Invalid",
  ACCOUNT_ACTIVATION_TOKEN_VALID: "Account Activation Token Valid",
  ACP_TRANSLATIONS_EXPORT: "ACP Translations Export",
  ANSWER_LINK: "Answer Link Clicked",
  ARCHIVE_PATIENT_PATIENT_HOME: "Archive Patient - Patient Home",
  ASSESSMENT_COMPLETION: "Assessment Completion",
  ASSESSMENT_MANUAL_ACTIONS: "Assessment manual actions",
  ASSESSMENT_MISSING_FIELDS_ERROR_MESSAGE_DISPLAYED:
    "Assessment Missing Fields Error Message Displayed",
  AUCTION_REQUEST_VIEWED_REQUEST_STATUS:
    "Auction Request Viewed - Request Status",
  AUCTION_RESPONSE_PAGE_BACK_TO_SEARCH:
    "Auction Response Page - Back to search",
  AUTION_REQUEST_PANEL_ENCRYPTED_MESSAGE:
    "Auction Request - Panel encrypted message",
  B2C_DASHBOARD_CLICK_EMAIL_ACTION_FAB:
    "B2C Dashboard - Click Email Action Fab",
  B2C_DASHBOARD_CLICK_MAP_ACTION_FAB: "B2C Dashboard - Click Map Action Fab",
  B2C_DASHBOARD_CLICK_PROVIDER_CARD_GO_TO_DETAIL_PAGE:
    "B2C Dashboard - Click Provider Card - go to detail page",
  B2C_DASHBOARD_PATIENT_LIST: "B2C Dashboard - PatientList",
  B2C_ONBOARDING_DAILY_SUPPORT_CLICKED: "B2C Onboarding Daily Support Clicked",
  B2C_ONBOARDING_LETS_GO_BUTTON_CLICKED: "B2C Lets Go Button Clicked",
  B2C_ONBOARDING_SHOW_RESULTS_BUTTON_CLICKED: "B2C Show Results Button Clicked",
  BULK_ACTION_BULK_DECLINE: "Bulk Action - bulk_decline",
  BULK_ACTION_BULK_SEEN: "Bulk Action - bulk_seen",
  BULK_ACTION_DROPDOWN: "Bulk Action - DropDown",
  CANCEL_VALIDATION_BUTTON_CLICKED: "Cancel Validation Button Clicked",
  CANCEL_VALIDATION_MODAL_CONFIRM_BUTTON_CLICKED:
    "Cancel Validation Modal Confirm Button Clicked",
  CAPACITY_NOTIFICATION_NO: "Capacity Notification No Button Clicked",
  CAPACITY_NOTIFICATION_X: "Capacity Notification X Clicked",
  CAPACITY_NOTIFICATION_YES: "Capacity Notification Yes Button Clicked",
  CAREPROVIDER_ACCOUNT_CLICK_UPDATE: "Careprovider Account - Click update",
  CAREPROVIDER_AUTH_JWT: "Careprovider Auth JWT",
  CAREPROVIDER_CHANGED: "Careprovider changed",
  CAREPROVIDER_DASHBOARD_CAPACITY_TAB_CLICKED:
    "Careprovider Dashboard - Our Capacity Tab clicked",
  CAREPROVIDER_DASHBOARD_REQUEST_TAB_CLICKED:
    "Careprovider Dashboard - Request Tab clicked",
  CAREPROVIDER_DASHBOARD_SEARCH_TAB_CLICKED:
    "Careprovider Dashboard - Search Tab clicked",
  CAREPROVIDER_DASHBOARD_TAB_DIRECT_REQUEST_LINK_CLICKED:
    "Careprovider Dashboard Direct Request Link Clicked",
  CAREPROVIDER_PROFILE_UPDATE_PROFILE: "Careprovider Profile - Update Profile",
  CAREPROVIDER_SETTINGS_CLICK_SAVE_EMAIL_PREFERENCES:
    "Careprovider Settings - Click Save Email preferences",
  CAREPROVIDER_SETTINGS_NOTIFICATIONS: "CareproviderSettings - Notifications",
  CAREPROVIDER_SETTINGS_SAVED_BROWSER_NOTIFICATIONS_CHANGES:
    "CareproviderSettings - Saved Browser Notifications Changes",
  CAREPROVIDER_SETTINGS_SAVED_CHANGES: "CareproviderSettings - Saved Changes",
  CAREPROVIDER_SETTINGS_TAB_ACCOUNT:
    "Careprovider Settings - My account Tab Clicked",
  CAREPROVIDER_SETTINGS_TAB_CAPACITY:
    "Careprovider Settings - Our capacity Tab Clicked",
  CAREPROVIDER_SETTINGS_TAB_COMPANY:
    "Careprovider Settings - Company profile Tab Clicked",
  CAREPROVIDER_SETTINGS_TAB_EXTENSIONS:
    "Careprovider Settings - Extensions Tab Clicked",
  CAREPROVIDER_SETTINGS_TAB_LEGAL: "Careprovider Settings - Legal Tab Clicked",
  CAREPROVIDER_SETTINGS_TAB_NOTIFICATIONS:
    "Careprovider Settings - Notifications Tab Clicked",
  CAREPROVIDER_SETTINGS_TAB_PARTNER_CLINICS:
    "Careprovider Settings - Partner Clinics Tab Clicked",
  CAREPROVIDER_SETTINGS_TAB_PWA: "Careprovider Settings - PWA Tab Clicked",
  CAREPROVIDER_SETTINGS_TAB_SEARCH:
    "Careprovider Settings - Search Tab Clicked",
  CAREPROVIDER_SETTINGS_TAB_SERVICES:
    "Careprovider Settings - Services Tab Clicked",
  CAREPROVIDER_SETTINGS_TAB_TEAM: "Careprovider Settings - Team Tab Clicked",
  CAREPROVIDER_SETTINGS_TEAM: "CareproviderSettings - Team",
  COMPANY_ONBOARDING: "Company onboarding",
  COMPONENT_ERROR_HEATMAP: "Component Error HeatMap",
  CONFIRM_BUTTON: "Confirm button",
  CONTACT_ANYWAY_MODAL_CONFIRM_BUTTON_CLICKED:
    "Contact Anyway Modal Confirm Button Clicked",
  CONTACT_RECEIVER_BUTTON_CLICKED: "Contact Receivers Button Clicked",
  CONTINUE_EDITING_LINK: "Continue Editing Link Clicked",
  CRISP_ICON_CLICKED: "Crisp Icon Clicked",
  DASHBOARD_CAPACITY_LINK_CLICKED: "Dashboard Capacity Link Clicked",
  DASHBOARD_FILTERS: "Dashboard Filters",
  DASHBOARD_TAB_PRESSED: "Dashboard tab pressed",
  DECRYPT_PATCHER: "Decrypt Patcher",
  DECRYPT_SESSION_KEY: "DecryptSessionKey",
  DERIVE_AES_KEY: "Derive AES Key",
  DOWNLOAD_PDF: "Download PDF",
  EDIT_PATIENT_BUTTON_CLICKED: "Edit Patient Button Clicked",
  EDIT_SEARCH_REJECTED_PROVIDERS_MODAL_CANCEL:
    "Edit search rejected providers modal - cancel",
  EDIT_SEARCH_REJECTED_PROVIDERS_MODAL_CONFIRM:
    "Edit search rejected providers modal - confirm",
  ENCRYPTION_ACTIVATED: "Encryption activated",
  ENCRYPTION_DEACTIVATED: "Encryption deactivated",
  ERROR_BOUNDARY_FILTERED_EVENT: "Error boundary filtered event",
  ERROR_PAGE: "Error Page",
  ERROR_RESTORING_PENDING_SESSION_KEYS: "Error restoring pending session keys",
  EVENT_DECRYPTION_ERROR: "Event decryption error",
  FILE_DELETE: "File delete",
  FILE_MENU_CLICKED: "File Menu Clicked",
  FILE_MENU_ITEM_CLICKED: "File Menu Item Clicked",
  FILE_MENU_MAIN_RELOAD_BUTTON_CLICKED: "Main File Menu Reload Button Clicked",
  FILE_MENU_MAIN_SHARE_BUTTON_CLICKED: "Main File Menu Share Button Clicked",
  FILE_MODAL_CTA_CLICKED: "File Modal CTA Clicked",
  FILE_UPLOAD_BUTTON_CLICKED: "File Upload Button Clicked",
  FILE_UPLOAD_BUTTON: "File Upload Button",
  FILE_UPLOAD_DRAG_AND_DROP: "File upload Drag and Drop",
  FILE_UPLOAD_ERROR_FILE_SIZE: "File upload error - file size",
  FILE_UPLOAD_ERROR_FILE_TYPE: "File upload error - file type",
  FILE_UPLOAD: "File upload",
  FILES_COUNT: "Files Count",
  FILES_SHOW_MORE_BUTTON: "Files show more button",
  GENERATE_SESSION_KEY: "Generate Session Key",
  GENERATING_PUBLIC_PRIVATE_KEYS: "Generating Public/Private keys",
  GO_TO_LEGAL_DOCUMENTS_BUTTON_CLICKED: "Go To Legal Documents Button Clicked",
  GO_TO_SEARCH_BUTTON_CLICKED: "Go To Search Button Clicked",
  GRANT_ACCESS_BUTTON_CLICKED: "Auction Request Grant Access Clicked",
  HEADER_MENU_CLICKED: "Header Menu Clicked",
  HEADER_MENU_ITEM_CLICKED: "Header Menu Item Clicked",
  HL7_FILE_EXPORT: "HL7 file export",
  HL7_IMPORT_ERROR: "HL7 import error",
  HL7_IMPORT: "HL7 import",
  HL7_PATIENT_IMPORT: "HL7 patient import",
  IMPORT_REHAB_FORM_DATA: "Import Rehab Form Data",
  IMPORTED_PRIVATE_KEY: "ImportedPrivateKey",
  JSON_DESERIALIZATION_ERROR: "Json deserialization error",
  LAZY_LOADING_CHUNK_ERROR: "Lazy Loading chunk error",
  LAZY_LOADING_CHUNK_RETRY: "Lazy Loading chunk retry",
  LOGGED_OUT: "Logged out",
  LOGIN_ATTEMPT_FAILED: "Login Attempt Failed",
  LOGIN_FAILED: "Login failed",
  MAP_CLICKED: "Map Clicked",
  MAP_DRAGGED: "Map Dragged",
  MAP_LOAD: "Map load",
  MAP_MARKER_CLICKED: "Map Marker Clicked",
  MAP_POPUP_CLOSED: "Map Popup Closed",
  MAP_ZOOMED_IN: "Map Zoomed In",
  MAP_ZOOMED_OUT: "Map Zoomed Out",
  MESSAGE_DESERIALIZATION_ERROR: "Message deserialization error",
  MESSENGER_EMOJI_PICKER_BUTTON: "Messenger - Emoji Icon Clicked",
  MESSENGER_FILES_TAB: "Messenger - Files Tab Clicked",
  MESSENGER_INFO_TAB: "Messenger - Info Tab Clicked",
  MESSENGER_MESSAGES_TAB: "Messenger - Messages Tab Clicked",
  MESSENGER_SEE_DOCUMENT_LINK: "Messenger - See Document Link Clicked",
  MESSENGER_SEND_MESSAGE_BUTTON: "Messenger - Send Button Clicked",
  MISSING_FIELD: "Missing field",
  NATIVE_ACCEPT: "Native accept",
  NATIVE_DECLINE: "Native decline",
  NATIVE_FILE_DOWNLOAD: "Native File Download",
  NATIVE_LOGIN: "Native login",
  NATIVE_MESSAGE: "Native message",
  NATIVE_REQUEST_VIEW: "Native request view",
  NATIVE_VERSION_TRACKER: "Native version tracker",
  NETWORK_CHECKER: "Network checker",
  NEW_ONBOARDING: "New onboarding",
  NEW_REQUEST_COUNT_DASHBOARD_VIEWED: "New Request Count Dashboard Viewed",
  NEWSLETTER_MENU_ITEM_CLICKED: "Newsletter Menu Item Clicked",
  NEXT_REQUEST_BUTTON_CLICKED: "Next Request Button Clicked",
  NOTIFICATION_PANEL_CLICK_BELL_ICON_IN_NAVBAR:
    "NotificationPanel - Click bell icon in navbar",
  NOTIFICATION_PANEL_CLICK_NOTIFICATION_CARD:
    "NotificationPanel - Click notification card",
  NOTIFICATION_PANEL_CLICK_SHOW_MORE_BUTTON:
    "NotificationPanel - Click show more button",
  NOTIFY_RECEIVER_ASSIGNEE: "Notify receiver assignee",
  NOTIFY_SOCIAL_WORKER: "Notify Social Worker",
  NULL_ON_ERROR: "Null on err!",
  OPERATION_TIMES: "Operation times",
  PAGE_VIEW: "Page View",
  PANDA_MEASURES: "panda-measures", // BE relies on this name to feed DD, don't change it
  PASSWORD_CHANGE_FAILED: "Password Change Failed",
  PASSWORD_CHANGE_SUCCEEDED: "Password Change Succeeded",
  PASSWORD_CHANGE_TOKEN_INVALID: "Password Change Token Invalid",
  PASSWORD_CHANGE_TOKEN_VALID: "Password Change Token Valid",
  PASSWORD_META: "Password meta",
  PATIENT_ASSESSMENT_PAGE_CLICK_ABORT_CREATION_FAB:
    "Patient Assessment Page - Click Abort Creation Fab",
  PATIENT_ASSESSMENT_PAGE_CLICK_SAVE_AND_EXIT:
    "Patient Assessment Page - Click Save And Exit",
  PATIENT_CREATE_ACTION: "Patient create action",
  PATIENT_INFORMATION_MISSING_SUBMITTED:
    "Patient's information missing - submitted",
  PATIENT_INFORMATION_MISSING_TAP_ON_BUTTON:
    "Patient's information missing - tap on button",
  PATIENT_SEARCH_BAR_CLICKED: "Patient Search Bar Clicked",
  PATIENT_SEARCH_CARD_CLICKED: "Patient Search Card Clicked",
  PATIENT_SESSION_KEY_UNAVAILABLE: "Patient session key unavailable",
  PENDING_KEY_DECRYPTION: "Pending Key Decryption",
  PENDING_REQUEST_ACCESS_VIEW: "Pending Request Access view",
  PENDING_UPLOAD_CANCEL_CLICKED: "Pending Upload Cancel Clicked",
  POST_MESSAGE_DURATION: "Post Message duration",
  PRINT_BUTTON_CONFIRMED: "Print Button Confirmed Clicked",
  PRINT_BUTTON_SEARCH_PAGE_CONFIRMED: "Print Button confirmed seach page",
  PRINT_BUTTON: "Print Button Clicked",
  PRINT_MENU: "Print menu",
  PRIVATE_KEY_COULD_NOT_PARSE: "Private key - could not parse",
  PRIVATE_KEY_D_LEADING_0: "Private key - d leading 0",
  PRIVATE_KEY_DECRYPTION: "Private key decryption",
  PROVIDER_SEARCH_ARCHIVED_PROFILES_CLICKED: "Archived Profiles Clicked",
  PROVIDER_SEARCH_CONSULTANT_CARD_CLICKED: "Consultant Card Clicked",
  PROVIDER_SEARCH_FILTER_TAG_EDIT_FILTER_CLICKED:
    "Filter Tag Edit Filter Clicked",
  PROVIDER_SEARCH_FILTER_TAG_REMOVE_FILTER_CLICKED:
    "Filter Tag Remove Filter Clicked",
  PROVIDER_SEARCH_FUNNEL_CONSULTANT_PROVIDER_SELECTED:
    "Funnel Consultant Provider Selected",
  PROVIDER_SEARCH_FUNNEL_GLOSSARY_CLICKED: "Funnel Glossary Link Clicked",
  PROVIDER_SEARCH_FUNNEL_SERVICE_SELECTED: "Funnel Service Selected",
  PROVIDER_SEARCH_FUNNEL_SKIPPED: "Funnel Skipped",
  PROVIDER_SEARCH_FUNNEL_SOLUTION_SELECTED: "Funnel Solution Selected",
  PROVIDER_SEARCH_FUNNEL_START_DATE_SELECTED: "Funnel Start Date Selected",
  PROVIDER_SEARCH_FUNNEL_ZIPCODE_SELECTED: "Funnel Zipcode Selected",
  PROVIDER_SEARCH_MAIN_MENU_GLOSSARY_CLICKED: "Main Menu Glossary Clicked",
  PROVIDER_SEARCH_MAIN_MENU_HOMEPAGE_CLICKED: "Main Menu Home Page Clicked",
  PROVIDER_SEARCH_MAIN_MENU_MY_ACCOUNT_CLICKED: "Main Menu My Account Clicked",
  PROVIDER_SEARCH_MAIN_MENU_SEARCHES_CLICKED: "Main Menu Searches Clicked",
  PROVIDER_SEARCH_PRIVACY_POLICY_CLICKED: "Privacy Policy Clicked",
  PROVIDER_SEARCH_PROFILE_ARCHIVE_CLICKED: "Profile Archive Clicked",
  PROVIDER_SEARCH_PROVIDER_CARD_CLICKED: "Provider Card Clicked",
  PROVIDER_SEARCH_SERVICE_DESCRIPTION_EXPANDED: "Services Description Expanded",
  PROVIDER_SEARCH_START_ZIPCODE_SELECTED: "Start Search Zipcode Selected",
  PROVIDER_SEARCH_TERMS_OF_USE_CLICKED: "Terms Of Use Clicked",
  PSA_RESTORED_THROUGH_WS: "PSA restored through WS",
  PSK_RESTORED_THROUGH_WS: "PSK restored through WS",
  REPORT_PROVIDER_CLICKED: "Report Provider Clicked",
  REQUEST_ACTION: "Request Action",
  REQUEST_DASHBOARD_CLICK_REQUEST_CARD_RECOMMENDATION:
    "Request dashboard - Click Request Card - Recommendation",
  REQUEST_DASHBOARD_CLICK_REQUEST_CARD:
    "Request dashboard - Click Request Card",
  REQUEST_PANEL_ACCEPT: "Request Panel Accept",
  REQUEST_PANEL_DECLINE: "Request Panel Decline",
  REQUEST_PANEL_REJECT: "Request Panel Reject",
  REQUEST_PANEL_SEND_REQUEST: "Request Panel Send Request",
  REQUEST_PANEL_VALIDATE: "Request Panel Validate",
  REQUEST_PENDING_SESSION_KEY: "Request pending session key",
  RESTART_SEARCH_BUTTON_CLICKED: "Restart Search Button Clicked",
  RESTART_SEARCH_LINK: "Restart Search Link Clicked",
  REVERSE_SEARCH_CREATED: "Reverse Search - Search Created",
  REVERSE_SEARCH_DELETED: "Reverse Search - Search Deleted",
  REVERSE_SEARCH_EDITED_SEARCH_SAVED: "Reverse Search - Edited Search Saved",
  REVERSE_SEARCH_EDITED: "Reverse Search - Search Edited",
  REVERSE_SEARCH_PAUSED: "Reverse Search - Search Paused",
  REVERSE_SEARCH_RESULTS_SHOWN: "Reverse Search - Search Results Shown",
  REVERSE_SEARCH_STARTED: "Reverse Search - Search Started",
  ROUTE_SESSION_EXPIRED: "Route - Session Expired",
  SAVE_SESSION_KEYS: "Save session keys",
  SEALD_API_NOT_ACCESSIBLE: "Seald API not accessible",
  SEALD_CONNECTION_BANNER: "Seald Banner - connection issues",
  SEALD_FLOW_FAILED: "Seald flow failed",
  SEALD_IDENTITY_PRE_INITIATED: "Seald identity pre-initiated",
  SEALD_IMPORT: "Seald import",
  SEALD_ONLY_FALLBACK_TRIGGERED: "Seald only fallback triggered",
  SEALD_RESET: "Seald reset",
  SEARCH_MERGE_PRINT_CLICK: "Print Button Confirmed Clicked",
  SEARCH_PANEL_PRINT_DISCHARGE_CONFIRMATION:
    "Search Panel - Print discharge confirmation",
  SEARCH_PANEL_REFRESHED_THROUGH_WS: "Search panel refreshed through WS",
  SEARCH_PANEL_WINNER_CARD_CLICKED: "Search Panel - Winner Card Clicked",
  SEARCH_PROVIDER_BUTTON_CLICKED: "Search Provider Button Clicked",
  SELECT_FILE_CTA_CLICKED: "Select File CTA Clicked",
  SELECT_FROM_COMPUTER_CLICKED: "Upload Modal Select From Computer Clicked",
  SELF_ACCOUNTS_MIGRATION: "Self Accounts Migration",
  SENDER_DASHBOARD_CLICK_PATIENT_CARD: "Sender Dashboard - Click Patient Card",
  SENDER_DASHBOARD_PRINT_PATIENT_LIST: "SenderDashboard - Print patient list",
  SESSION_DURATION_SINCE_SEARCH_STARTED:
    "Session duration since search started",
  SESSION_KEY_DECRYPTION: "Session key decryption",
  SHARE_PATIENT_WITH_RECEIVER_WITH_SEALD:
    "Share patient with receiver with Seald",
  SHARE_PATIENT_WITH_RECEIVER: "Share patient with receiver",
  SHARE_PATIENT_WITH_RECEIVERS_WITH_SEALD:
    "Share patient with receivers with Seald",
  SHARE_PATIENT_WITH_RECEIVERS: "Share patient with receivers",
  SINGER_ASSESSMENT_SCORE: "Singer Assessment Score",
  BARTHEL_ASSESSMENT_SCORE: "Barthel Assessment Score",
  EARLY_REHAB_ASSESSMENT_SCORE: "Early Rehab Assessment Score",
  SPINNER: "Spinner",
  SSL_IMPORT_PING_TEST: "SSL Import ping test",
  STOP_SEARCH_BUTTON_CLICKED: "Stop Search Button Clicked",
  STOP_THE_SEARCH_LINK: "Stop The Search Link Clicked",
  TAB_CLICK: "Tab Click",
  TABLE_SORT_BY_DEFAULT_CLICKED: "Table Sort by Default Clicked",
  TABLE_SORT_BY_DISTANCE_CLICKED: "Table Sort by Distance Clicked",
  TABLE_SORT_BY_NAME_CLICKED: "Table Sort by Name Clicked",
  TEST_ERROR_PAGE: "Test Error Page",
  TIMEOUT: "Timeout",
  TOAST_DISMISSED_BY_CLICKING_CLOSE_ICON:
    "Toast dismissed by clicking close icon",
  TRANSPORT_LOGGED_OUT: "Transport - Logged out",
  TRANSPORT_PASSWORD_CHANGE: "Transport - Password change",
  TRANSPORT_PASSWORD_RESET: "Transport - Password reset",
  TRANSPORT_SESSION_EXPIRED: "Transport - Session Expired",
  TRANSPORT_UNAUTHORIZED: "Transport - Unauthorized",
  UM_CREATE_ACCOUNT_BUTTON: "User Management Add Account Clicked",
  UM_CREATE_MODAL_CANCELLED: "User Management Add Account Modal Cancel Clicked",
  UM_CREATE_MODAL_SUBMIT: "User Management Add Account Modal Create Clicked",
  UM_DEACTIVATE_CLICKED: "User Management Account Deactivate Clicked",
  UM_DEACTIVATE_ERROR: "User Management Account Deactivate Error",
  UM_DEACTIVATE_MODAL_CANCELLED:
    "User Management Account Deactivate Modal Cancel Clicked",
  UM_DEACTIVATE_MODAL_CONFIRMED:
    "User Management Account Deactivate Modal Deactivate Clicked",
  UM_DEACTIVATE_SUCCESS: "User Management Account Deactivate Success",
  UM_EDIT_CLICKED: "User Management Account Edit Clicked",
  UM_EDIT_ERROR: "User Management Account Edit Error",
  UM_EDIT_MODAL_CANCELLED: "User Management Account Edit Modal Cancel Clicked",
  UM_EDIT_MODAL_CONFIRMED: "User Management Account Edit Modal Save Clicked",
  UM_EDIT_SUCCESS: "User Management Account Edit Success",
  UM_MANAGEMENT_SHOW_MORE_BUTTON: "Show More Button Clicked",
  UM_REACTIVATE_CLICKED: "User Management Account Reactivate Clicked",
  UM_REACTIVATE_ERROR: "User Management Account Reactivate Error",
  UM_REACTIVATE_MODAL_CANCELLED:
    "User Management Account Reactivate Modal Cancel Clicked",
  UM_REACTIVATE_MODAL_CONFIRMED:
    "User Management Account Reactivate Modal Reactivate Clicked",
  UM_REACTIVATE_SUCCESS: "User Management Account Reactivate Success",
  UM_RESEND_ACTIVATION_EMAIL_CLICKED:
    "User Management Account Resend activation Email Clicked",
  UM_RESEND_ACTIVATION_EMAIL_ERROR:
    "User Management Account Resend activation Email Error",
  UM_RESEND_ACTIVATION_EMAIL_MODAL_CANCELLED:
    "User Management Account Resend activation Email Modal Cancel Clicked",
  UM_RESEND_ACTIVATION_EMAIL_MODAL_CONFIRMED:
    "User Management Account Resend activation Email Modal Send Clicked",
  UM_RESEND_ACTIVATION_EMAIL_SUCCESS:
    "User Management Account Resend Activation Email Success",
  UM_RESET_PASSWORD_EMAIL_CLICKED:
    "User Management Account Reset Password Email Clicked",
  UM_RESET_PASSWORD_EMAIL_ERROR:
    "User Management Account Reset Password Email Error",
  UM_RESET_PASSWORD_EMAIL_MODAL_CANCELLED:
    "User Management Account Reset Password Email Modal Cancel Clicked",
  UM_RESET_PASSWORD_EMAIL_MODAL_CONFIRMED:
    "User Management Account Reset Password Email Modal Send Clicked",
  UM_RESET_PASSWORD_EMAIL_SUCCESS:
    "User Management Account Reset Password Email Success",
  UPDATE_PROFILE_LINK: "Update Profile Link Clicked",
  UPLOAD_FILE_CLICKED: "Upload Modal Upload File Clicked",
  UPLOAD_FILE_FROM_COMPUTER_CLOSED: "Upload From Computer Modal Closed",
  UPLOAD_FILE_FROM_COMPUTER_DRAG_AND_DROP:
    "Upload From Computer Modal File Dragged and Dropped",
  UPLOAD_FILE_FROM_COMPUTER_SELECT_CLICKED:
    "Upload From Computer Modal Select Clicked",
  UPLOAD_FILE_HIS_DURATION: "Duration File Uploaded",
  UPLOAD_MODAL_CLOSED: "Upload Modal Closed",
  USER_LOGIN_DURATION: "User login duration",
  VALIDATE_AND_GRANT_ACCESS_BUTTON: "Validate and Grant access button",
  VALIDATE_BUTTON: "Validate button",
  VERSION_CONTROL_NATIVE_FORCE_UPDATE_CLICKED_CALL_CUSTOMER_SERVICE:
    "Version Control Native - force update clicked call customer service",
  VERSION_CONTROL_NATIVE_FORCE_UPDATE_CLICKED_SKIP_VERSION:
    "Version Control Native - force update clicked skip version",
  VERSION_CONTROL_NATIVE_FORCE_UPDATE_CLICKED_UPDATE:
    "Version Control Native - force update clicked update",
  VERSION_CONTROL_NATIVE_USER_BLOCKED_APP_OUT_OF_DATE:
    "Version Control Native - user blocked app out of date",
  VERSION_CONTROL_NATIVE_USER_SKIPPED_VERSION_UPDATE:
    "Version Control Native - user skipped version update",
  VERSION_CONTROL: "Version control",
  WEBINAR_CARE_MENU_ITEM_CLICKED: "Webinar Care Menu Item Clicked",
  WEBINAR_REHAB_MENU_ITEM_CLICKED: "Webinar Rehab Menu Item Clicked",
  REPORT_CONTENT_CLICKED: "Report Content Clicked",
  CHANGE_PASSWORD_CLICKED: "Change Password Clicked",
} as const;

export const ALGOLIA_TRACK_EVENTS = {
  SEARCH_RESULT_CLICKED: "Search Result Clicked",
  GO_TO_ANSWER_CLICKED: "Go To Answer Clicked",
  CONFIRM_CLICKED: "Confirm Clicked",
} as const;

export const ALGOLIA_INDICES = {
  ZIPCODE: "zipcodes2023",
  CAREPROVIDERS: "careproviders",
} as const;

export const earlyBarthelElements = [
  "intensive_medical_monitoring",
  "tracheostoma_requiring_aspiration",
  "intermittent_ventilation",
  "orientation_disorder",
  "behavioural_disorder",
  "supervised_swallowing_disorder",
  "severe_communication_disorder",
] as const;

export const barthelElements = [
  "food",
  "setting_up_and_relocating",
  "personal_care",
  "toilet_use",
  "bathing",
  "getting_up_and_walking",
  "stair_climbing",
  "dressing_and_undressing",
  "fecal_incontinence",
  "urinary_incontinence",
] as const;

// Everytime there's a structure change to the INDEX_DB_SESSION_KEYS
// the version must be upped
export const INDEX_DB_SESSION_KEYS_VERSION = 2;

export const INDEX_DB_SESSION_KEYS = "DecryptedSessionKeysDB" as const;

export const INDEX_DB_SESSION_KEYS_HASHES_TABLE = "HashesToKeysStore" as const;
export const INDEX_DB_SESSION_KEYS_ATTRIBUTES_TABLE =
  "AttributesToHashesStore" as const;

export const INDEX_DB_TABLES = [
  INDEX_DB_SESSION_KEYS_HASHES_TABLE,
  INDEX_DB_SESSION_KEYS_ATTRIBUTES_TABLE,
] as const;

export const WEBSOCKET_STATUS = {
  CLOSED: "CLOSED",
  CLOSING: "CLOSING",
  CONNECTED: "CONNECTED",
  CONNECTING: "CONNECTING",
  NOT_STARTED: "NOT_STARTED",
  ERROR: "ERROR",
} as const;

export const DEFAULT_ALGOLIA_QUERY = "xxxxxxx";
export const EMPTY_ALGOLIA_QUERY = "";

export const PATIENT_GENERATOR_OPTIONS = {
  care: "care",
  care_five: "care_five",
  mobile_short: "mobile_short",
  h2h_two_solutions: "h2h_two_solutions",
  reha_w_ins: "reha_w_ins",
  reha_wo_ins: "reha_wo_ins",
  homecare: "homecare",
  medical_supplies: "homecare",
  transport: "transport",
} as const;

export const CREATE_PATIENT_ROLE_SEARCH_TYPE_MAP: Record<
  (typeof CREATE_PATIENT_ROLES)[number],
  SearchType
> = {
  [ACCOUNT_ROLE_CARESEEKER_CARE]: SEARCH_TYPE_CARE,
  [ACCOUNT_ROLE_CARESEEKER_HOSPITALIZATION]: SEARCH_TYPE_HOSPITAL,
  [ACCOUNT_ROLE_CARESEEKER_REHAB]: SEARCH_TYPE_REHABILITATION,
  [ACCOUNT_ROLE_TRANSPORT]: SEARCH_TYPE_TRANSPORT,
  [ACCOUNT_ROLE_MEDICAL_SUPPLIES]: SEARCH_TYPE_MEDICAL_SUPPLIES,
};

export const DISTANCE_FILTER_DEFAULT = 1 as const;
export const DISTANCE_FILTER_SENDER = 2 as const;
export const DISTANCE_FILTER_RECEIVER = 3 as const;

export const INF_SYS_MYNEVA = 1;
export const INF_SYS_MEDIFOX = 2;
export const INF_SYS_DAN_PRODUKTE = 3;
export const INF_SYS_CONNEXT_VIVENDI = 4;
export const INF_SYS_NOVENTI_CARE = 5;
export const INF_SYS_CURASOFT = 6;
export const INF_SYS_SNAP = 7;
export const INF_SYS_SINFONIE = 8;
export const INF_SYS_SENSO = 9;
export const INF_SYS_GODO_SYSTEMS = 10;
export const INF_SYS_OTHER = 11;

export const MAX_PROVIDER_SEARCH_DISTANCE_KM = 200;

export const NEXTUI_LOCALE = {
  de: "de-DE",
  en: "en-GB",
} as const;

export const PAGINATION_COUNT_20 = 20;
export const PAGINATION_COUNT_30 = 30;

export const PAGINATION_RESULT_COUNTS = [
  PAGINATION_COUNT_10,
  PAGINATION_COUNT_20,
  PAGINATION_COUNT_30,
] as const;

export const PFLEGEPORTAL_WEBSITE = "https://pflegeportal-weser-ems.de";
export const WESER_EMS_MARKETING_URL = "https://gesundheit-weser-ems.de";
export const WESER_EMS_IMPRESSUM_URL = `${WESER_EMS_MARKETING_URL}/impressum/`;
export const WESER_EMS_AOK_PARTNER_URL =
  "https://www.aok.de/pk/unterstuetzungsangebote-in-der-naehe/uebersicht/?initial_search=true";
export const WESER_EMS_VDEK_PARTNER_URL = "https://www.pflegelotse.de";
export const WESER_EMS_BKK_PARTNER_URL =
  "https://pflegefinder.bkk-dachverband.de/aua/";
export const WESER_EMS_AOK_PARTNER_LOGO = "Logo_AOK@2x.png";
export const WESER_EMS_VDEK_PARTNER_LOGO = "Logo_vdek_Pflegelotse@2x.png";
export const WESER_EMS_BKK_PARTNER_LOGO = "Logo_BKK_Pflegefinder@2x.png";
export const WESER_EMS_LOWER_SAXONY_MINISTRY_URL =
  "https://www.ms.niedersachsen.de/startseite/gesundheit_pflege/pflege/angebote-zur-unterstuetzung-im-alltag-153311.html";
export const WESER_EMS_CONSULTATION_URL =
  "https://gesundheit-weser-ems.de/pflegeberatung/";

export const ALGOLIA_ADD_ACCOUNT_ROLE = "add_account_role" as const;
export const ALGOLIA_ADD_PRIORITY_PROVIDER = "add_priority_provider" as const;
export const ALGOLIA_ADD_PROVIDER_MANUALLY = "add_provider_manually" as const;
export const ALGOLIA_ADD_WINNING_PROVIDER = "add_winning_provider" as const;
export const ALGOLIA_ASSESSMENT_ICD_CODES = "assessment_icd_codes" as const;
export const ALGOLIA_ATTRIBUTE_PROVIDER_MANUALLY =
  "attribute_provider_manually" as const;
export const ALGOLIA_FILTERS_CARESEEKER = "filters_careseeker" as const;
export const ALGOLIA_SEARCH_ACCOUNT = "search_account" as const;
export const ALGOLIA_SEARCH_CAREPROVIDER = "search_careprovider" as const;
export const ALGOLIA_SEARCH_CARESEEKER = "search_careseeker" as const;
export const ALGOLIA_SEARCH_COMPANY = "search_company" as const;
export const ALGOLIA_SEARCH_GENERAL = "search_general" as const;
export const ALGOLIA_SEARCH_INSURANCE = "search_insurance" as const;
export const ALGOLIA_SEARCH_MEDICAL_SUPPLIES =
  "search_medical_supplies" as const;
export const ALGOLIA_SEARCH_ONPREMISE = "search_onpremise" as const;
export const ALGOLIA_SEARCH_PATIENT = "search_patient" as const;
export const ALGOLIA_SEARCH_ZIP_CODES = "search_zip_codes" as const;
export const ALGOLIA_SEARCH_CONSULTANT = "search_consultant" as const;

export const UNIVERSAL_SEARCH_RESOURCES = {
  ACCOUNT: 1,
  RECEIVER: 2,
  SENDER: 3,
  COMPANY: 4,
  CONSULTANT: 5,
} as const;

export const ALGOLIA_ANALYTICS_NAMES = [
  ALGOLIA_ADD_ACCOUNT_ROLE,
  ALGOLIA_ADD_PRIORITY_PROVIDER,
  ALGOLIA_ADD_PROVIDER_MANUALLY,
  ALGOLIA_ADD_WINNING_PROVIDER,
  ALGOLIA_ASSESSMENT_ICD_CODES,
  ALGOLIA_ATTRIBUTE_PROVIDER_MANUALLY,
  ALGOLIA_FILTERS_CARESEEKER,
  ALGOLIA_SEARCH_ACCOUNT,
  ALGOLIA_SEARCH_CAREPROVIDER,
  ALGOLIA_SEARCH_CARESEEKER,
  ALGOLIA_SEARCH_COMPANY,
  ALGOLIA_SEARCH_GENERAL,
  ALGOLIA_SEARCH_INSURANCE,
  ALGOLIA_SEARCH_MEDICAL_SUPPLIES,
  ALGOLIA_SEARCH_ONPREMISE,
  ALGOLIA_SEARCH_PATIENT,
  ALGOLIA_SEARCH_ZIP_CODES,
  ALGOLIA_SEARCH_CONSULTANT,
] as const;

export const THEMES = {
  RECARE: "themeRecare",
  PROVIDER_SEARCH: "themeBCP",
  WESER_EMS: "themeWeserEms",
} as const;

export const THEME_CLASSES: Partial<Record<AppType, string>> = {
  [APP_CLINIC]: THEMES.RECARE,
  [APP_CAREPROVIDER]: THEMES.RECARE,
  [APP_ACP]: THEMES.RECARE,
  [APP_PROVIDER_SEARCH]: THEMES.PROVIDER_SEARCH,
  [APP_B2C]: THEMES.WESER_EMS,
};

export const LOGO_WHITE = "white";
export const LOGO_GREY = "grey";
export const LOGO_LIGHT_BLUE = "light-blue";
export const LOGO_MASTER = "master";
export const LOGO_MEDIUM_DARK_BLUE = "medium-dark-blue";

export const LOGO_COLOR_SCHEMA = [
  LOGO_WHITE,
  LOGO_GREY,
  LOGO_LIGHT_BLUE,
  LOGO_MASTER,
  LOGO_MEDIUM_DARK_BLUE,
] as const;

export const NEGATIVE_DIFF = "negative";
export const POSITIVE_DIFF = "positive";
export const SIMPLE_DIFF = "simple";

export const MIN_AGE = 0;
export const MAX_AGE = 120;

export const REDIRECT_PARAM = "redirect_to";
export const RETURN_PARAM = "return_to_authorized";
export const REDIRECT_REASON_PARAM = "redirect_reason";

export const REDIRECT_REASON_GENERIC_ERROR = 1;
export const REDIRECT_REASON_LINK_EXPIRED = 2;
export const REDIRECT_REASON_NOT_AUTHORIZED = 3;

export const CONSULTANT_STATUS_CREATED = 0;
export const CONSULTANT_STATUS_ACTIVE = 1;
export const CONSULTANT_STATUS_INACTIVE = 2;

export const CONSULTANT_STATUSES = [
  CONSULTANT_STATUS_CREATED,
  CONSULTANT_STATUS_ACTIVE,
  CONSULTANT_STATUS_INACTIVE,
] as const;

const MONDAY = "monday";
const TUESDAY = "tuesday";
const WEDNESDAY = "wednesday";
const THURSDAY = "thursday";
const FRIDAY = "friday";
const SATURDAY = "saturday";
const SUNDAY = "sunday";

export const WEEKDAYS = {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
} as const;

export const LOREM_IPSUM_ONE_PARAGRAPH =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam quis viverra odio. Vestibulum in gravida libero, vel pulvinar erat. Phasellus imperdiet eleifend sollicitudin. In eleifend mauris at rutrum congue. Nulla facilisi. Nullam porttitor porta arcu vitae rhoncus. Duis aliquam maximus est, condimentum rhoncus sem. Cras hendrerit elit id justo ultricies ullamcorper. Nulla posuere laoreet risus sit amet euismod. Ut pellentesque lacinia sagittis. Nulla vel luctus ante. Integer ac ligula non sapien bibendum vehicula. Cras interdum scelerisque leo, ac elementum quam ultricies consequat.";

export const GLOSSARY_SECTION_CONSULTANTS = "consultants";
export const GLOSSARY_SECTION_CARE_NEEDS = "care_needs";
export const GLOSSARY_SECTION_CARE_LEVELS = "care_levels";
export const GLOSSARY_SECTION_SOLUTIONS = "solutions";

export const GLOSSARY_SECTIONS = [
  GLOSSARY_SECTION_CONSULTANTS,
  GLOSSARY_SECTION_CARE_NEEDS,
  GLOSSARY_SECTION_CARE_LEVELS,
  GLOSSARY_SECTION_SOLUTIONS,
] as const;

export const BROWSER_NOTIFICATION = {
  REQUEST: 1,
  VALIDATE: 2,
  NEW_MESSAGE: 3,
  ATTRIBUTION_CANCELLATION: 4,
  NOT_AVAILABLE: 5,
  REQUEST_AVAILABLE: 6,
  PROFILE_EDITED: 7,
  NEW_MESSAGE_FROM_PROVIDER: 8,
  TEST: -1,
};

export const TOKEN_INVALID_REASONS = {
  PASSWORD_CHANGED: "password_changed",
  EXPIRED: "expired",
  INVALID: "invalid",
} as const;

export const CAPACITY_MAX_DAYS = 60;

export const NEWSLETTER_URL = "https://recaresolutions.com/newsletter";

export const TRANSITIONAL_CARE_PATIENT_TYPES = [
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_REHABILITATION,
];

export const PRODUCT_FEATURE_ACTIVE = 1;
export const PRODUCT_FEATURE_INACTIVE = 2;
export const PRODUCT_FEATURE_TRIAL = 3;

export const PRODUCT_FEATURE_STATUSES = [
  PRODUCT_FEATURE_ACTIVE,
  PRODUCT_FEATURE_INACTIVE,
  PRODUCT_FEATURE_TRIAL,
] as const;

export const PRODUCT_FEATURE_TRANSITIONAL_CARE = 1;

export const PRODUCT_FEATURES = [PRODUCT_FEATURE_TRANSITIONAL_CARE] as const;

export const PROVIDER_SEARCH_LINK_TO_DASHBOARD =
  "https://www.stmgp.bayern.de/pflege/pflegefinder";

export const REQUEST_FORM_FIELDS = {
  CAREPROVIDER_ADDRESS_ADDRESS: "careprovider_address_address",
  CAREPROVIDER_ADDRESS_CITY: "careprovider_address_city",
  CAREPROVIDER_ADDRESS_ZIPCODE: "careprovider_address_zipcode",
  CAREPROVIDER_NAME: "careprovider_name",
  REQUEST_SENT_DATE: "request_sent_date",
  REQUEST_SENT_TIME: "request_sent_time",
  REQUEST_STATUS: "request_status",
} as const;

export const CARE_NECESSITIES = {
  AFTER_CARE_ENSURED_DURING: 1,
  AFTER_CARE_COULD_BE_ENSURED: 2,
  FULL_STOP_OUTSIDE_ENTITLEMENT: 3,
  NO_FURTHER_NEED: 4,
} as const;

export const MAX_REQUESTS = 20;

export const HOME_CARE_SITUATION = {
  HOME: 1,
  ASSISTED_LIVING: 2,
  CARE_HOME: 3,
} as const;

export const AT_HOME_SITUATION = {
  ALONE: 1,
  RELATIVES: 2,
} as const;

export const GUARDIAN = {
  CARER: 1,
  HEALTH_CARE_REP: 2,
  LEGAL_REP: 3,
} as const;

export const FUNCTION_DIAGNOSIS_PRIORITIES = {
  PRIO_ONE: 1,
  PRIO_TWO: 2,
  PRIO_THREE: 3,
  PRIO_FOUR: 4,
  PRIO_FIVE: 5,
} as const;
export const MUSCOSKELETAL_RESILIENCE = {
  FULLY: 1,
  PARTIALLY: 2,
  STABLE: 3,
} as const;

export const EARLY_REHAB_CRITERIA = {
  INTENSIVE_CARE_MONITORING: 1,
  INTERMITTENT_VENTILATION: 2,
  BEHAVIOURAL_DISORDER: 3,
  COMMUNICATION_DISORDER: 4,
  TRACHEOSTOMA: 5,
  ORIENTATION_DISORDER: 6,
  SWALLOWING_DISORDER: 7,
} as const;

export const REHAB_FOCUS = {
  HEART: 1,
  NEUROLOGICAL_DISEASE: 2,
  BLOOD_VESSELS: 3,
  ONCOLOGICAL_DISEASE: 4,
  INFLAMMATORY_DISEASE: 5,
  GYNAECOLOGICAL_DISEASE: 6,
  MUSCOLOSKELETAL_DISEASE: 7,
  SKIN_DISEASE: 8,
  GASTROENTEROLOGICAL_DISEASE: 9,
  PSYCHOSOMATIC_DISORDER: 10,
  ENDOCRINE_DISEASE: 11,
  MENTAL_ILLNESS: 12,
  RESPIRATORY_DISEASE: 13,
  ADDICTIVE_DISORDER: 14,
  NEPHROLOGICAL_DISEASE: 15,
  BLOOD_DISEASE: 16,
  UROLOGICAL_DISEASE: 17,
  GERIATRICS: 18,
  OTHER: 19,
} as const;

export const REHAB_DIRECTIVE_TYPE = {
  MYOCARDIAL_INFARCTION: 1,
  BYPASS: 2,
  HEART_FAILURE: 3,
  ENDOPROSTHETIC_TREATMENT: 4,
  BACK_PAIN: 5,
  SPINAL_CANAL: 6,
  AMPUTATION: 7,
  RESPIRATORY_DISEASE: 8,
  NEUROLOGICAL_DISEASE: 9,
  ONCOLOGICAL_DISEASE: 10,
  TRANSPLANTS: 11,
  GERIATRIC_REHAB: 12,
} as const;

export const OUTPATIENT_NOT_POSSIBLE_REASONS = {
  ACCOMPANYING_PERSON: 1,
  TAXI: 2,
  AMBULANCE: 3,
} as const;

export const REHAB_TRIGGERING_EVENT = {
  SURGERY: 1,
  ACUTE_EVENT: 2,
  RADIATION: 3,
  ANTINEOPLASTIC_THERAPY: 4,
  NOT_APPLICABLE: 5,
  ACCIDENT_WORK: 6,
  THIRD_PARTY_DAMAGE: 7,
} as const;

export const REHAB_FORM_TYPES = {
  GENERAL: 1,
  MEDICAL: 2,
} as const;

export const REHAB_FORM_SUB_TYPES = {
  PENSION: 1,
  UNIVERSAL: 2,
} as const;

export const DRV_TRAVEL_TYPES = {
  PUBLIC: TRANSPORT_TYPE_PUBLIC_TRANSPORT,
  CAR: TRANSPORT_TYPE_PERSONAL_CAR,
} as const;

export const REHAB_FORM_NAMES = {
  GENERAL_PENSION: "general-form-pension",
  GENERAL_UNIVERSAL: "general-form-universal",
  MEDICAL_PENSION: "medical-form-pension",
  MEDICAL_UNIVERSAL: "medical-form-universal",
} as const;

export const PATIENT_DOCUMENTS = {
  DOCUMENTATION: 1,
  DISCHARGE: 2,
  _FROM_HIS: 3, // This is a document type only used on the Gateway for sending files to Recare/FE. It should not be used by Recare/FE for sending files to the gateway
  TRANSITIONAL_CARE: 4,
  DRV_ANTRAG: 5,
  AHB_ANTRAG: 6,
  DRV_AERZT_BEFUND: 7,
  AHB_AERZT_BEFUND: 8,
} as const;

export const PATIENT_DOCUMENT_NAMES = {
  [PATIENT_DOCUMENTS.DOCUMENTATION]: "EntlassDoku",
  [PATIENT_DOCUMENTS.DISCHARGE]: "EntlassZusm",
  [PATIENT_DOCUMENTS._FROM_HIS]: "_",
  [PATIENT_DOCUMENTS.TRANSITIONAL_CARE]: "UebergPflege",
  [PATIENT_DOCUMENTS.DRV_ANTRAG]: "drv_antrag_G0250",
  [PATIENT_DOCUMENTS.AHB_ANTRAG]: "antrag_anschlussrehabilitation",
  [PATIENT_DOCUMENTS.DRV_AERZT_BEFUND]: "drv_G0260_G0600",
  [PATIENT_DOCUMENTS.AHB_AERZT_BEFUND]: "antrag_aerztlicher_befundbericht",
} as const;

export const PATIENT_DOCUMENT_DESCRIPTIONS = {
  [PATIENT_DOCUMENTS.DOCUMENTATION]: "Recare_Entlassdokumentation",
  [PATIENT_DOCUMENTS.DISCHARGE]: "Recare_Entlassbestaetigung",
  [PATIENT_DOCUMENTS._FROM_HIS]: "_",
  [PATIENT_DOCUMENTS.TRANSITIONAL_CARE]: "Recare_Uebergangspflege",
  [PATIENT_DOCUMENTS.DRV_ANTRAG]: "Recare_DRV_Antrag_G0250",
  [PATIENT_DOCUMENTS.AHB_ANTRAG]:
    "Recare_Einheitlicher_Antrag_Antrag_auf_Anschlussrehabilitation",
  [PATIENT_DOCUMENTS.DRV_AERZT_BEFUND]:
    "Recare_DRV_Aerztlicher_Befundbericht_G0260_G0600",
  [PATIENT_DOCUMENTS.AHB_AERZT_BEFUND]:
    "Recare_Einheitlicher_Antrag_Aerztlicher_Befundbericht",
} as const;

export const REHAB_FORM_TO_PATIENT_DOCUMENT = {
  [REHAB_FORM_NAMES.GENERAL_PENSION]: PATIENT_DOCUMENTS.DRV_ANTRAG,
  [REHAB_FORM_NAMES.GENERAL_UNIVERSAL]: PATIENT_DOCUMENTS.AHB_ANTRAG,
  [REHAB_FORM_NAMES.MEDICAL_PENSION]: PATIENT_DOCUMENTS.DRV_AERZT_BEFUND,
  [REHAB_FORM_NAMES.MEDICAL_UNIVERSAL]: PATIENT_DOCUMENTS.AHB_AERZT_BEFUND,
} as const;

export const TRANSPORTATION_TYPE = {
  PUBLIC: 1,
  CAR: 2,
  TAXI: 3,
  AMBULANCE: 4,
} as const;

export const TRANSPORTATION_TAXI = {
  WHEELCHAIR: 1,
  STRETCHER: 2,
  LYING_DOWN: 3,
} as const;

export const YES_NO_FIELD_OPTIONS = {
  YES: 1,
  NO: 0,
} as const;

export const REHAB_ADMISSION = {
  DIRECT: 1,
  WITHIN_TWO_WEEKS: 2,
  OUTSIDE_TWO_WEEKS: 3,
} as const;

export const TYPE_OF_REHAB = {
  MOBILE: 1,
  MOBILE_OUTPATIENT: 2,
  STATIONARY: 3,
} as const;

export const PERFORMED_TREATMENT = {
  SURGERY: 1,
  OTHER: 2,
} as const;

export const REQUESTED_REHAB_SERVICE = {
  INSURED: 1,
  RELATIVES: 2,
} as const;

export const REQUESTED_REHAB_SERVICE_TYPE = {
  INPATIENT: 1,
  OUTPATIENT: 2,
} as const;

export const PRIOR_EMPLOYMENT_STATUS = [
  PRIOR_EMPLOYMENT_NOT_WORKING,
  PRIOR_EMPLOYMENT_FULL_TIME_NO_ROTATING_SHIFT,
  PRIOR_EMPLOYMENT_FULL_TIME_ROTATING_SHIFT,
  PRIOR_EMPLOYMENT_WITH_NIGHT_SHIFTS,
  PRIOR_EMPLOYMENT_PART_TIME_LESS_THAN_HALF_DAY,
  PRIOR_EMPLOYMENT_PART_TIME_MORE_THAN_HALF_DAY,
  PRIOR_EMPLOYMENT_HOUSEHOLD_CARE,
  PRIOR_EMPLOYMENT_UNEMPLOYED_REGISTERED,
  PRIOR_EMPLOYMENT_WORK_AT_HOME,
  PRIOR_EMPLOYMENT_WORKSHOP_FOR_DISABLED_PEOPLE,
] as const;

export const LEGAL_GUARDIAN = {
  STATUTORY_REP: 1,
  CUSTODIAN: 2,
  SUPERVISOR: 3,
  AUTHORIZED: 4,
} as const;

export const LACK_OF_CAPACITY_DOCUMENT = {
  ATTACHED: 1,
  SEND_LATER: 2,
} as const;

export const VISUALLY_IMPAIRED_DOCUMENT = {
  LARGE_PRINT: 1,
  BRAILLE_SHORT: 2,
  BRAILLE_LONG: 3,
  CD: 4,
  AUDIO: 5,
} as const;

export const EMPLOYMENT_STATUS = [
  EMPLOYMENT_STATUS_NOT_EMPLOYED,
  EMPLOYMENT_STATUS_TRAINEE,
  EMPLOYMENT_STATUS_UNSKILLED_EMPLOYEE,
  EMPLOYMENT_STATUS_SEMI_SKILLED_WORKER,
  EMPLOYMENT_STATUS_SKILLED_WORKER,
  EMPLOYMENT_STATUS_MASTER_FOREMAN,
  EMPLOYMENT_STATUS_EMPLOYEE,
  EMPLOYMENT_STATUS_CIVIL_SERVANT,
  EMPLOYMENT_STATUS_SELF_EMPLOYED,
] as const;

export const NOTIFICATION_TYPES = {
  CAPACITY_UPDATE: 1,
} as const;

export const CARE_LEVEL_FORMS = {
  FIRST: 1,
} as const;

export const WEBINAR_CARE_URL = "https://calendly.com/recare-partner";
export const WEBINAR_REHAB_URL =
  "https://calendly.com/webinar-fur-rehabilitationskliniken";
export const FAQ_URL = "https://help.recaresolutions.com/de/";
export const CONTACT_US_URL = "https://recaresolutions.com/kontakt/";

export const B2C_ONBOARDING_PAGES = {
  WELCOME: "welcome",
  TARGET: "target",
  START_DATE: "startdate",
  CARE_TYPE: "careType",
  CARE_SOLUTION: "careSolution",
  CARE_TERM: "careTerm",
  LOCATION: "location",
  LOADING: "loading",
  DAILY_LIVING_SUPPORT_SERVICES: "dailyLivingSupportServices",
} as const;

export const TOP_LEVEL_NAVBAR_HEIGHT = 28;
export const MAIN_NAVBAR_HEIGHT = 56;

export const APP_BAR_HEIGHT_V2 = TOP_LEVEL_NAVBAR_HEIGHT + MAIN_NAVBAR_HEIGHT;

export const REPOSITORY_VEYO = "veyo-react-frontend";
export const REPOSITORY_VOICE = "voice";
export const REPOSITORY_EMAIL = "react-emails";
